import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'axios-hooks';
import {
  Box,
  Button,
  Flex,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  TabPanels,
  TabPanel,
  Progress
} from '@chakra-ui/react';

import {
  purpleColor,
  purpleColorHover,
  setLocalData,
  getLocalData,
  API_ENDPOINTS,
  PENDING_PROVIDER_REQUEST_STATUS,
  COMPLETED_PROVIDER_REQUEST_STATUS,
  ACTION_REQUIRED_PROVIDER_REQUEST_STATUS
} from '../../../utils';
import { UserState, Address } from '../types';
import defaultLogo from '../../../assets/providers-logos/default-logo.svg';

import ServiceDiscovery from './ServiceDiscovery';
import ProviderRowGroup from './ProviderRow';
import AddProviderDialog from './AddProviderDialog';
import PayNow from '../PayNow';

import MapImage from './map.png';
import SearchField from '../../../components/ui/SearchField';
import Correspondence from './Correspondence';

type TServicesProps = {
  reloadMe: Function;
};

const Services = (props: TServicesProps) => {
  const user = useSelector((state: UserState) => state.user.value);

  const gmailCallbackData = getLocalData().correspondenceCallBackData;

  const [correspondenceState, setCorrespondenceState] = useState<any>({
    open: !!(gmailCallbackData && gmailCallbackData.id),
    data: gmailCallbackData
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [userAddress, setUserAddress] = useState<Address>();
  const [startPayment, setStartPayment] = useState<boolean>(false);
  const [inProgressContracts, setInProgressContracts] = useState<any>([]);
  const [completedActions, setCompletedActions] = useState<any>([]);
  const [actionItems, setActionItems] = useState<any>([]);
  const [openAddProviderDialog, setOpenAddProviderDialog] = useState<boolean>(false);
  const [providerRequests, setProviderRequests] = useState<any>([]);
  const [serviceProviders, setServiceProviders] = useState<any>([]);
  const [allData, setAllData] = useState(getLocalData().metaData ? getLocalData().metaData : {
    requiresActionItems: [],
    inProgressItems: [],
    completedItems: []
  });

  const [{ data: changeRequests, loading: changeRequestsLoading }, getChangeRequests] = useAxios(API_ENDPOINTS.CHANGE_REQUESTS);
  const [{ data: connectRequests, loading: connectRequestsLoading }, getConnectRequests] = useAxios(API_ENDPOINTS.CONNECT_REQUESTS);
  const [{ data: disconnectRequest, loading: disconnectRequestsLoading }, getDisconnectRequests] = useAxios(API_ENDPOINTS.DISCONNECT_REQUESTS);
  const [{ data: serviceAgreements, loading: serviceAgreementsLoading }, refetchServiceAgreements] = useAxios(API_ENDPOINTS.SERVICE_AGREEMENTS);
  const [{ data: myServiceProviders, loading: myServiceProvidersLoading }, refetchMyServiceProviders] = useAxios(API_ENDPOINTS.MY_SERVICE_PROVIDERS);
  const [{ data: potentialProviders, loading: potentialProvidersLoading }, refetchPotentialProviders] = useAxios(API_ENDPOINTS.POTENTIAL_PROVIDERS);

  const mapReponse = (providers: any, action: string) => {
    return providers.map((provider: any) => ({
      id: provider?.providerRequest?.id as number,
      providerName: provider?.providerRequest?.providerName as string,
      showSendEmailButton: provider?.providerRequest
        ?.sendRequestOn as boolean,
      sendEmailType: provider?.providerRequest?.sendRequestType as string,
      emailStatus: provider?.providerRequest?.requestStatus as string,
      sendEmailPermission: provider?.providerRequest?.hasSendScope as boolean,
      action,
      logo: provider?.providerRequest?.provider?.logo || defaultLogo,
      associatedEmail: provider?.providerRequest?.associatedEmail as string,
      reason: provider?.providerRequest?.reason,
      sendRequestOn: provider?.providerRequest?.sendRequestOn,
      providerId: provider?.providerRequest?.provider.id,
      isPaid: provider?.providerRequest?.provider.isPaid
    }));
  };

  const setupProviders = (serviceAgreements: any, myServiceProviders: any, potentialProviders: any) => {
    const providersMap = (myServiceProviders || []).reduce((all: any, provider: any) => {
      all[provider.id] = provider;

      return all;
    }, {});

    const validAgreements = (serviceAgreements || []).filter((agreement: any) => agreement.userEmail && agreement.userEmail.email);

    const agreementsMap = (validAgreements || []).reduce((all: any, agreement: any) => {
      all[agreement.id] = agreement;

      return all;
    }, {});

    const mergeAgreementAndProvider = (agreement: any) => {
      const provider = providersMap[agreement.provider];
      return {
        ...agreement,
        agreementId: agreement.id,
        email: agreement.userEmail.email,
        ...provider,
        providerName: provider?.name || provider?.serviceProvider?.name,
        isPaid: provider.serviceProvider ? provider.serviceProvider.isPaid : provider.isPaid
      };
    };

    const providers = (validAgreements || []).reduce((all: any, agreement: any) => {
      if (agreement.transitioningTo) {
        const transitionToAgreement = agreementsMap[agreement.transitioningTo];

        all.transitionAgreements.push([
          mergeAgreementAndProvider(agreement),
          mergeAgreementAndProvider(transitionToAgreement)
        ]);
      } else if (agreement.transitioningTo === null && !agreement.transitionedFrom.length) {
        all.nonTransitionAgreements.push(mergeAgreementAndProvider(agreement));
      }

      return all;
    }, {
      nonTransitionAgreements: [],
      transitionAgreements: []
    });

    providers.nonTransitionAgreements = providers.nonTransitionAgreements.concat(
      (potentialProviders || []).map((provider: any) => ({ ...provider, isPaid: provider?.serviceProvider.isPaid || false, email: provider.discoveredUserEmail[0].userEmail.email, name: provider?.name || provider?.serviceProvider?.name || provider?.domains[0], logo: provider?.serviceProvider?.logo, type: 'POTENTIAL' }))
    );

    /* const flattenedTransitionProviders = providers.transitionAgreements.reduce((all: any, transitionProvider: any) => {
      all = [...all, ...transitionProvider];

      return all;
    }, []); */

    return [...providers.transitionAgreements, ...providers.nonTransitionAgreements];
  };

  useEffect(() => {
    if (changeRequests && connectRequests && disconnectRequest && !changeRequestsLoading && !connectRequestsLoading && !disconnectRequestsLoading) {
      setProviderRequests(
        []
          .concat(mapReponse(changeRequests, 'Notify of Address Change'))
          .concat(mapReponse(connectRequests, 'Connect'))
          .concat(mapReponse(disconnectRequest, 'Cancellation'))
      );
    }

    if (serviceAgreements && myServiceProviders && potentialProviders && !serviceAgreementsLoading && !myServiceProvidersLoading && !potentialProvidersLoading) {
      setServiceProviders(setupProviders(serviceAgreements, myServiceProviders, potentialProviders));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changeRequests,
    connectRequests,
    disconnectRequest,
    serviceAgreements,
    myServiceProviders,
    potentialProviders,
    changeRequestsLoading,
    connectRequestsLoading,
    disconnectRequestsLoading,
    serviceAgreementsLoading,
    myServiceProvidersLoading,
    potentialProvidersLoading
  ]);

  useEffect(() => {
    setInProgressContracts([]);
    setActionItems([]);
    setCompletedActions([]);

    const actionableItems: any = [];
    const allInProgressItems: any = [];
    const allCompletedActions: any = [];

    const providerRequestsMap = providerRequests.reduce((all: any, request: any) => {
      all[request.providerId] = request;

      return all;
    }, {});

    const allProviders = (serviceProviders || []).reduce((all: any, provider: any) => {
      const transformProvider = (item: any) => {
        if (!providerRequestsMap[item.type === 'POTENTIAL' ? item.serviceProvider.id : item.id]) {
          return {
            ...item,
            isPaid: item.isPaid,
            action: 'Confirmation Required'
          };
        }
      };

      if (Array.isArray(provider)) {
        let to = transformProvider(provider[0]);
        let from = transformProvider(provider[1]);

        if (providerRequestsMap[provider[0].id]) {
          providerRequestsMap[provider[0].id].skip = true;
          to = providerRequestsMap[provider[0].id];
        }

        if (providerRequestsMap[provider[1].id]) {
          providerRequestsMap[provider[1].id].skip = true;
          from = providerRequestsMap[provider[1].id];
        }

        all.push({
          to,
          from
        });
      } else {
        const result = transformProvider(provider);

        if (result) all.push(result);
      }

      return all;
    }, []);

    if (providerRequests) {
      providerRequests.forEach((provider: any) => {
        if (provider.skip !== true) {
          if (ACTION_REQUIRED_PROVIDER_REQUEST_STATUS.includes(provider.emailStatus)) {
            actionableItems.push(provider);
          } else if (PENDING_PROVIDER_REQUEST_STATUS.includes(provider.emailStatus)) {
            allInProgressItems.push(provider);
          } else if (COMPLETED_PROVIDER_REQUEST_STATUS.includes(provider.emailStatus)) {
            allCompletedActions.push(provider);
          }
        }
      });
    }

    const allActionableItems: any = [...actionableItems, ...allProviders];

    setInProgressContracts(allInProgressItems);
    setCompletedActions(allCompletedActions);
    setActionItems(allActionableItems);

    setLocalData({
      metaData: {
        requiresActionItems: allActionableItems,
        inProgressItems: allInProgressItems,
        completedItems: allCompletedActions
      }
    });

    setAllData({
      requiresActionItems: allActionableItems,
      inProgressItems: allInProgressItems,
      completedItems: allCompletedActions
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerRequests, serviceProviders]);

  const filterItems = (e: any) => {
    const query = e.target.value;

    const everything = {
      requiresActionItems: actionItems,
      inProgressItems: inProgressContracts,
      completedItems: completedActions
    };

    if (query !== '') {
      everything.requiresActionItems = actionItems.filter((item: any) => (item.providerName || item.name).toLowerCase().includes(query.toLowerCase()));
      everything.inProgressItems = inProgressContracts.filter((item: any) => (item.providerName || item.providerName || item.name).toLowerCase().includes(query.toLowerCase()));
      everything.completedItems = completedActions.filter((item: any) => (item.providerName || item.name).toLowerCase().includes(query.toLowerCase()));
    }

    setSearchQuery(query);
    setAllData(everything);
  };

  useEffect(() => {
    if (user && user.addresses && user.addresses.length) {
      if (user.addresses.length === 1) {
        setUserAddress(user.addresses[0]);
      } else {
        const [moveInAddress] = user?.addresses.filter((address: Address) => {
          return address.moveOutDate === null;
        });
        setUserAddress(moveInAddress);
      }
    }
  }, [user]);

  const handleChangeInProviders = (changeType: string) => {
    if (changeType === 'none') {
      setStartPayment(true);

      return;
    }

    if (changeType === 'service-agreement' || changeType === 'all') {
      refetchServiceAgreements();
      refetchMyServiceProviders();
      refetchPotentialProviders();
    }

    if (changeType === 'provider-request' || changeType === 'all') {
      getChangeRequests();
      getConnectRequests();
      getDisconnectRequests();
    }

    props.reloadMe();
  };

  const handleAddProviders = () => {
    handleChangeInProviders('all');
    setOpenAddProviderDialog(false);
  };

  const onCorrespondence = (data: any) => {
    setCorrespondenceState({
      open: true,
      data
    });
  };

  return (
    <Box>
      <Flex justify='space-between'>
        <Flex>
          <Box>
            <Box h='110px' w='110px'>
              <Image src={MapImage} alt='map' />
            </Box>
          </Box>
          <Box px='2'>
            <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight='bold'>
              {userAddress ? userAddress?.addressLines[0] : null}
            </Text>
            <Text fontSize={{ base: 'md', lg: 'lg' }} fontWeight='bold'>
              {userAddress ? userAddress?.addressLines[1] : null}
            </Text>
            <Text fontSize='sm' fontWeight='semibold'>
              {userAddress?.postcode}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems='flex-end'>
          <Button
            bg={purpleColor}
            borderRadius={4}
            size={{ base: 'xs', md: 'md' }}
            color='white'
            p={{ base: '4', md: '2' }}
            _hover={{
              bg: purpleColorHover
            }}
            onClick={() => setOpenAddProviderDialog(true)}
          >
            Add Provider
          </Button>
        </Flex>
      </Flex>
      <Box py='7'>
        <Flex display={{ base: 'flex', md: 'none' }}>
          <SearchField placeholder='Search provider' value={searchQuery} onChange={filterItems} />
        </Flex>
        <Tabs colorScheme='purple' pos='relative'>
          <TabList>
            {[
              `All Services (${allData.requiresActionItems.length + allData.inProgressItems.length + allData.completedItems.length})`,
              `Requires Action (${allData.requiresActionItems.length})`,
              `In Progress (${allData.inProgressItems.length})`,
              `Completed (${allData.completedItems.length})`
            ].map(
              (tab, index) => {
                return (
                  <Tab key={index} fontSize={{ base: 'sm', md: 'md' }}>
                    {tab}
                  </Tab>
                );
              }
            )}
            <Flex display={{ base: 'none', md: 'flex' }} width={{ md: '200px', lg: '300px' }} pos='absolute' right='0px' top='-10px'>
              <SearchField size='lg' placeholder='Search provider' value={searchQuery} onChange={filterItems} />
            </Flex>
          </TabList>
          {
            (serviceAgreementsLoading || myServiceProvidersLoading || potentialProvidersLoading || changeRequestsLoading || connectRequestsLoading || disconnectRequestsLoading) ? <Progress colorScheme='purple' size='xs' isIndeterminate /> : null
          }

          <ServiceDiscovery onUpdate={() => handleChangeInProviders('all')} view='Services' />

          <PayNow onDone={() => handleChangeInProviders('all')} startPayment={startPayment} onCancel={() => setStartPayment(false)} totalProviderRequests={[...allData.requiresActionItems, ...allData.inProgressItems, ...allData.completedItems].reduce((count, item) => count + ((item.to && item.from) ? 2 : 1), 0)} />
          <TabPanels>
            <TabPanel padding={0}>
              <ProviderRowGroup onCorrespondence={onCorrespondence} providers={(allData.requiresActionItems || []).concat(allData.inProgressItems || []).concat(allData.completedItems || [])} onChange={handleChangeInProviders} />
            </TabPanel>
            <TabPanel padding={0}>
              <ProviderRowGroup onCorrespondence={onCorrespondence} providers={allData.requiresActionItems || []} onChange={handleChangeInProviders} />
            </TabPanel>
            <TabPanel padding={0}>
              <ProviderRowGroup onCorrespondence={onCorrespondence} providers={allData.inProgressItems || []} onChange={handleChangeInProviders} />
            </TabPanel>
            <TabPanel padding={0}>
              <ProviderRowGroup onCorrespondence={onCorrespondence} providers={allData.completedItems || []} onChange={handleChangeInProviders} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <AddProviderDialog showDialog={openAddProviderDialog} onConfirm={handleAddProviders} onCancel={() => setOpenAddProviderDialog(false)} />

      { (correspondenceState.open && correspondenceState.data) ? <Correspondence reloadMe={props.reloadMe} isOpen={correspondenceState.open} data={correspondenceState.data} onChange={() => handleChangeInProviders('provider-request')} onClose={() => setCorrespondenceState({
        open: false
      })} /> : null }
    </Box>
  );
};

export default Services;
