import { Center, Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import NonTransitionRow from './NonTransitionRow';

type TTransitionRowProps = {
  toProvider: any;
  fromProvider: any;
  onChange: Function;
  onCorrespondence: (id: number) => void;
};

function TransitionRow (props: TTransitionRowProps) {
  const { fromProvider, toProvider, onChange, onCorrespondence } = props;

  const fromProviderPaid = fromProvider.sendRequestOn === false && fromProvider.emailStatus === 'READY_TO_SEND';
  const toProviderPaid = toProvider.sendRequestOn === false && toProvider.emailStatus === 'READY_TO_SEND';

  return (
    <Box style={{
      borderInlineStartWidth: '1px',
      borderInlineStartColor: '#a379e7',
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
      marginLeft: '-18px',
      paddingLeft: '18px'
    }}>
      <NonTransitionRow provider={fromProvider} onChange={onChange} noBadge={fromProviderPaid === toProviderPaid} onCorrespondence={onCorrespondence} />
      <Center><ChevronDownIcon color='purple' /><ChevronDownIcon color='purple' /><ChevronDownIcon color='purple' /></Center>
      <NonTransitionRow provider={toProvider} onChange={onChange} noBadge={fromProviderPaid === toProviderPaid} onCorrespondence={onCorrespondence} />
    </Box>
  );
}

export default TransitionRow;
