import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useAxios from 'axios-hooks';

import Navbar from './Navbar';
import { API_ENDPOINTS, getLocalData, printError } from '../../utils';

import OnboardingSteps from './steps/OnboardingSteps';
import StepsDefinition, { TStep } from './steps';

import './onboarding.css';

import { PROCESSING } from '../../utils/appCostants';

function Onboarding () {
  const navigate = useNavigate();

  let { step: stepName } = useParams();
  stepName = stepName === 'email-verification' ? 'complete' : stepName;

  const [onBoardingSteps, setOnBoardingSteps] = useState<TStep[]>([]);
  const [currentStepId, setCurrentStepId] = useState<number>(0);

  const [{ data, loading }, reloadMe] = useAxios(API_ENDPOINTS.ME);

  const localData = getLocalData();

  useEffect(() => {
    if (data && !loading) {
      const allDone = data?.onboardingStatus?.missingFields.length === 0 || data?.onboardingStatus?.isOnboarded;

      if (allDone) navigate('/dashboard');

      const availableSteps = StepsDefinition(
        data?.onboardingStatus?.requiredFields?.includes(PROCESSING) ? [] : [3]
      );
      const currentStep = availableSteps.find((step) => step.path.includes(stepName as string));

      if (currentStep) setCurrentStepId(currentStep.id);
      setOnBoardingSteps(availableSteps);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, stepName]);

  if (!localData.token) navigate('/login' + document.location.search);

  const [searchParams] = useSearchParams();

  const [{ error }, addAdditionalAccount] = useAxios(
    API_ENDPOINTS.ADD_EMAIL, { manual: true }
  );
  if (error) {
    printError(`Error preparing call to confirm Google authorization with axios : ${error.message}`);
  }

  const googleCode = searchParams.get('code');
  const googleState = searchParams.get('state');

  useEffect(() => {
    const onGoogleLoginSuccess = async (code: any) => {
      await addAdditionalAccount({
        data: {
          code
        }
      });
    };

    if (googleCode && googleState && googleState === 'readonly.requested') {
      onGoogleLoginSuccess(searchParams.get('code')).then(() => {}, (error) => {
        printError(`Error on call to backend to confirm Google authorization : ${error.message}`);
      });
    }
  }, [googleCode, googleState, addAdditionalAccount, searchParams]);

  return (
    <>
      <Navbar />

      { data && onBoardingSteps.length && <OnboardingSteps me={data} loading={loading} stepName={stepName} reloadMe={reloadMe} onboardingSteps={onBoardingSteps} currentStep={currentStepId} /> }
    </>
  );
}

export default Onboarding;
