import { useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  FormLabel,
  Button,
  Image,
  Link,
  useToast
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';

import MonaddSideBanner from '../../components/ui/MonaddSideBanner';

import {
  validateEmail,
  dark,
  gray,
  purpleColor,
  red,
  purpleColorHover,
  API_ENDPOINTS
} from '../../utils';
import TextField from '../../components/ui/TextField';
import MonaddLogoBlack from '../../assets/Monadd_logo_black.svg';
import PasswordField from '../../components/ui/passwordField';

type TForgotPasswordProps = {
  viewType: 'FORGOT' | 'RESET';
}

const ForgotPassword = (props: TForgotPasswordProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { uuid } = useParams();

  const viewType = uuid ? 'RESET' : 'FORGOT';

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [{ loading }, forgotPassword] = useAxios(API_ENDPOINTS.FORGOT_PASSWORD, { manual: true });
  const [{ loading: resetLoading }, resetPassword] = useAxios(API_ENDPOINTS.RESET_PASSWORD, { manual: true });

  const handleForgotPassword = async () => {
    let validateFlag = false;
    const validations: { [key: string]: string } = {};

    if (uuid) {
      if (password === null || password === '' || password.length < 12) {
        validations.password = 'Valid password is required (at least 12 characters).';
      }

      if (rePassword !== password) {
        validations.rePassword = 'Both password must match.';
      }

      if (Object.keys(validations).length) {
        setErrors(validations);
        validateFlag = true;
      }

      if (validateFlag) return;

      await resetPassword({
        data: { password, uuid }
      });

      setPassword('');
      setRePassword('');
    } else {
      if (!validateEmail(email!)) {
        validations.email = 'Enter valid email address';
        validateFlag = true;
      }

      if (Object.keys(validations).length) {
        setErrors(validations);
        validateFlag = true;
      }

      if (validateFlag) return;

      await forgotPassword({
        data: { email }
      });

      setEmail('');
    }

    toast({
      title: uuid ? 'Password has been reset.' : 'Reset link has been sent to your email',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top'
    });

    navigate('/login');
  };

  return (
    <Flex
      gap={6}
      flexDirection={{
        sm: 'column-reverse',
        md: 'column-reverse',
        lg: 'row'
      }}

      mx={{
        sm: 0,
        md: 0,
        lg: '165px',
        xl: '165px'
      }}
      my={{
        sm: 0,
        md: 0,
        lg: '100px',
        xl: '100px'
      }}
    >
      <Box flex={1}>
        <MonaddSideBanner />
      </Box>
      <Box flex={1}>
        <Box>
          <Box px={{
            sm: '25px',
            md: '171px',
            lg: '10px',
            xl: '70px',
            '2xl': '79px'

          }} justifyContent="center" alignItems="center" mt={{
            sm: '54px',
            md: '54px',
            lg: '97px'
          }}>

            <Flex justifyContent="center" mb="36px">
              {<Image src={MonaddLogoBlack} display={{
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none'
              }} />}
            </Flex>
            <Heading
              fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
              fontWeight='700'
              color={dark}
              mb='16px'
              textAlign='center'
            >
              Reset Password
            </Heading>

            <Flex mb='25px'>
              <Divider my='25px' />

              <Divider my='25px' />
            </Flex>

            {
              viewType === 'RESET' ? <>
                <FormLabel
                  htmlFor='password'
                  color={dark}
                  ml={2}
                  fontWeight='400'
                  fontSize='16px'

                >
                  New password<Box as='span' color={purpleColor}>*</Box>
                </FormLabel>

                <PasswordField
                  placeholder='Enter new password'
                  name='password'
                  label='Password'
                  borderRadius={4}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                    setErrors({ ...errors, password: '' });
                  }}
                  value={password}
                  isRequired
                  errorBorderColor='red.300'
                  isInvalid={!!errors.password}
                />

                <FormLabel
                  htmlFor='repassword'
                  color={dark}
                  ml={2}
                  fontWeight='400'
                  fontSize='16px'

                >
                  Repeat password<Box as='span' color={purpleColor}>*</Box>
                </FormLabel>

                <PasswordField
                  placeholder='Repeat password'
                  name='repassword'
                  label='Repeat Password'
                  borderRadius={4}
                  onChange={(e: any) => {
                    setRePassword(e.target.value);
                    setErrors({ ...errors, repassword: '' });
                  }}
                  value={rePassword}
                  isRequired
                  errorBorderColor='red.300'
                  isInvalid={!!errors.repassword}
                />

              </> : <>
                <FormLabel
                  htmlFor='email'
                  color={dark}
                  ml={2}
                  fontWeight='400'
                  fontSize='16px'

                >
                  Email address<Box as='span' color={purpleColor}>*</Box>
                </FormLabel>

                <TextField
                  placeholder='Enter your email address'
                  label='Email address'
                  name='email'
                  type='email'
                  borderRadius={4}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                    setErrors({ ...errors, email: '' });
                  }}
                  value={email}
                  isRequired
                  errorBorderColor='red.300'
                  mb={errors.email ? '' : '25px'}
                  isInvalid={!!errors.email}
                />
                {!!errors.email && (
                  <Text color={red} mb='25px' p='3px'>
                    {errors.email}
                  </Text>
                )}
              </>
            }

            <Button
              w='100%'
              mt='40px'
              borderRadius={4}
              bg={purpleColor}
              px='30px'
              py='30px'
              color="white"
              onClick={() => handleForgotPassword()}
              isLoading={loading || resetLoading}
              isDisabled={loading || resetLoading}
              _hover={{
                background: purpleColorHover,
                color: 'white'
              }}
            >
              Reset
            </Button>
            <Box>
              <Text color={gray} textAlign='center' mt='5'>
                Do you want to proceed with  {''}
                <Text as='span' color={purpleColor}>
                  <Link as={RouterLink} to='/login' fontWeight={600}>Sign In</Link>
                </Text>
              </Text>
            </Box>
          </Box>

        </Box>
      </Box>

    </Flex>
  );
};

export default ForgotPassword;
