import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import {
  useNavigate,
  Link as RouterLink,
  useLocation,
  useSearchParams,
  useParams
} from 'react-router-dom';
import {
  Box,
  Divider,
  Flex,
  Link,
  Heading,
  Text,
  FormLabel,
  Button,
  useToast,
  Image,
  Tooltip,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import {
  API_ENDPOINTS,
  validateEmail,
  setLocalData,
  getLocalData,
  clearLocalStorage,
  dark,
  gray,
  lightGray,
  purpleColor,
  red,
  purpleColorHover,
  SPECIFIC_CUSTOMER_EMAIL
} from '../../utils';
import TextField from '../../components/ui/TextField';
import PasswordField from '../../components/ui/passwordField';
import GoogleAuth from './GoogleAuth';
import MonaddLogoBlack from '../../assets/Monadd_logo_black.svg';
import yotiLogo from '../../assets/icons/yoti_icon_charcoal_resized.svg';

type TAuthProps = {
  isRegister: boolean;
  viewType:
    | 'LOGIN'
    | 'REGISTER'
    | 'YOTI'
    | 'YOTI_CALLBACK'
    | 'AFFILIATE'
    | 'REFERRAL';
};

const AuthForm = (props: TAuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams();

  const toast = useToast();

  const { yoti } = getLocalData();

  if (!yoti && !props.isRegister) clearLocalStorage();

  const [email, setEmail] = useState<string>(
    yoti && yoti.email ? yoti.email : ''
  );
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [{ data, loading, error }, authenticate] = useAxios(
    props.isRegister ? API_ENDPOINTS.EMAIL_SIGNUP : API_ENDPOINTS.EMAIL_SIGNIN,
    { manual: true }
  );

  const [{ data: username, loading: getUserNameLoading }, getUserName] =
    useAxios(API_ENDPOINTS.GET_USER_NAME, { manual: true });

  useEffect(() => {
    if (location.pathname === '/join' && searchParams.get('aid')) {
      setLocalData({ aid: searchParams.get('aid') });
    }

    if (searchParams.get('code')) {
      toast({
        title: 'Adding Gmail permissions to your profile. You will be redirected shortly',
        status: 'success',
        isClosable: true,
        position: 'top',
        duration: 500
      });
    }

    if (params?.referralType) {
      setLocalData({
        referrer: `${params?.referralType}/${params?.userType}/${params?.username}`
      });
      getUserName({
        params: {
          user_type: params?.userType,
          username: params?.username
        }
      });
    }

    if (error) {
      const possibleErrorKeys = ['detail', 'password', 'email'];
      const errorMessage = possibleErrorKeys.reduce((msg, key) => {
        if (error?.response?.data[key]) {
          msg = Array.isArray(error?.response?.data[key]) ? error?.response?.data[key][0] : error?.response?.data[key];
        }

        return msg;
      }, 'Something went wrong.');

      toast({
        title: Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    } else if (data) {
      if (yoti) clearLocalStorage();
      setLocalData({
        token: data.token.access,
        refreshToken: data.token.refresh,
        email: data.user.email
      });

      if (data.user.email.includes(SPECIFIC_CUSTOMER_EMAIL)) {
        return navigate('/custom/checkout');
      }

      navigate('/onboarding/name');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data, loading, toast, yoti, props.isRegister]);

  const handleAuthentication = (e: any) => {
    e.preventDefault();

    const validations: { [key: string]: string } = {};

    if (password === null || password === '' || (password.length < (props.isRegister ? 12 : 8))) {
      validations.password = `Valid password is required${
        props.isRegister ? ' (at least 12 characters)' : ''
      }.`;
    }

    if (!validateEmail(email!)) {
      validations.email = 'Enter valid email address';
    }

    if (Object.keys(validations).length) {
      setErrors(validations);

      return;
    }

    authenticate({
      data: {
        email,
        password,
        ...(getLocalData().aid && { affiliate: getLocalData().aid }),
        ...(getLocalData().referrer && {
          referrer_string: getLocalData().referrer
        }),
        ...(yoti ? {
          phoneNumber: yoti.phoneNumber,
          firstName: yoti.firstName,
          lastName: yoti.lastName,
          address: [yoti.address, yoti.city],
          postcode: yoti.postcode,
          form_country: yoti.country,
          country: yoti.country,
          affiliate: 'yoti'
        } : {})
      }
    });
  };

  const resetYoti = () => {
    clearLocalStorage();
    navigate('/yoti');
  };

  return (
    <Box>
      {/* {isGoogleLogin && (
        <SuccessModal text={'We Are Verifying Your Details'} loading={true}
        open = {true}
         onDone={() => {
           setTimeout(() => {
             window.close();
           }, 1000);
         }}/>
      )} */}
      <Box
        px={{
          sm: '25px',
          md: '171px',
          lg: '10px',
          xl: '70px',
          '2xl': '79px'
        }}
        justifyContent="center"
        alignItems="center"
        mt={{
          sm: '54px',
          md: '54px',
          lg: '97px'
        }}
      >
        <Flex justifyContent="center" mb="36px">
          {
            <Image
              src={MonaddLogoBlack}
              display={{
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none'
              }}
            />
          }
        </Flex>
        <Heading
          fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
          fontWeight="700"
          color={dark}
          mb="16px"
          textAlign="center"
        >
          {props.isRegister ? yoti ? `Welcome ${yoti.firstName}!` : props.viewType === 'REFERRAL' ? `Your friend ${
                  username?.name || ''
                }  has gifted you 15% off your move` : 'Register for an account' : 'Welcome back!'}
        </Heading>
        {props.isRegister && (
          <>
            <Text
              color={lightGray}
              textAlign="center"
              px={{
                sm: '15px',
                md: 0,
                lg: '0',
                xl: 0,
                '2xl': '60px'
              }}
              fontSize={{ sm: '16px', md: '16px', lg: '18px' }}
              fontWeight="400"
            >
              {yoti ? 'We have imported following information, confirm your email and choose a password to continue' : ''}
            </Text>
            <Divider my="20px" />
          </>
        )}

        {!yoti && (
          <><GoogleAuth
            name={props.isRegister ? 'Register With Google' : 'Sign In With Google'} />
            <Flex alignItems={'center'} mt="2" justifyContent={'space-between'}>
              <Text fontSize={15} color={gray} textAlign="center">
                We'll automatically identify any accounts linked with your Gmail address.
              </Text>
              <Popover isLazy>
                <PopoverTrigger>
                  <Box as="span" m={3}>
                    <Icon fontSize={16} cursor="pointer" />
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>About Your Data</PopoverHeader>
                  <PopoverBody>
                    <Text>
                      When you link your email(s) we analyze only email that have a
                      commercial relationship to you. You'll see the services and
                      subscriptions that have sent transactional communications. By
                      default all personal domains are blocked from being processed.
                    </Text>
                    <br />
                    <Text>
                      We don't store information without consent, nor sell data. We
                      only use the information we gather to provide you visibility
                      into the services and subscriptions that are already linked to
                      you.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            {props.isRegister ? <Box>
                <Box
                  as="button"
                  width="100%"
                  onClick={() => navigate('/yoti')}
                  _hover={{
                    background: 'white',
                    color: 'teal.500'
                  }}
                >
                  <Flex borderRadius={4} boxShadow="lg" px="40px" py="19px">
                    <Image src={yotiLogo}/>
                    <Text
                      fontWeight="600"
                      color={dark}
                      display="flex"
                      justifyContent="center"
                      w="100%"
                    >
                      Register with Digital ID
                    </Text>
                  </Flex>
                </Box>
              </Box> : <></>}

          </>
        )}

        {yoti && props.isRegister && (
          <Box>
            <Box
              border="1px"
              style={{ borderColor: '#E2E8F0' }}
              textAlign="left"
              borderRadius="16px"
              px="30px"
              py="10px"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                w="100%"
                style={{
                  position: 'relative'
                }}
              >
                <Box w="80%">
                  <Text>
                    <strong>Name:</strong>{' '}
                    {`${yoti.firstName} ${yoti.lastName}`}
                  </Text>
                  <Text>
                    <strong>Phone Number:</strong> {yoti.phoneNumber}
                  </Text>
                  <Text>
                    <strong>Address:</strong>{' '}
                    {`${yoti.address}, ${yoti.postcode}`}
                  </Text>
                  <Text>
                    <strong>City/Town:</strong> {yoti.city}
                  </Text>
                  <Text>
                    <strong>Country:</strong> {yoti.countryName}
                  </Text>
                </Box>
                <Tooltip
                  label="Reset your information and start over."
                  aria-label="Reset"
                >
                  <RepeatIcon cursor="pointer" onClick={() => resetYoti()} />
                </Tooltip>
              </Flex>
            </Box>
            <Divider my="20px" />
          </Box>
        )}

        <Flex mb="25px">
          {!yoti && (
            <>
              <Divider my="25px" />
              <Text my="12px" px="25px" color={lightGray}>
                Or
              </Text>
              <Divider my="25px" />
            </>
          )}
        </Flex>

        <FormLabel
          htmlFor="email"
          color={dark}
          ml={2}
          fontWeight="400"
          fontSize="16px"
        >
          Email address
          <Box as="span" color={purpleColor}>
            *
          </Box>
        </FormLabel>

        <TextField
          placeholder="Enter your email address"
          label="Email address"
          name="email"
          type="email"
          borderRadius={4}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setErrors({ ...errors, email: '' });
          }}
          value={email}
          isRequired
          errorBorderColor="red.300"
          mb={errors.email ? '' : '25px'}
          isInvalid={!!errors.email}
        />
        {!!errors.email && (
          <Text color={red} mb="25px" p="3px">
            {errors.email}
          </Text>
        )}

        <FormLabel
          htmlFor="password"
          color={dark}
          ml={2}
          fontWeight="400"
          fontSize="16px"
        >
          {props.isRegister ? 'Create password' : 'Password'}
          <Box as="span" color={purpleColor}>
            *
          </Box>
        </FormLabel>

        <PasswordField
          placeholder={`Enter ${props.isRegister ? 'new' : 'your'} password`}
          name="password"
          label="Password"
          onChange={(e: any) => {
            setPassword(e.target.value);
            setErrors({ ...errors, password: '' });
          }}
          value={password}
          isRequired
          errorBorderColor="red.300"
          isInvalid={!!errors.password}
        />

        {!props.isRegister && (
          <Link
            p={2}
            fontSize="14px"
            color={purpleColor}
            as={RouterLink}
            to="/reset-password"
          >
            Forgot Password?
          </Link>
        )}

        {!!errors.password && (
          <Text color={red} mb="25px" p="3px">
            {errors.password}
          </Text>
        )}

        {props.isRegister && (
          <Text color={gray} mt="24px" textAlign="center">
            By signing up you accept our <br />
            <Text as="span" color={purpleColor} fontWeight={600}>
              <Link href="https://www.monadd.io/terms-conditions" isExternal>
                terms and conditions
              </Link>
            </Text>
            <Text as="span"> and</Text>
            <Text as="span" color={purpleColor} fontWeight={600}>
              <Link href="https://www.monadd.io/privacy-policy" isExternal>
                {' '}
                privacy policy
              </Link>
            </Text>
          </Text>
        )}

        <Button
          type='submit'
          w="100%"
          mt="40px"
          bg={purpleColor}
          px="30px"
          py="30px"
          color="white"
          borderRadius={4}
          onClick={(e: any) => handleAuthentication(e)}
          isLoading={loading || getUserNameLoading}
          isDisabled={loading || getUserNameLoading}
          _hover={{
            background: purpleColorHover,
            color: 'white'
          }}
        >
          {props.isRegister ? (yoti ? 'Continue' : 'Sign Up') : 'Sign In'}
        </Button>
      </Box>
      <Box>
        {props.isRegister ? (
          <Text color={gray} textAlign="center" mt="5">
            Already have an account? {''}
            <Text as="span" color={purpleColor}>
              <Link as={RouterLink} to="/login" fontWeight={600}>
                Sign In
              </Link>
            </Text>
          </Text>
        ) : (
          <Text color={gray} textAlign="center" mt="5">
            Do not have an account? {''}
            <Text as="span" color={purpleColor}>
              <Link as={RouterLink} to="/register" fontWeight={600}>
                Sign Up
              </Link>
            </Text>
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default AuthForm;
