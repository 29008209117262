import {
  Flex,
  useColorModeValue,
  Image,
  Button
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import Logo from '../../../assets/navbar-logo.svg';
import '../dashboard.css';

interface MobileProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 0, lg: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      w='100vw'
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ sm: 'space-between' }}
      display={{ base: 'flex', md: 'flex', lg: 'none' }}
    >
      <Button
        variant='outline'
        borderRadius={4}
        onClick={onOpen}
      >
        <HamburgerIcon/>
      </Button>
      <Flex justifyContent="center" w="full">
        <Image src={Logo} />
      </Flex>
    </Flex>
  );
};

export default MobileNav;
