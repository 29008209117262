import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../../../../components/ui/DashboardCard';
import Card from '../../../../components/ui/Card';
import { CLEARBIT } from '../../../../utils';
import DefaultLogo from '../../../../assets/providers-logos/default-logo.svg';
import { Stack } from '@chakra-ui/react';

type TDashboardWidgetProps = {
  emailAddresses: number;
  total: number;
  finished: boolean;
  discovered: any;
};

function getWindowDimensions () {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function DashboardWidget (props: TDashboardWidgetProps) {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      if (windowDimensions.width !== getWindowDimensions().width) setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardCard
      cardType='yellowCard'
      primaryText={`We have discovered ${props.total} service(s)`}
      btnText='Services'
      image='homeIcon'
      disabled={!props.total}
      content={
        <Stack direction='row'>
          {props.discovered.slice(0, windowDimensions.width > 1000 ? 6 : 4).map((provider: any, index: number) => (
            <Card
              key={index}
              onClick={() => {
                navigate('/services');
              }}
              src={provider.logo || (CLEARBIT + provider.name)}
              fallbackSrc={DefaultLogo}
              w='50px'
              py='0px'
              px='0'
              pl='0px'
            />
          ))}
        </Stack>
      }
      imageProperties={{
        width: '130px',
        top: { base: '60px', lg: '30px' },
        right: { base: '10px', lg: '10px' }
      }}
    />
  );
}

export default DashboardWidget;
