import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Image, Flex, Heading, Text, Button, Spinner, useToast } from '@chakra-ui/react';

import { dark, lightGray, purpleColor, purpleColorHover } from '../../../../utils/themeSettings';
import checkIcon from '../../../../assets/icons/big-check.svg';
import { API_ENDPOINTS } from '../../../../utils';
import { TStepProps } from '../../types';

function ProcessEmail (props: TStepProps) {
  const toast = useToast();

  const [processing, setProcessing] = useState<boolean>(true);
  const [retry, setRetry] = useState<number>(0);

  const [{ data, loading, error }, reProcessEmails] = useAxios(API_ENDPOINTS.PROCESS_EMAILS);

  useEffect(() => {
    if (!loading && data) {
      if (data.taskStatus === 'success') {
        setProcessing(false);
      } else {
        setTimeout(reProcessEmails, 1000);
      }
    } else if (!loading && error) {
      if (retry < 5) {
        setRetry(retry + 1);
        setTimeout(reProcessEmails, 2000);
      } else {
        toast({
          title: 'Cannot process emails, you can continue to next step.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top'
        });
        setProcessing(false);
      }
    }
  }, [data, loading, error, reProcessEmails, retry, toast]);

  return (
    <Flex textAlign="center" w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }} justifyContent="center" direction="column" alignItems="center" onClick={event => event.stopPropagation()}>
      <Heading fontSize="36px" color={dark}>
        {processing ? 'Connecting' : 'All done'}
      </Heading>
      <Text color={lightGray} px="20px" my="15px" >
        We’ll just be a minute.<br />
        We are currently connecting all your accounts.
      </Text>

      <Flex justifyContent="center" m="38px" >
        {processing ? <Spinner size='xl' color='#805AD5' width='150px' height='150px' emptyColor='gray.200' thickness='20px' speed='0.8s' /> : <Image width='150px' src={checkIcon} />}

      </Flex>
      <Button
        isDisabled={processing}
        isLoading={processing}
        mt="55px"
        my='30px'
        w='100%'
        borderRadius={4}
        bg={purpleColor}
        _hover={{ bg: purpleColorHover }}
        color="white"
        px='30px'
        py='30px'
        onClick={() => props.onStepChange()}
      >
        Continue
      </Button>
    </Flex >
  );
}

export default ProcessEmail;
