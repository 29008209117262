import { Flex, Text } from '@chakra-ui/react';
import { MdLockOutline } from 'react-icons/md';
import { gray } from '../../utils';

const SeucreInfoToolTip = () => {
  return (
    <Flex justifyContent="center" my="10px">
      <Flex alignItems="center">
        <MdLockOutline color={gray} />
        <Text color={gray} px="10px">
        Your information is protected at all times with bank grade 256-bit encryption at rest and in-transit.
        </Text>
      </Flex>
    </Flex>
  );
};

export default SeucreInfoToolTip;
