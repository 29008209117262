import { useRef, useState } from 'react';

import {
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialog,
  Flex,
  Checkbox,
  Text
} from '@chakra-ui/react';

type TConfirmDialogProps = {
  showDialog: boolean;
  onConfirm: Function;
  onCancel: Function;
  isLoading?: boolean;
};

function ConfirmProviderDialog (props: TConfirmDialogProps) {
  const { showDialog, onConfirm, onCancel, isLoading } = props;
  const [confirmConsent, setConfirmConsent] = useState<boolean>(false);

  const cancelRef = useRef(null);
  const modalSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1280) return 'xl';
    else if (screenWidth >= 992) return 'lg';
    else if (screenWidth >= 768) return 'md';
    else if (screenWidth >= 480) return 'sm';
    else return 'xs';
  };

  return (
    <AlertDialog
      size={modalSize()}
      motionPreset='slideInBottom'
      onClose={() => onCancel()}
      isOpen={showDialog}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent borderRadius={4}>
        <AlertDialogHeader>Confirm?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          This will send request to your provider, this action cannot be undone. Are you sure you want to continue?
          <Flex mt="24px" px={{ base: '11px', md: '0', lg: '0' }}>
        <Checkbox
          borderRadius="22px"
          size='lg'
          colorScheme="purple"
          isChecked={confirmConsent}
          onChange={(e) => setConfirmConsent(e.target.checked)}
        />
        <Text fontSize="14px" ml="20px">
          Confirm all future prompts for this session.
        </Text>
      </Flex>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button borderRadius={4}isDisabled={isLoading} onClick={() => onCancel()}>No</Button>
          <Button borderRadius={4} colorScheme='red' ml={3} onClick={() => onConfirm(confirmConsent)} isLoading={isLoading} isDisabled={isLoading}>Yes</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ConfirmProviderDialog;
