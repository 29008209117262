import { Flex, Spinner } from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import { offWhite } from '../../../utils/themeSettings';
import SeucreInfoToolTip from '../../../components/ui/SecureInfoTooltip';
import StepWrapper from './StepWrapper';
import { TStep, TOnboardingStepsProps } from './Steps';

import '../onboarding.css';

function OnboardingSteps ({ loading, me, reloadMe, stepName, onboardingSteps }: TOnboardingStepsProps) {
  const { setStep, activeStep } = useSteps({
    initialStep: (onboardingSteps.find((step) => step.path.includes(stepName as string))?.id) || 0
  });

  const onStepChange = async (stepId?: number, reloadOnly?: boolean) => {
    await reloadMe();

    if (reloadOnly) return;

    stepId = stepId === undefined || stepId === null ? (activeStep + 1) : stepId;

    const nextStep = onboardingSteps.find((step) => step.id === stepId);
    window.history.replaceState(null, 'Monadd', `/onboarding${nextStep?.path}`);

    setStep(stepId);
  };

  return (
    <Flex flexDir="column" width="100%">
      <Steps
        id="profile-steps"
        size="sm"
        activeStep={activeStep}
        bg={{ base: 'white', md: offWhite, lg: offWhite }}
        py="24px"
        textColor="black"
        colorScheme="purple"
        onClickStep={(step) => onStepChange(step)}
      >
        {onboardingSteps.map((step: TStep) => (
          <Step label={step.label} key={step.path}>
            <Flex
              id="inner-form"
              justifyContent="center"
              mt={{ base: '24px', md: '64px', lg: '64px' }}
              flexDirection="column"
              alignItems="center"
            >
              {
                loading ? <Spinner size='xl' /> : <StepWrapper me={me} step={step.id} onStepChange={onStepChange} onboardingSteps={onboardingSteps}><step.component onStepChange={onStepChange} me={me} step={step.id} /></StepWrapper>
              }
              {!loading && step.id !== 5 && <SeucreInfoToolTip />}
            </Flex>
          </Step>
        ))}
      </Steps>
    </Flex>
  );
}

export default OnboardingSteps;
