import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Spinner,
  Input,
  Spacer,
  useToast
} from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { dark, gray, purpleColor, purpleColorHover } from '../../../utils/themeSettings';
import CustomSelect from '../../../components/ui/CustomSelect';

import './checkout.css';

type TPaymentProps = {
  price: number;
  clientSecret: string;
  currency: string;
  appliedCoupon: string;
  changeCurrency: (currencyCode: string) => void;
  applyCoupon: (coupon: string) => void;
};

const CURRENCY_SYM: { [key: string]: string } = {
  GBP: '£',
  USD: '$',
  EUR: '€'
};

function Payment ({ price, currency, appliedCoupon, changeCurrency, applyCoupon }: TPaymentProps) {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>(appliedCoupon || '');

  useEffect(() => {
    if (!stripe || !elements) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [stripe, elements]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_REDIRECT_PAYMENTS_URL as string
      }
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      toast({
        title: error?.message || 'Something went wrong with your payment method',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
    } else {
      toast({
        title: 'Something went wrong with your payment method, Please contact Monadd support',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      });
    }

    setLoading(false);
  };

  return (
    <Box textAlign='center' mx={{ sm: '0px', md: '15px' }} onClick={event => event.stopPropagation()}>
      <Heading fontSize={{ sm: '28px', md: '28px', lg: '36px' }} mt={{ sm: '24px', md: '28px', lg: '5px' }} color={dark}>
        Checkout
      </Heading>
      <form id='payment-form' onSubmit={handleSubmit}>
        <Flex justifyContent='center' direction='column' my='10px' w='100%'>
          <Box mb='5px'>
            {stripe && elements ? <PaymentElement /> : <Spinner size='xl' />}
          </Box>
          <Box>
            <Box my='5px' textAlign={{ base: 'left', md: 'center', lg: 'center' }}>
              <Heading fontSize={{ sm: '22px', md: '26px' }} color={dark}>
                Order summary
              </Heading>
            </Box>
            <Flex justifyContent='center'>
              <Box w='390px' my='10px'>
                <Flex justifyContent='space-between' my='8px'>
                  <Heading fontSize={{ sm: '18px', md: '20px' }} color={dark}>
                    Pay Per Move
                  </Heading>
                  <Heading fontSize={{ sm: '18px', md: '20px' }} color={dark}>{CURRENCY_SYM[currency]} {price / 100}</Heading>
                </Flex>
                <Text textAlign='left' fontSize='14px' color={gray} my='8px'>
                  A one-time payment
                </Text>
                <Box mt={'20px'}>
                  <Text textAlign='left' fontSize='14px' color={gray} my='8px'>
                    Change Currency
                  </Text>
                  <CustomSelect
                    onChange={(event: any) => {
                      changeCurrency(event.target.value);
                    }}
                    h='30px'
                    mt={{ base: '16px', md: 0, lg: 0 }}
                    w={{ base: '100%', md: '150px', lg: '150px' }}
                    color={dark}
                    defaultValue={currency}>
                    {
                      Object.entries(CURRENCY_SYM).map(([key, value]) => <option key={key} value={key}>{key} {value}</option>)
                    }
                  </CustomSelect>
                </Box>
                <Box mt={'20px'}>
                  <Text textAlign='left' fontSize='14px' color={gray} my='8px'>
                    Coupon
                  </Text>
                  <Flex justifyContent='space-between' my='8px'>
                    <Input
                      size={'sm'}
                      type='text'
                      placeholder='Enter coupon code'
                      name='coupon'
                      value={coupon}
                      onChange={(e: any) => setCoupon(e.target.value)}
                      borderRadius={4}
                      marginRight={2}
                    />
                    <Spacer />
                    <Button
                      size={'sm'}
                      borderRadius={4}
                      bg={purpleColor}
                      _hover={{ bg: purpleColorHover }}
                      color='white'
                      onClick={() => applyCoupon(coupon)}
                    >
                        Apply
                      </Button>
                  </Flex>
                </Box>

                <hr style={{ marginTop: '10px' }} />
                <Flex justifyContent='space-between' my='8px'>
                  <Heading fontSize='20px' color={dark}>
                    Total
                  </Heading>
                  <Heading fontSize='20px' color={dark}>{CURRENCY_SYM[currency]} {price / 100}</Heading>
                </Flex>

                <Button
                  my='10px'
                  w='100%'
                  mt='20px'
                  mb='5px'
                  borderRadius={4}
                  bg={purpleColor}
                  _hover={{ bg: purpleColorHover }}
                  color='white'
                  px='30px'
                  py='25px'
                  isLoading={loading}
                  isDisabled={loading}
                  onClick={handleSubmit}
                >
                  Pay now
                </Button>
              </Box>
            </Flex>

          </Box>
        </Flex>
      </form>
    </Box>
  );
}

export default Payment;
