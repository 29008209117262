import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Spinner, Flex } from '@chakra-ui/react';

import Payment from './Payment';
import { TMe } from '../types';
import { API_ENDPOINTS, iso2Currency } from '../../../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

type TStartPaymentProps = {
  me: TMe;
};

function StartPayment ({ me }: TStartPaymentProps) {
  const countryCode = me.addresses[0].moveInDate ? me.addresses[1].country : me.addresses[0].country;
  const defaultCurrency = iso2Currency(countryCode);

  const [currency, setCurrency] = useState(defaultCurrency);
  const [paymentIntent, setPaymentIntent] = useState<any>({});

  const [{ data, loading }, reCreateIntent] = useAxios({ ...API_ENDPOINTS.CREATE_PAYMENT_SESSION, params: { currency: currency || defaultCurrency } });
  const [{ data: newIntentData, loading: newIntentLoading }, updateIntent] = useAxios(API_ENDPOINTS.UPDATE_PAYMENT_SESSION, { manual: true });

  useEffect(() => {
    if (!loading && data) {
      setPaymentIntent(data);

      if (data.currency && currency !== data.currency) setCurrency(data.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  useEffect(() => {
    if (!newIntentLoading && newIntentData) {
      setCurrency(data.currency);
      setPaymentIntent(newIntentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIntentData, newIntentLoading]);

  if (loading || newIntentLoading) return <Flex alignItems='center' justifyContent='center' h={{ sm: '90vh', md: 'auto' }} w='100%'><Spinner size='xl' /></Flex>;

  return (
    <Elements options={{
      clientSecret: paymentIntent?.clientSecret?.clientSecret,
      appearance: {
        theme: 'flat',
        variables: {
          borderRadius: '15px'
        }
      }
    }} stripe={stripePromise}>
      <Payment
        clientSecret={paymentIntent?.clientSecret?.clientSecret}
        price={paymentIntent.stripePrice}
        currency={paymentIntent.currency}
        appliedCoupon={paymentIntent.coupon || ''}
        changeCurrency={(newCurrency: string) => {
          if (newCurrency !== currency) {
            setCurrency(newCurrency);
            reCreateIntent({
              params: { currency: newCurrency }
            });
          }
        }}
        applyCoupon={(coupon: string) => {
          const params = {
            currency, coupon, intent_id: data.clientSecret.id
          };

          if (coupon !== '') {
            params.coupon = coupon;
          }

          updateIntent({ params });
        }}
      />
    </Elements>
  );
}

export default StartPayment;
