import PersonalDetails from './PersonalDetails';
import AdditionalAccounts from './AdditionalAccounts';
import Addresses from './Addresses';
import ProcessEmail from './ProcessEmail';
import VerifyProfile from './VerifyProfile';

import checkIcon from '../../../assets/icons/signup-checks.svg';

export type TStep = {
  id: number;
  label: string;
  path: string;
  component?: any;
  icon: any;
  field: string[];
};

export type TOnboardingStepsProps = {
  loading: boolean;
  stepName: string | undefined;
  me: any;
  reloadMe: Function;
  onboardingSteps: TStep[];
  currentStep: number;
};

const steps: TStep[] = [
  {
    id: 0,
    label: 'Personal details',
    path: '/name',
    component: PersonalDetails,
    icon: checkIcon,
    field: ['name']
  },
  {
    id: 1,
    label: 'Add emails',
    path: '/add-accounts',
    component: AdditionalAccounts,
    icon: checkIcon,
    field: ['add_accounts']
  },
  {
    id: 2,
    label: 'Addresses',
    path: '/addresses',
    component: Addresses,
    icon: checkIcon,
    field: ['old_location', 'new_location']
  },
  {
    id: 3,
    label: 'Processing',
    path: '/email-processing',
    component: ProcessEmail,
    icon: checkIcon,
    field: []
  },
  {
    id: 5,
    label: 'Finish',
    path: '/complete',
    component: VerifyProfile,
    icon: checkIcon,
    field: ['email', 'phone_number']
  }
];

function getSteps (notIncludeSteps?: number[]) {
  return steps.filter((step) => !(notIncludeSteps || []).includes(step.id)).map((step, index) => ({
    ...step,
    id: index
  }));
}

export default getSteps;
