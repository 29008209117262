import { FaCheck, FaTimes } from 'react-icons/fa';
import {
  Avatar,
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem
} from '@chakra-ui/react';

import { dark, gray, green, red } from '../../../../utils/themeSettings';

type TAccountProp = {
  id: string;
  displayPicture: string;
  email: string;
  gmailReadScope: boolean | null;
  gmailSendScope: boolean | null;
};

function Account (props: TAccountProp) {
  const { id, displayPicture, email, gmailReadScope } = props;

  return (
    <Flex alignItems="center" my="12px" key={id}>
      <Wrap>
        <WrapItem>
          <Avatar name="" src={displayPicture} size="lg" />
        </WrapItem>
      </Wrap>
      <Box textAlign="left" ml="17px">
        <Text textColor={dark}>{email}</Text>
        <Flex alignItems="center" fontSize="14px">
          { gmailReadScope ? <FaCheck color={green} /> : <FaTimes color={red} /> }
          <Text ml="6px" color={gray}>
            {' '}
            { gmailReadScope ? 'Permissions Accepted' : 'Not all permissions granted' }
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Account;
