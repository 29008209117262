// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Image,
  Avatar,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Menu
} from '@chakra-ui/react';

import dashboardIcon from '../../../assets/dashboard/dashboardIcon.svg';
import servicesIcon from '../../../assets/dashboard/servicesIcon.svg';
import activityIcon from '../../../assets/dashboard/activityIcon.svg';
import spacesIcon from '../../../assets/dashboard/spacesIcon.svg';
import supportIcon from '../../../assets/dashboard/support.svg';
import logoutIcon from '../../../assets/dashboard/logout.svg';
import helpIcon from '../../../assets/dashboard/help.svg';
import Logo from '../../../assets/navbar-logo.svg';

import NavBarItem from './NavBarItem';

import '../dashboard.css';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { clearLocalStorage } from '../../../utils';
import { useSelector } from 'react-redux';
import { UserState } from '../types';

interface LinkItemProps {
  name: string;
  icon: any;
  activeIcon?: any;
  active?: boolean;
  path?: string;
  isClicked?: boolean;
}

interface SidebarProps {
  onClose: () => void;
}

const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <Image src={dashboardIcon} />,
    activeIcon: <Image src={dashboardIcon} />,
    active: false,
    isClicked: true
  },
  {
    name: 'Services',
    path: '/services',
    icon: <Image src={servicesIcon} />,
    activeIcon: <Image color='black' src={servicesIcon} />,
    active: false,
    isClicked: false
  },
  {
    name: 'Spaces',
    path: '/spaces',
    icon: <Image src={spacesIcon} />,
    activeIcon: <Image color='black' src={spacesIcon} />,
    active: false,
    isClicked: false
  },
  {
    name: 'Activity',
    path: '/activity',
    icon: <Image src={activityIcon} />,
    activeIcon: <Image color='black' src={activityIcon} />,
    active: false,
    isClicked: false
  }
];

const NavBar = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate();

  const user = useSelector((state: UserState) => state.user.value);

  const logoutUser = () => {
    clearLocalStorage();
    navigate('/login');
  };

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 'full', lg: '256px' }}
      top="0"
      pos="fixed"
      h="100vh"
    >
      <Flex
        h="10"
        alignItems="center"
        mx="8"
        my="8"
        justifyContent="space-between"
      >
        <Image src={Logo} />

        <CloseButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
        />
      </Flex>
      <Flex
        mx="4"
        h="86vh"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          {LinkItems.map((link) => (
            <NavBarItem
              path={link?.path || ''}
              key={link.name}
              isClicked={link.isClicked || false}
              icon={link.activeIcon}
              title={link.name}
            />
          ))}
        </Box>
        <Flex
          direction={{ base: 'row', lg: 'column' }}
          gap={2}
          flexWrap='wrap'
        >
          <Flex
            ml={2}
            gap={2}
            alignItems='center'
            display={{ base: 'flex', md: 'flex', lg: 'none' }}
            w='270px'
          >
            <Avatar
              name={`${user?.firstName} ${user?.lastName}`}
              width='50px'
              height='50px'
              bg='purple.300'
            />
            <Box>
              <Text fontWeight='bold'>{user?.firstName} {user?.lastName}</Text>
              <Text fontSize='sm'>Verified</Text>
            </Box>
            <Menu>
              <MenuButton>
                <ChevronDownIcon fontSize='xl' />
              </MenuButton>
              <MenuList
                pos='relative'
                left='-100px'
                width='150px'
                zIndex={20}
                color='#909090'
              >
                <MenuItem w='100%'>
                  <Button
                    bg='none'
                    color='black'
                    _hover={{ bg: 'none' }}
                    onClick={() => navigate('/profile')}
                  >
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15.6172 12.592L15.4124 12.7532L15.6547 12.8493C17.3163 13.5087 18.7671 14.6079 19.8516 16.0291C20.9361 17.4502 21.6134 19.1397 21.8109 20.9164C21.8346 21.1392 21.7694 21.3624 21.6294 21.5374C21.491 21.7104 21.2907 21.8225 21.071 21.8499L20.97 21.8499L20.9691 21.8499C20.759 21.8512 20.5558 21.7745 20.3988 21.6348C20.2419 21.4951 20.1422 21.3022 20.1191 21.0934L20.1191 21.0931C19.8954 19.1017 18.9458 17.2624 17.4517 15.9268C15.9577 14.5912 14.024 13.8529 12.02 13.8529C10.016 13.8529 8.08227 14.5912 6.58824 15.9268C5.09421 17.2624 4.14464 19.1017 3.92093 21.0931L3.9209 21.0935C3.89607 21.3192 3.78262 21.5257 3.60549 21.6677C3.42836 21.8098 3.20207 21.8756 2.9764 21.8508C2.75072 21.826 2.54416 21.7125 2.40214 21.5354C2.33181 21.4477 2.27946 21.347 2.24805 21.239C2.21664 21.1311 2.20681 21.018 2.2191 20.9063C2.41569 19.1346 3.08935 17.4495 4.16829 16.0306C5.24725 14.6116 6.69106 13.5121 8.34578 12.8491L8.58682 12.7526L8.38275 12.592C7.42686 11.8399 6.72916 10.8086 6.38669 9.64159C6.04422 8.47453 6.07403 7.22975 6.47195 6.08042C6.86988 4.9311 7.61615 3.93438 8.60693 3.22893C9.59771 2.52348 10.7837 2.14438 12 2.14438C13.2163 2.14438 14.4023 2.52348 15.3931 3.22893C16.3838 3.93438 17.1301 4.9311 17.528 6.08042C17.926 7.22975 17.9558 8.47453 17.6133 9.64159C17.2708 10.8086 16.5731 11.8399 15.6172 12.592ZM9.69438 11.4505C10.3768 11.9065 11.1792 12.1499 12 12.1499C13.1006 12.1499 14.1562 11.7127 14.9345 10.9344C15.7128 10.1561 16.15 9.10053 16.15 7.99988C16.15 7.17909 15.9066 6.37673 15.4506 5.69426C14.9946 5.0118 14.3464 4.47988 13.5881 4.16578C12.8298 3.85168 11.9954 3.76949 11.1904 3.92962C10.3854 4.08975 9.64589 4.485 9.0655 5.06539C8.48512 5.64578 8.08987 6.38523 7.92974 7.19026C7.76961 7.99528 7.85179 8.8297 8.1659 9.58802C8.48 10.3463 9.01192 10.9945 9.69438 11.4505Z'
                        fill='black'
                      />
                    </svg>
                    <Text fontSize='sm' ml={2}>Profile</Text>
                  </Button>
                </MenuItem>
                <MenuItem w='100%'>
                  <Button
                    onClick={logoutUser}
                    bg='none'
                    color='black'
                    _hover={{ bg: 'none' }}
                  >
                    <Image color='black' width='25px' src={logoutIcon} />
                    <Text fontSize='sm' ml={2}>Logout</Text>
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <NavBarItem
            icon={<Image src={supportIcon} />}
            title='Support'
            path='/support'
            url='mailto:support@monadd.io'
          />
          <NavBarItem
            icon={<Image src={helpIcon} />}
            title='Help'
            path='/help'
            url='https://www.monadd.io/faq'
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
