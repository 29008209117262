import {
  Box,
  Button,
  Heading,
  Text,
  Divider,
  FormLabel,
  Input,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useState } from 'react';

import {
  dark,
  API_ENDPOINTS,
  purpleColor,
  purpleColorHover,
  gray
} from '../../../../utils';

import { TStepProps } from '../../types';

export type TPersonalInformaton = {
  firstName: string;
  lastName: string;
};

export default function PersonalDetails (props: TStepProps) {
  const { me, onStepChange } = props;

  const [formData, setFormData] = useState<TPersonalInformaton>({
    firstName: me?.firstName || '',
    lastName: me?.lastName || ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [{ loading }, submitPersonalDetails] = useAxios(
    API_ENDPOINTS.PERSONAL_INFORMATION,
    { manual: true }
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const validations: { [key: string]: string } = {};

    if (formData.firstName.length < 2) { validations.firstName = 'Enter a valid first name.'; }
    if (formData.lastName.length < 2) { validations.lastName = 'Enter a valid last name.'; }

    if (Object.keys(validations).length) {
      setErrors(validations);

      return;
    }

    await submitPersonalDetails({ data: formData });

    onStepChange();
  };

  return (
    <Box
      px={{
        sm: '25px',
        md: '171px',
        lg: '10px',
        xl: '70px',
        '2xl': '79px'
      }}
      w={{ base: '320px', sm: '400px', md: '400px', lg: '450px', xl: '590px', '2xl': '608px' }}
      pt={'10px'}
      textAlign='center'
      justifyContent='center'
      alignItems='center'
      onClick={(event) => event.stopPropagation()}
    >
      <Heading
        fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
        mt={{ sm: '24px', md: '28px', lg: 0 }}
        color={dark}
      >
        Complete Your Profile!
      </Heading>
      <Text
        id='description'
        color={gray}
        px={{ base: '30px', md: '30px', lg: '30px' }}
        my='15px'
      >
        For the purpose of industry regulation, your details are required.
      </Text>
      <Divider className='hide-show' />

      <form onSubmit={handleSubmit} style={{
        margin: '0 5px'
      }}>
        <FormControl isInvalid={!!errors.firstName} mt='30px'>
          <FormLabel>First name</FormLabel>
          <Input
            type='text'
            placeholder='Enter your first name'
            name='firstName'
            autoComplete='firstName'
            borderRadius={4}
            px={'30px'}
            py={'30px'}
            onChange={(e: any) => {
              setFormData({ ...formData, firstName: e.target.value });
              setErrors({ ...errors, firstName: '' });
            }}
            value={formData.firstName}
          />
          {!!errors.firstName && (
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!errors.lastName} mt='30px'>
          <FormLabel>Last name</FormLabel>
          <Input
            type='text'
            placeholder='Enter your last name'
            name='lastName'
            autoComplete='lastName'
            borderRadius={4}
            px={'30px'}
            py={'30px'}
            onChange={(e: any) => {
              setFormData({ ...formData, lastName: e.target.value });
              setErrors({ ...errors, lastName: '' });
            }}
            value={formData.lastName}
          />
          {!!errors.lastName && (
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          )}
        </FormControl>

        <Button
          my='30px'
          w='100%'
          mt='40px'
          borderRadius={4}
          bg={purpleColor}
          _hover={{ bg: purpleColorHover }}
          color='white'
          px='30px'
          py='30px'
          isLoading={loading}
          isDisabled={loading}
          onClick={(e) => handleSubmit(e)}
          type='submit'
        >
          Save & Continue
        </Button>
      </form>
    </Box>
  );
}
