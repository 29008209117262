import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from '@chakra-ui/react';

import SeucreInfoToolTip from '../../../../components/ui/SecureInfoTooltip';
import PhoneNumber from './PhoneNumber';
import VerifyCode from './VerifyCode';

import { API_ENDPOINTS } from '../../../../utils';
import { TStepProps } from '../../types';
import AllSet from './AllSet';
import VerifyEmail from './VerifyEmail';

function VerifyProfile (props: TStepProps) {
  const { me } = props;
  const navigate = useNavigate();

  const mobilePhoneVerificationDone = !props.me.onboardingStatus.missingFields?.includes('phone_number') || props.me.onboardingStatus.isOnboarded;
  const emailVerificationDone = !props.me.onboardingStatus.missingFields?.includes('email') || props.me.onboardingStatus.isOnboarded;

  const [sent, setSent] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [validView, setValidView] = useState<string>(
    mobilePhoneVerificationDone && emailVerificationDone ? 'ALL_SET' : (
      mobilePhoneVerificationDone && !emailVerificationDone ? 'EMAIL' : 'PHONE'
    )
  );

  const [{ data, loading, error }, addPhoneNumber] = useAxios(API_ENDPOINTS.ADD_PHONE_NUMBER, { manual: true });

  useEffect(() => {
    if (!loading && error) {
      if (error?.response?.data && Array.isArray(error.response.data) && error.response.data.length) {
        setPhoneNumberError(error?.response?.data[0]);
      }
    }
  }, [data, error, loading, navigate]);

  const onSend = async (phone: string) => {
    setPhoneNumber(phone);
    await addPhoneNumber({ data: { phoneNumber: phone } });

    setSent(true);
  };

  const goBack = () => props.onStepChange(3);

  return (
    <Modal size={sent ? 'xl' : 'md'} isOpen={true} onClose={() => { }} closeOnEsc={false} motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={4}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton onClick={() => goBack()} />
        <ModalBody>
          <Box textAlign="center">
            {
              validView === 'ALL_SET' ? <AllSet /> : validView === 'EMAIL' ? <VerifyEmail email={props.me.email} /> : sent ? <VerifyCode onSend={onSend} loading={loading} phoneNumber={phoneNumber} phoneNumberError={phoneNumberError} onSuccess={(showView: string) => setValidView(showView)} /> : <PhoneNumber phoneNumber={props.me.phoneNumber || ''} defaultCountry={me.addresses[0].moveInDate ? me.addresses[1].country : me.addresses[0].country} onSend={onSend} loading={loading} phoneNumberError={phoneNumberError} />
            }
            <SeucreInfoToolTip />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default VerifyProfile;
