import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';

const SearchField = (props: any) => {
  return (
    <InputGroup alignItems='center'>
      <InputLeftElement h='100%' pointerEvents='none'>
        <SearchIcon color='gray.500' />
      </InputLeftElement>
      <Input
        type='text'
        border='solid 2px #e2e8f0ff'
        {...props}
      />
    </InputGroup>
  );
};

export default SearchField;
