import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, AbsoluteCenter, Text, Button, VStack } from '@chakra-ui/react';

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <AbsoluteCenter >
        <VStack>
          <Text fontSize='3xl'>Coming Soon</Text>
          <Button colorScheme='purple' variant='link' onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
        </VStack>
      </AbsoluteCenter >
    </Container >
  );
};

export default ComingSoon;
