import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Card, CardHeader, CardBody, Text, SimpleGrid, VStack, StackDivider, Avatar, Spacer, AbsoluteCenter } from '@chakra-ui/react';

import { UserState } from '../types';

const Profile = () => {
  const user = useSelector((state: UserState) => state.user.value);

  return (
    <Box>
      <VStack spacing={10}>
        <Card width='100%'>
          <CardHeader>
            <Flex justify='space-between'>
              <Flex>
                <Text fontSize='2xl'>Basic info</Text>
              </Flex>
              <Flex alignItems='flex-end'>
                {/* <IconButton
                  variant='outline'
                  colorScheme='purple'
                  aria-label='Edit'
                  icon={<EditIcon />}
                /> */}
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={4}
              align='stretch'
            >
              <SimpleGrid columns={3}>
                <Flex><AbsoluteCenter axis='vertical'><Text fontSize='sm'>Profile picture</Text></AbsoluteCenter></Flex>
                <Flex><AbsoluteCenter axis='vertical'><Text fontSize='md'>You can't change the profile picture for this account</Text></AbsoluteCenter></Flex>
                <Flex>
                  <Spacer />
                  <Avatar
                    name={`${user?.firstName} ${user?.lastName}`}
                    width='50px'
                    height='50px'
                    bg='purple.300'
                  />
                </Flex>
              </SimpleGrid>
              <SimpleGrid columns={3}>
                <Text fontSize='sm'>First Name</Text>
                <Text fontSize='md'>{user.firstName}</Text>
              </SimpleGrid>
              <SimpleGrid columns={3}>
                <Text fontSize='sm'>Last Name</Text>
                <Text fontSize='md'>{user.lastName}</Text>
              </SimpleGrid>
            </VStack>
          </CardBody>
        </Card>
        <Card width='100%'>
          <CardHeader>
            <Flex justify='space-between'>
              <Flex>
                <Text fontSize='2xl'>Contact info</Text>
              </Flex>
              <Flex alignItems='flex-end'>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={4}
              align='stretch'
            >
              <SimpleGrid columns={3}>
                <Text fontSize='sm'>Phone</Text>
                <Text fontSize='md'>{user.phoneNumber}</Text>
              </SimpleGrid>
              <SimpleGrid columns={3}>
                <Text fontSize='sm'>Primary Email</Text>
                <Text fontSize='md'>{user.email}</Text>
              </SimpleGrid>
              <SimpleGrid columns={3}>
                <Text fontSize='sm'>Connected Emails</Text>
                <Text fontSize='md'>N/A</Text>
              </SimpleGrid>
            </VStack>
          </CardBody>
        </Card>
        <Card width='100%'>
          <CardHeader>
            <Flex justify='space-between'>
              <Flex>
                <Text fontSize='2xl'>Addresses</Text>
              </Flex>
              <Flex alignItems='flex-end'>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={4}
              align='stretch'
            >
              {user.addresses.map((address: any, index: number) => (
                <SimpleGrid columns={3} key={index}>
                  <Text fontSize='sm'>Address {index + 1}</Text>
                  <Text fontSize='md'>
                    {address.addressLines[0]} {address.addressLines[1]} {address.postcode} {address.country}
                  </Text>
                </SimpleGrid>
              ))}
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default Profile;
