import { useState } from 'react';
import useAxios from 'axios-hooks';
import {
  Select,
  useToast
} from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';

import { API_ENDPOINTS, dark, offWhite } from '../../../../utils';
import { TProvider } from '../types';

import '../providers.css';

type TProviderChoiceSelectProps = {
  provider: TProvider;
  transitionType: string;
  onChange: Function;
  size?: string;
};

function ProviderChoiceSelect (props: TProviderChoiceSelectProps) {
  const { provider, transitionType, onChange } = props;

  const toast = useToast();

  const [providerChoice, setProviderChoice] = useState(provider.providerChoice || 'NONE');

  // eslint-disable-next-line no-empty-pattern
  const [{}, updateChoice] = useAxios(API_ENDPOINTS.UPDATE_PROVIDER_CHOICE, { manual: true });

  const onUpdateChoice = async (choice: string) => {
    await updateChoice({
      data: {
        serviceAgreement: provider.agreementId ? provider.agreementId : null,
        potentialProviderSuggestion: !provider.agreementId ? provider.id : null,
        status: choice
      }
    });

    toast({
      title: `${provider.name} choice updated.`,
      status: 'success',
      isClosable: true,
      duration: 3000,
      position: 'top'
    });

    onChange(choice);
  };

  return (
    <Select
      icon={<MdArrowDropDown />}
      bg={offWhite}
      color={dark}
      width={{ base: '200px', md: '250px' }}
      h={{ sm: '40px', md: '60px' }}
      borderRadius={4}
      value={providerChoice}
      border="none"
      onChange={(e: any) => {
        setProviderChoice(e.target.value);
        onUpdateChoice(e.target.value);
      }}
    >
      {transitionType === 'none' ? <option value="ADDRESS_CHANGE">Update Address</option> : null}
      {transitionType === 'to' ? <option value="CONNECT">Open New Account</option> : <option value="DISCONNECT">Cancel Service</option>}
      <option value="NONE">Do nothing</option>
    </Select>
  );
}

export default ProviderChoiceSelect;
