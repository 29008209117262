import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';

import { dark } from '../../utils/themeSettings';
import screen1 from '../../assets/slider/screen1.png';
import screen2 from '../../assets/slider/screen2.png';
import screen3 from '../../assets/slider/screen3.png';

import './yoti.css';

function Yoti () {
  const buttonRef = useRef(null);
  const [, updateState] = useState<number>();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // @ts-ignore
    if (window.Yoti) {
      // @ts-ignore
      if (!window._yoti) {
        // @ts-ignore
        const _yoti = window.Yoti.Share.init({
          elements: [{
            clientSdkId: process.env.REACT_APP_YOTI_CLIENT_SDK_ID,
            scenarioId: process.env.REACT_APP_YOTI_CLIENT_SCENARIO_ID,
            // @ts-ignore
            domId: buttonRef.current.id,
            button: {
              label: 'Continue with your Digital ID',
              width: 'full'
            },
            skinId: 'yoti-with-post-office',
            shareComplete: {
              tokenHandler: (token: string, done: any) => {
                navigate('/yoti/welcome?token=' + token);
                // done();
              }
            }
          }]
        });

        // @ts-ignore
        window._yoti = _yoti;
      }

      return () => {
        // @ts-ignore
        if (window._yoti) {
          // @ts-ignore
          window._yoti.destroy();
          // @ts-ignore
          window._yoti = null;
        }
      };
    } else {
      updateState(Math.random());
    }
  });

  return (
    <Box>
      <Box
        px={{
          sm: '25px',
          md: '79px',
          lg: '10px',
          xl: '79px'
        }}
        justifyContent="center"
        alignItems="center"
        mt="97px"
      >
        <Heading
          fontSize="36px"
          color={dark}
          mb="10px"
          textAlign="center"
        >
          Let’s get you moved!
        </Heading>
        <Flex justifyContent="center">
          <Box my="60px" w="80%">
            <Carousel
              showStatus={false}
              autoPlay={true}
              showArrows={false}
            >
              <Box>
                <Image src={screen1} />
              </Box>
              <Box>
                <Image src={screen2} />
              </Box>
              <Box>
                <Image src={screen3} />
              </Box>
            </Carousel>
          </Box>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <div ref={buttonRef} id="button-ref"></div>
        </Flex>
      </Box>
    </Box>
  );
}

export default Yoti;
