import { Button } from '@chakra-ui/react';
import { purpleColor, purpleColorHover } from '../../utils/themeSettings';

function CustomButton (props: any) {
  return (
    <Button
      isLoading={props.isLoading}
      w={props.fullwidth ? '100%' : null}
      {...props}
      _hover={{ bg: props.variant ? 'none' : purpleColorHover }}
      color={props.variant ? purpleColor : 'white'}
      borderRadius={4}
      colorScheme="purple"
      disabled={props.disabled}
      variant={props.variant ? props.variant : 'solid'}
      // border={props.variant ? "1px" : "white"}
      px={props.px ? props.px : '30px'}
      py={props.py ? props.py : '30px'}
    >
      {props.children}
    </Button>
  );
}

export default CustomButton;
