import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image, Stack, Text, Flex, Spacer } from '@chakra-ui/react';
import Slide1 from './exp1.png';
import Slide2 from './exp2.png';
import Slide3 from './exp3.png';
import Slide4 from './exp4.png';
import Slide5 from './exp5.png';
import { setLocalData } from '../../../../utils';

type TExplainerModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Slides = [
  { image: Slide1, text: 'Go to Services to view and add all your bills and services' },
  { image: Slide2, text: 'Take action in any service request that requires action' },
  { image: Slide3, text: 'Update or cancel a service whenever' },
  { image: Slide4, text: 'Add more home addresses to manage (coming soon)' },
  { image: Slide5, text: 'Move to a new home and migrate all services' }
];

function ExplainerModal({ isOpen, onClose }: TExplainerModalProps) {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  setLocalData({
    hideExplainer: true
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={4}>
        <ModalHeader>How it works?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Carousel swipeable useKeyboardArrows selectedItem={currentSlide} onChange={setCurrentSlide}>
            {Slides.map((slide: any, index: number) => (
              <Stack key={index} mb={5}>
                <Image src={slide.image} />
                <Text>{slide.text}</Text>
              </Stack>
            ))}
          </Carousel>
        </ModalBody>
        <ModalFooter>
          <Flex grow={2}>
            <Button borderRadius={4} disabled={currentSlide === 0} onClick={() => {
              setCurrentSlide(currentSlide - 1);
            }}>Previous</Button>
            <Spacer />
            {currentSlide < 4 && <Button borderRadius={4} onClick={() => {
              setCurrentSlide(currentSlide + 1);
            }}>Next</Button>}
            {currentSlide === 4 && <Button borderRadius={4} onClick={onClose} colorScheme='red'>Close</Button>}
          </Flex >
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExplainerModal;
