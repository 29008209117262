import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState: { value: initialState },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
      return state;
    },
    setUserInfo: (state, action) => {
      state.value = action.payload;
      return state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { login } = userSlice.actions;
export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
