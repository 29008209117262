import React, { useState } from 'react';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import DashboardCard from '../../../components/ui/DashboardCard';
import { UserState } from '../types';
import { API_ENDPOINTS, getLocalData } from '../../../utils';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import ExplainerModal from './ExplainerModal';
import ServiceDiscovery from '../Services/ServiceDiscovery';

const LeftFrameDashboard = () => {
  const user = useSelector((state: UserState) => state.user.value);
  const navigate = useNavigate();

  const [confirmGoAgain, setConfirmGoAgain] = useState<boolean>(false);
  const [explainerModalOpen, setExplainerModalOpen] = useState<boolean>(!getLocalData().hideExplainer);

  const [{ loading }, startAgain] = useAxios(API_ENDPOINTS.START_AGAIN, {
    manual: true
  });

  async function initStartAgain () {
    await startAgain({ data: {} });

    setConfirmGoAgain(false);
    navigate('/onboarding/add-accounts');
  }

  return (
    <Stack
      px='4'
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', lg: '0.6fr 0.4fr' }}
        gap='3'
      >
        <DashboardCard
          cardType='blueCard'
          primaryText='Heyyy! Welcome to Monadd'
          secondaryText="it's time to stay on top of your services."
          btnText='Watch video'
          image='giveMeFive'
          imageProperties={{
            width: { base: '180px', md: '200px', lg: '240px' },
            bottom: '0px',
            right: '50px'
          }}
        />
        <DashboardCard
          cardType='yellowCard'
          primaryText='Import your bills from email'
          secondaryText="Discover the bills linked to your address."
          btnText='Connect Email'
          image='blackHand'
          imageProperties={{
            width: '140px',
            bottom: '0px',
            right: { base: '0px', lg: '5px' }
          }}
        />
      </Grid>
      <Text mt={8} fontSize='22px' fontWeight='700'>
        What's your plan {user?.firstName}?
      </Text>
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr' }}
        gap='3'
      >
        <DashboardCard
          cardType='greyCard'
          gridTemplateColumns={{ base: '80%', md: '75%', lg: '40%' }}
          primaryText='Manage my services'
          secondaryText="Add your first home address to manage all the bills and services linked to it."
          btnText='Create first space'
          image='personInPurple'
          action={() => navigate('/services')}
          imageProperties={{
            width: { base: '165px', lg: '200px' },
            bottom: { base: '10px', lg: '35px' },
            right: { base: '10px', lg: '35px' }
          }}
        />
        <DashboardCard
          cardType='greyCard'
          primaryText='Moving my home'
          secondaryText="Migrate your services from one place to another in a matter of clicks."
          btnText='Moving my home'
          helpText='Need help?'
          image='personInBlue'
          action={() => setConfirmGoAgain(true)}
          imageProperties={{
            width: { base: '140px', lg: '180px' },
            bottom: { base: '10px', lg: '35px' },
            right: { base: '10px', lg: '35px' }
          }}
        />
      </Grid>
      <Text mt={8} fontSize='22px' fontWeight='700'>
        Your services insights
      </Text>
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', lg: '0.6fr 0.4fr' }}
        gap='3'
      >
        <ServiceDiscovery onUpdate={() => {}} view='Dashboard' />

        <DashboardCard
          cardType='yellowCard'
          primaryText='Subscribe for ongoing bill care'
          secondaryText="Monitor changes to your services anytime, anywhere"
          btnText='Coming Soon'
          image='blackHand'
          disabled
          imageProperties={{
            width: '130px',
            bottom: '0px',
            right: { base: '0px', lg: '10px' }
          }}
        />
      </Grid>
      <ConfirmDialog
        isLoading={loading}
        showDialog={confirmGoAgain}
        heading="Start Again"
        msg="This will start onboarding again and cannot be undone, are you sure you want to continue?"
        onConfirm={initStartAgain}
        onCancel={() => setConfirmGoAgain(false)}
      />
      <ExplainerModal isOpen={explainerModalOpen} onClose={() => setExplainerModalOpen(false)} />
    </Stack>
  );
};

export default LeftFrameDashboard;
