import useAxios from 'axios-hooks';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  Button,
  useToast
} from '@chakra-ui/react';

import { dark, lightGray, purpleColor, purpleColorHover } from '../../../../utils/themeSettings';
import LoginWithGoogle from '../../../auth/LoginWithGoogle';
import googleLogo from '../../../../assets/icons/google-logo.svg';
import {
  API_ENDPOINTS
} from '../../../../utils';

import { TStepProps } from '../../types';
import Account from './Account';

import { GMAIL_JOURNEYS } from '../../../../utils/appCostants';
import { useEffect } from 'react';

function AdditionalAccounts (props: TStepProps) {
  const { me, onStepChange, step } = props;
  const toast = useToast();

  const [{ data, loading, error }, addAdditionalAccount] = useAxios(API_ENDPOINTS.ADD_EMAIL, { manual: true });

  useEffect(() => {
    if (error?.response?.status === 403) {
      toast({
        title: error?.response?.data?.detail,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    }
  }, [error, toast]);

  const onGoogleLoginSuccess = async (event: any) => {
    await addAdditionalAccount({
      data: {
        code: event.code
      }
    });

    onStepChange(data, step);
  };

  const handleSave = () => onStepChange(data);

  const { primaryEmail, secondaryEmails } = me;
  const journeyType = (
    GMAIL_JOURNEYS.includes(me.onboardingJourney) || me.onboardingJourney === null) ? 'GMAIL' : 'MANUAL';

  return (
    <Box textAlign="center" onClick={event => event.stopPropagation()}
      w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }}
    >
      <Heading fontSize={{ sm: '28px', md: '28px', lg: '36px' }} mt={{ sm: '24px', md: '28px', lg: 0 }} color={dark}>
        Discover services
      </Heading>
      <Text color={lightGray} px={{ base: '25px', md: '0', lg: '0' }} my="15px">
        We'll instantly find any extra services linked to them. Please make sure
        the emails correspond with your name, this makes the verification
        process easier.
      </Text>
      <Box px={{ base: '50px', md: '0', lg: '0' }}>
        <Divider />
      </Box>
      <Flex
        mt="24px"
        mb="50px"
        px={{ base: '35px', md: '0', lg: '0' }}
        borderRadius="16px"
        justifyContent="center"
      >
        <LoginWithGoogle
          gmailButtonText="Add another Google Account"
          onGoogleLoginSuccess={onGoogleLoginSuccess}
        />
      </Flex>
      {
        (secondaryEmails.length || journeyType === 'GMAIL') && (
          <Box px={{ base: '15px', md: '0', lg: '0' }}>
            <Divider />
            <Box my="24px">
              <Flex my="24px">
                <Image src={googleLogo} mr="15px" />
                <Text fontWeight={600}>Associated Gmails</Text>
              </Flex>
            </Box>
            <Flex my="24px" direction="column" alignItems="start">
              {
                journeyType === 'GMAIL' && primaryEmail && (
                  <>
                    <Text color={dark}>Primary</Text>
                    <Account id={primaryEmail?.id} displayPicture={primaryEmail?.displayPicture} email={primaryEmail?.email} gmailReadScope={primaryEmail?.gmailReadScope} gmailSendScope={primaryEmail?.gmailSendScope} />
                  </>
                )
              }
              {secondaryEmails.length > 0 ? (
                <Text color={dark}>Secondary</Text>
              ) : null}
              {
                secondaryEmails.map((email: any) => (
                  <Account key={email?.id} id={email?.id} displayPicture={email?.displayPicture} email={email?.email} gmailReadScope={email?.gmailReadScope} gmailSendScope={email?.gmailSendScope} />
                ))
              }
            </Flex>
          </Box>
        )
      }

      <Button
        my="30px"
        w={{ sm: '85%', md: '100%', lg: '100%' }}
        mt='40px'
        borderRadius={4}
        bg={purpleColor}
        _hover={{ bg: purpleColorHover }}
        color="white"
        px='30px'
        py='30px'
        isLoading={loading}
        isDisabled={loading}
        onClick={handleSave}
      >
        Skip
      </Button>
    </Box>
  );
}

export default AdditionalAccounts;
