import { useEffect } from 'react';
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Button,
  Image
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';

import MonaddSideBanner from '../../components/ui/MonaddSideBanner';
import checkIcon from '../../assets/icons/big-check.svg';

import {
  dark,
  purpleColor,
  purpleColorHover,
  API_ENDPOINTS,
  lightGray,
  setLocalData
} from '../../utils';
import MonaddLogoBlack from '../../assets/Monadd_logo_black.svg';

const EmailVerification = (props: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailCode = searchParams.get('code');

  const [{ data, loading, error }, verifyEmail] = useAxios(
    API_ENDPOINTS.VALIDATE_EMAIL,
    { manual: true }
  );

  useEffect(() => {
    if (emailCode) {
      searchParams.delete('code');
      verifyEmail({
        data: {
          userUuid: emailCode
        }
      });
    }
    if (data) {
      setLocalData({
        token: data.token.access,
        refreshToken: data.token.refresh
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailCode, data, error, loading]);

  return (
    <Flex
      gap={6}
      flexDirection={{
        sm: 'column-reverse',
        md: 'column-reverse',
        lg: 'row'
      }}
      mx={{
        sm: 0,
        md: 0,
        lg: '165px',
        xl: '165px'
      }}
      my={{
        sm: 0,
        md: 0,
        lg: '100px',
        xl: '100px'
      }}
    >
      <Box flex={1}>
        <MonaddSideBanner />
      </Box>
      <Box
        flex={1}
        px={{
          sm: '25px',
          md: '171px',
          lg: '10px',
          xl: '70px',
          '2xl': '79px'
        }}
        justifyContent="center"
        alignItems="center"
        mt={{
          sm: '54px',
          md: '54px',
          lg: '97px'
        }}
      >
        <Flex justifyContent="center" mb="36px">
          {
            <Image
              src={MonaddLogoBlack}
              display={{
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none'
              }}
            />
          }
        </Flex>
        <Heading
          fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
          fontWeight="700"
          color={dark}
          mb="16px"
          textAlign="center"
        >
          Email Verification
        </Heading>

        <Flex mb="25px">
          <Divider my="25px" />

          <Divider my="25px" />
        </Flex>
        {error ? (
          <Text
            color={lightGray}
            px="20px"
            my="15px"
            textAlign={'center'}
            fontSize="2xl"
          >
            Email verification failed. Please contact Monadd support
          </Text>
        ) : null}
        {data ? (
          <>
            <Text
              color={lightGray}
              px="20px"
              my="15px"
              textAlign={'center'}
              fontSize="2xl"
            >
              Your profile is verified successfully.
            </Text>
            <Flex justifyContent="center" m="38px">
              <Image src={checkIcon} />
            </Flex>
          </>
        ) : null}
        {data ? (
          <Button
            w="100%"
            mt="40px"
            borderRadius={4}
            bg={purpleColor}
            px="30px"
            py="30px"
            color="white"
            onClick={() => {
              navigate('/dashboard');
            }}
            isLoading={loading}
            isDisabled={loading}
            _hover={{
              background: purpleColorHover,
              color: 'white'
            }}
          >
            Continue to Dashboard
          </Button>
        ) : null}
      </Box>
    </Flex>
  );
};

export default EmailVerification;
