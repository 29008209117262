import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import {
  Box,
  Heading,
  Text,
  PinInputField,
  Button,
  PinInput
} from '@chakra-ui/react';

import { dark, lightGray, purpleColor, red } from '../../../../utils/themeSettings';
import { API_ENDPOINTS } from '../../../../utils';

type TVerifyCodeProps = {
  phoneNumber: string;
  onSend: Function;
  loading: boolean;
  phoneNumberError: string;
  onSuccess: Function;
};

function VerifyCode (props: TVerifyCodeProps) {
  const [OTP, setOTP] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');

  const [{ data, loading, error }, validatePhoneNumber] = useAxios(API_ENDPOINTS.VALIDATE_PHONE_NUMBER, { manual: true });

  useEffect(() => {
    if (!loading && error) {
      if (error?.response?.data && Array.isArray(error.response.data) && error.response.data.length) {
        setOtpError(error?.response?.data[0]);
        setOTP('');
      }
    }

    if (!loading && data) {
      const mobilePhoneVerificationDone = !data.onboardingStatus.missingFields.includes('phone_number');
      const emailVerificationDone = !data.onboardingStatus.missingFields.includes('email');

      props.onSuccess(mobilePhoneVerificationDone && emailVerificationDone ? 'ALL_SET' : (
        mobilePhoneVerificationDone && !emailVerificationDone ? 'EMAIL' : 'PHONE'
      ));
    }
  }, [data, error, loading, props]);

  const onValidate = (e: any, value?: string) => {
    if (e) e.preventDefault();

    const code = value || OTP;

    if (code.length === 6) validatePhoneNumber({ data: { code } });
  };

  return (
    <>
      <Box textAlign="center">
        <Heading fontSize="26px" color={dark}>
          Authentication Code
        </Heading>
        <Text color={lightGray} px="15px" my="15px">
          Let's make sure it's really you. We've just sent you a verification code by text to <Text as="span">{props.phoneNumber}</Text>.
        </Text>

        <form onSubmit={onValidate}>
          <Box mt="44px" mb="20px">
            <PinInput otp autoFocus size='lg' value={OTP} isInvalid={!!otpError} onChange={(e: any) => { setOTP(e); }} onComplete={(value: string) => onValidate(null, value)}>

              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />
              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />
              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />
              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />
              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />
              <PinInputField borderRadius={4} w="70px" h="80px" mx="4px" fontSize="36px" fontWeight={600} color={dark} />

            </PinInput>
          </Box>

          <Text color={lightGray} px="15px" mt="24px">
            Didn't recieve a code? {''} <Button color={purpleColor} variant='link' onClick={(e: any) => {
            e.preventDefault();
            props.onSend(props.phoneNumber);
          }} isLoading={props.loading} isDisabled={props.loading || loading}>Try again</Button>.
          </Text>
          {!!props.phoneNumberError && (<Text color={red} textAlign="center" pt="3px">{props.phoneNumberError}</Text>)}
          <Button
            my="24px"
            w='100%'
            mt='40px'
            borderRadius={4}
            colorScheme="purple"
            px='30px'
            py='30px'
            isDisabled={props.loading || loading}
            isLoading={props.loading || loading}
            type="submit"
          >
            Verify
          </Button>
        </form>
      </Box>
    </>
  );
};

export default VerifyCode;
