import { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Box,
  Button,
  useToast
} from '@chakra-ui/react';

import TextField from '../../../../components/ui/TextField';

import { TAddress } from './Address';
import { purpleColor, purpleColorHover, iso2ToCountryName } from '../../../../utils';

type TAddressModelProps = {
  showModal: boolean;
  onConfirm: Function;
  onCloseModal: Function
  heading: string;
  addressForm: TAddress;
}

function AddressModal (props: TAddressModelProps) {
  const { showModal, onConfirm, heading, addressForm, onCloseModal } = props;

  const { onClose } = useDisclosure();
  const toast = useToast();
  const [address, setAddress] = useState<string>(addressForm.address);
  const [postcode, setPostcode] = useState<string>(addressForm.postcode);

  const confirmAddress = () => {
    if (!address || address.length <= 3) {
      toast({
        title: 'Valid address is required.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
      return;
    }

    if (!postcode || postcode.length <= 3) {
      toast({
        title: 'Valid postcode is required.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
      return;
    }

    onConfirm(address, postcode);
  };

  return (
    <>
      <Modal isOpen={showModal} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={4}>
          <ModalHeader>{heading}</ModalHeader>
          <ModalCloseButton onClick={() => onCloseModal()} />
          <ModalBody>
            <Box my="24px">
              <TextField
                isInvalid={false}
                errormessage="Address is required"
                placeholder="Your address"
                borderRadius={4}
                label="Search your address"
                value={address}
                onChange={(e: any) => {
                  setAddress(e.target.value);
                }}
              />
            </Box>

            <Box my="24px">
              <TextField
                readOnly={!!addressForm.postcode}
                placeholder="Your Postcode / Zipcode"
                borderRadius={4}
                label="Postcode / Zipcode"
                name="postcode"
                onChange={(e: any) => {
                  setPostcode(e.target.value);
                }}
                value={postcode}
              />
            </Box>
            <Box my="24px">
              <TextField
                placeholder="Your Town name"
                borderRadius={4}
                readOnly
                label="Town"
                name="town"
                value={addressForm.town}
              />
            </Box>
            <Box my="24px">
              <TextField
                readOnly
                borderRadius={4}
                placeholder="Your Country name"
                label="Country"
                name="country"
                value={iso2ToCountryName(addressForm.country)}
              />
            </Box>
            <Button
              my="24px"
              w='100%'
              mt='40px'
              borderRadius={4}
              bg={purpleColor}
              _hover={{ bg: purpleColorHover }}
              color="white"
              px='30px'
              py='30px'
              onClick={() => confirmAddress()}
            >
              Confirm
            </Button>
          </ModalBody>

          {/*  <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddressModal;
