import {
  Flex, Image, IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/navbar-logo.svg';
import { HiOutlineUser } from 'react-icons/hi';
import {
  clearLocalStorage
} from '../../utils';
import {
  MoonIcon
} from '@chakra-ui/icons';

function Navbar () {
  const navigate = useNavigate();

  const sessionLogout = () => {
    clearLocalStorage();
    navigate('/');
  };

  return (
    <>
      <Flex px="40px" py="26px" justifyContent="space-between">
        <Image cursor="pointer" src={logo} onClick={() => navigate('/')} />
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<Icon as={HiOutlineUser} />}
            variant='outline'
            />
          <MenuList>
            <MenuItem icon={<MoonIcon />} onClick={sessionLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

export default Navbar;
