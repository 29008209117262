import { useNavigate } from 'react-router-dom';
import {
  Heading,
  Text,
  Flex,
  Image,
  Button,
  Box
} from '@chakra-ui/react';

import checkIcon from '../../../../assets/icons/big-check.svg';
import { dark, lightGray } from '../../../../utils/themeSettings';

function AllSet () {
  const navigate = useNavigate();

  return (
    <>
      <Heading fontSize="26px" color={dark}>
        All Set
      </Heading>
      <Text color={lightGray} px="20px" my="15px">
        Your profile is verified.
      </Text>
      <Flex justifyContent="center" m="38px">
        <Image src={checkIcon} />
      </Flex>
      <Box mt="44px" mb="20px">
        <Button
          my="24px"
          w='100%'
          mt='40px'
          borderRadius={4}
          colorScheme="purple"
          px='30px'
          py='30px'
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Go to Dashboard
        </Button>
      </Box>
    </>
  );
};

export default AllSet;
