import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAxios from 'axios-hooks';
import { Box, Drawer, DrawerContent, useDisclosure, useToast } from '@chakra-ui/react';

import { setUserInfo } from '../../store/user';
import { API_ENDPOINTS, getLocalData } from '../../utils';

import Main from './Main';
import { Navbar, MobileNav } from './NavBar';
import AddingPermission from './AddingPermission';

import './dashboard.css';

type TDashboardProps = {
  page?: string;
};

export default function Dashboard (props: TDashboardProps) {
  const { page } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchParams] = useSearchParams();

  // These params are required for callback from gmail permissions popup
  const gmailPermissionCode = searchParams.get('code');
  const gmailPermissionScope = searchParams.get('scope');

  const [permissionDialogOpen, setPermissionDialogOpen] = useState(
    !!(gmailPermissionCode && gmailPermissionScope)
  );

  const [{ data, loading }, reLoadMe] = useAxios(API_ENDPOINTS.ME);

  const onPermissionsDone = (status: boolean) => {
    setPermissionDialogOpen(false);

    if (status) {
      toast({
        title: 'Send email permissions added to profile.',
        status: 'success',
        isClosable: true,
        duration: 1500
      });
    } else {
      toast({
        title: 'Send email permission not granted.',
        status: 'error',
        isClosable: true,
        duration: 1500
      });
    }
  };

  const checkUserState = () => {
    if (
      getLocalData().token &&
      getLocalData().refreshToken &&
      data?.onboardingStatus?.isOnboarded
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (data && !loading) {
      if (data?.onboardingStatus && !data.onboardingStatus?.isOnboarded) {
        navigate('/onboarding/name' + document.location.search);
      }

      if (data?.primaryEmail?.gmailSendScope) {
        setPermissionDialogOpen(false);
      } else {
        setPermissionDialogOpen(
          !!gmailPermissionCode && !!gmailPermissionScope
        );
      }

      dispatch(setUserInfo(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <Box minH='100vh' bg='#FFFFF'>
      {checkUserState() && (
        <>
          <Box
            pos='sticky'
            top='0'
            zIndex='20'
          >
            <Box
              display={{ base: 'none', lg: 'block' }}
            >
              <Navbar onClose={() => onClose} />
            </Box>
            <Drawer
              autoFocus={false}
              isOpen={isOpen}
              placement='left'
              onClose={onClose}
              returnFocusOnClose={false}
              onOverlayClick={onClose}
            >
              <DrawerContent>
                <Navbar onClose={onClose} />
              </DrawerContent>
            </Drawer>
              <MobileNav onOpen={onOpen} />{' '}
          </Box>
          <Box ml={{ base: 0, md: 0, lg: 60 }} overflowX='hidden'>
            {' '}
            <Main reloadMe={reLoadMe} page={page} />
          </Box>
          {permissionDialogOpen ? <AddingPermission
            open={permissionDialogOpen}
            onDone={onPermissionsDone}
          /> : null}
        </>
      )}
    </Box>
  );
}
