import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Spinner
} from '@chakra-ui/react';

import { dark } from '../../utils/themeSettings';
import { API_ENDPOINTS, setLocalData, getLocalData } from '../../utils';

type TAddingPermissionProps = {
  onDone: (status: boolean) => void;
  open: boolean;
};

function AddingPermission ({ open, onDone }: TAddingPermissionProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [reLoggedIn, setReLoggedIn] = useState(null);

  // These params are required for callback from gmail permissions popup
  const gmailPermissionCode = searchParams.get('code');
  const gmailPermissionScope = searchParams.get('scope');
  const gmailPermissionError = searchParams.get('error');

  const [{ data: reLoginData, loading: logingIn, error: reLoginError }, reLogin] = useAxios(API_ENDPOINTS.SOCIAL_LOGIN, { manual: true, useCache: false });
  const [{ data, loading }, addGmailScope] = useAxios(API_ENDPOINTS.INCREMENTAL_GMAIL_SCOPE, { manual: true, useCache: false });

  function profile () {
    if (gmailPermissionScope && gmailPermissionCode && gmailPermissionScope.includes('https://www.googleapis.com/auth/gmail.send')) {
      reLogin({
        data: {
          code: gmailPermissionCode,
          provider: 'google-oauth2',
          redirect_uri: process.env.REACT_APP_REDIRECT_DASHBOARD_URL
        }
      });
    } else if (gmailPermissionError && gmailPermissionError === 'access_denied') {
      navigate('/services');
      onDone(false);
    }
  }

  useEffect(() => {
    if (!reLoggedIn && reLoginData && !logingIn) {
      const session = getLocalData();

      setLocalData({ ...session, token: reLoginData.token, refreshToken: reLoginData.refresh });

      setReLoggedIn(reLoginData);
    }

    if (reLoggedIn && reLoginData && !logingIn) addGmailScope();

    if (data && !loading) {
      navigate('/services');
      onDone(true);
    }

    if (gmailPermissionCode && gmailPermissionScope && !reLoginData && !reLoggedIn && !reLoginError) profile();

    // if there is an error in relogin, then credentials were already validated
    if (gmailPermissionCode && gmailPermissionScope && reLoginError) addGmailScope();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, reLoginData, logingIn, reLoggedIn, reLoginError]);

  return (
    <Modal size='md' isOpen={open} onClose={() => { }} motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={4}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Box textAlign="center">
            <Heading fontSize="26px" color={dark}>
              Verifying send email permissions.
            </Heading>
            <Flex justifyContent="center" m="38px" >
              { (loading || logingIn) && <Spinner size='xl' color='#805AD5' width='150px' height='150px' emptyColor='gray.200' thickness='20px' speed='0.8s' /> }
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddingPermission;
