/* eslint-disable react/prop-types */
import AddressSearch from 'react-loqate';
import TextField from './TextField';
import 'react-loqate/dist/react-loqate.cjs.development.css';

const SearchAddress = (props: any) => {
  return <AddressSearch
    locale="en_GB"
    // @ts-ignore
    apiKey={process.env.REACT_APP_LOQATE_API_KEY}
    className="loqate"
    classes={{
      list: 'loqate-list',
      listItem: 'loqate-list-item'
    }}
    countries={[props.selectedCountry]}
    components={{
      Input: props => (
        <TextField
        // @ts-ignore
            isInvalid={props?.addressError}
            errormessage='Address is required'
            placeholder='Type your Address'
            autoComplete='none'
            id='addressField'
            name='address'
            borderRadius={4}
            // @ts-ignore
            {...props}
        />
      )
    }}
    onSelect={props.onAddressSelect}
    debounce={100}
    />;
};

export default SearchAddress;
