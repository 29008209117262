import {
  Divider
} from '@chakra-ui/react';
import '../providers.css';

import NonTransitionRow from './NonTransitionRow';
import TransitionRow from './TransitionRow';

type TProviderRowProps = {
  providers: any;
  onChange: Function;
  last?: boolean;
  noBadge?: boolean;
  onCorrespondence: (data: any) => void;
};

function ProviderRow (props: TProviderRowProps) {
  const { providers, onChange, last, noBadge, onCorrespondence } = props;

  return <>
    {
      providers.to ? <TransitionRow toProvider={providers.to} fromProvider={providers.from} onChange={onChange} onCorrespondence={onCorrespondence} /> : <NonTransitionRow provider={Array.isArray(providers) ? providers[0] : providers} onChange={onChange} noBadge={noBadge} onCorrespondence={onCorrespondence} />
    }
    { !last && <Divider /> }
  </>;
}

export default ProviderRow;
