import countryData from 'country-data';
import { TLocalStorageData } from '../types';

const regionNames = new Intl.DisplayNames(
  ['en'], { type: 'region' }
);

export const validateEmail = (email: string) => {
  const regex = /[a-z0-9]+@[a-z]+.[a-z]{2,3}/;
  return regex.test(email);
};

export function setLocalData (data: Partial<TLocalStorageData>) {
  if (data.token) localStorage.setItem('token', data.token);
  if (data.refreshToken) localStorage.setItem('refresh', data.refreshToken);
  if (data.yoti) localStorage.setItem('yoti', JSON.stringify(data.yoti));
  if (data.aid) localStorage.setItem('aid', data.aid);
  if (data.referrer) localStorage.setItem('referrer', data.referrer);
  if (data.email) localStorage.setItem('userEmail', data.email);
  if (data.confirmAllConsent) localStorage.setItem('confirmAllConsent', 'true');
  if (data.hideExplainer) localStorage.setItem('hideExplainer', 'true');
  if (data.metaData) localStorage.setItem('metaData', JSON.stringify(data.metaData));
  if (data.additionalPermissionsGranted) localStorage.setItem('additionalPermissionsGranted', 'true');
  if (data.correspondenceCallBackData) localStorage.setItem('correspondenceCallBackData', JSON.stringify(data.correspondenceCallBackData));
  if (data.messagesRead) localStorage.setItem('messagesRead', JSON.stringify(data.messagesRead));
}

export function getLocalData (): Partial<TLocalStorageData> {
  return {
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refresh'),
    yoti: localStorage.getItem('yoti') ? JSON.parse(localStorage.getItem('yoti') as string) : null,
    aid: localStorage.getItem('aid'),
    referrer: localStorage.getItem('referrer'),
    email: localStorage.getItem('userEmail'),
    confirmAllConsent: !!localStorage.getItem('confirmAllConsent'),
    hideExplainer: !!localStorage.getItem('hideExplainer'),
    additionalPermissionsGranted: !!localStorage.getItem('additionalPermissionsGranted'),
    metaData: localStorage.getItem('metaData') ? JSON.parse(localStorage.getItem('metaData') || '') : null,
    correspondenceCallBackData: localStorage.getItem('correspondenceCallBackData') ? JSON.parse(localStorage.getItem('correspondenceCallBackData') || '') : undefined,
    messagesRead: localStorage.getItem('messagesRead') ? JSON.parse(localStorage.getItem('messagesRead') || '') : undefined
  };
}

export const removeStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem('yoti');
  localStorage.removeItem('aid');
  localStorage.removeItem('referrer');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('confirmAllConsent');
  localStorage.removeItem('hideExplainer');
  localStorage.removeItem('metaData');
  localStorage.removeItem('additionalPermissionsGranted');
  localStorage.removeItem('correspondenceCallBackData');
  localStorage.removeItem('messagesRead');
};

export function splitString (str: string) {
  // spliting the string into array
  if (str === ' ' || str === null || str === undefined) {
    return {
      firstStr: '',
      restStr: ''
    };
  }
  let arr = str.split(' ');

  const result = arr[0] + ' ' + (arr[1] || '');

  arr = arr.splice(2, arr.length);

  return {
    firstStr: result || '',
    restStr: arr.join(' ')
  };
}

export function iso2Currency (code: string): string {
  const country = countryData.countries[code];

  return ['GBP', 'EUR', 'USD'].includes(country.currencies[0]) ? country.currencies[0] : 'USD';
};

export const COUNTRY_OPTIONS = countryData.countries.all.reduce((all: any[], country: any) => {
  if (country.status !== 'deleted') {
    const callingCode = country.countryCallingCodes.length ? country.countryCallingCodes[0] : null;

    all.push({
      ...country,
      label: `${country.emoji} ${country.name}`,
      label2: `${country.emoji} ${country.alpha2}`,
      value: country.alpha2,
      callingCode
    });
  }

  return all;
}, []);

export function iso2ToCountryName (code: string): string | undefined {
  return regionNames.of(code);
}

export function iso2CountryCallingCode (code: any) {
  if (code === '') return null;

  const country = countryData.countries[code];

  if (!country) return null;

  return country.countryCallingCodes.length ? country.countryCallingCodes[0] : null;
}

export function iso2CountryFlag (code: any) {
  if (code === '') return null;

  const country = countryData.countries[code];

  if (!country) return null;

  return country.emoji;
}

export function debug (msg: string): void {
  if (process.env.REACT_APP_ENV !== 'production') console.log(msg);
}

export function printError (msg: string, err?: any): void {
  if (process.env.REACT_APP_ENV !== 'production') console.error(msg, err);
}
