import { useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Text
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { Select, ChakraStylesConfig } from 'chakra-react-select';

import { dark, gray, darkGray, iso2ToCountryName, COUNTRY_OPTIONS } from '../../../../utils';
import TextField from '../../../../components/ui/TextField';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import SearchAddress from '../../../../components/ui/SearchAddress';

import AddressModel from './AddressModal';

import './countrySelect.css';

export type TAddress = {
  address: string;
  town: string;
  postcode: string;
  country: string;
  moveDate: string | null;
};

type TAddressProps = {
  onChange: Function;
  isMovingOut: boolean;
  startDateFrom?: string | null;
  values: TAddress | null;
  manageMode?: boolean;
};

const sixMonthOldDate = (): string => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();
  let month: number;
  const day: number = currentDate.getDate();

  if (currentDate.getMonth() > 6) {
    month = currentDate.getMonth() - 6;
  } else {
    year = year - 1;
    month = 12;
    month = month - (6 - currentDate.getMonth());
  }

  return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

const selectedCountryOption = (code: string) => {
  for (let index = 0; index < COUNTRY_OPTIONS.length; index++) {
    const option = COUNTRY_OPTIONS[index];

    if (option.value === code) {
      return option;
    }
  }

  return null;
};

function Address (props: TAddressProps) {
  const { onChange, isMovingOut, startDateFrom, values, manageMode } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(!!values);
  const [addressForm, setAddressForm] = useState<TAddress>(values || {
    address: '',
    moveDate: null,
    town: '',
    postcode: '',
    country: ''
  });

  const chakraStyles: ChakraStylesConfig = {
    control: (provided, state) => ({
      ...provided,
      h: '60px',
      borderRadius: '4px',
      borderColor: '#e2e8f0',
      textAlign: 'left'
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      border: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: dark,
      bg: 'white',
      border: 'none'
    })
  };

  const getAddressLineFromLoqate = (address: any) => {
    let line = address.Line1;

    if (address.Line2) line += `, ${address.Line2}`;
    if (address.Line3) line += `, ${address.Line3}`;
    if (address.Line4) line += `, ${address.Line4}`;
    if (address.Line5) line += `, ${address.Line5}`;

    return line;
  };

  const onAddressSelect = (results: any) => {
    const address = getAddressLineFromLoqate(results);

    setAddressForm({
      ...addressForm,
      address,
      town: results.City || results.AdminAreaName || results.ProvinceName,
      postcode: results.PostalCode,
      country: results.CountryIso2
    });

    setShowModal(true);
  };

  const onConfirm = (address: string, postcode: string) => {
    const addressData = {
      ...addressForm,
      address,
      postcode
    };

    setAddressForm(addressData);
    setShowModal(false);
    setIsConfirmed(true);
    onChange(addressData);
  };

  const onMoveDateChange = (e: any) => {
    const addressData = {
      ...addressForm,
      moveDate: e.target.value
    };

    setAddressForm(addressData);
    onChange(addressData);
  };

  return (
    <Box textAlign="center" px={{ base: '15px', md: '15px', lg: '0' }} w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }}>

      {showModal && (
        <AddressModel showModal={showModal} addressForm={addressForm} heading='Confirm Address' onConfirm={onConfirm} onCloseModal={() => setShowModal(false)} />
      )}

      <Heading fontSize={{ sm: '28px', md: '28px', lg: '36px' }} mt={{ sm: '24px', md: '28px', lg: 0 }} color={dark}>
        { manageMode ? 'Address to Manage' : `Moving ${isMovingOut ? 'from' : 'to'}?` }
      </Heading>
      <Text color={darkGray} px="20px" my="15px">
      Add your full address, including apartment number, to ensure that it matches the information in your bills and transactions. Address details are required to proceed.
      </Text>
      <Box px="25px">
        <Divider />
      </Box>
      <Box className='iAmTheBox' my="24px" px={{ sm: '20px', md: 0 }}>
        <Select
          isSearchable
          value={selectedCountryOption(addressForm.country)}
          options={COUNTRY_OPTIONS}
          onChange={(value: any) => {
            if (value.value !== addressForm.country) {
              setAddressForm({ ...addressForm, country: value.value as string, address: '', town: '', postcode: '' });
              setIsConfirmed(false);
            }
          }}
          placeholder='Select your Country'
          chakraStyles={chakraStyles}
        />
      </Box>
      <Box my="24px" px={{ sm: '20px', md: 0 }}>
        <SearchAddress
          autoComplete={false}
          selectedCountry={addressForm.country}
          addressError={''}
          selectedAddress={addressForm.address}
          onAddressSelect={onAddressSelect}
        />
      </Box>
      {isConfirmed ? (
        <Box my="24px" px={{ sm: '20px', md: 0 }}>
          <Box
            border="1px"
            style={{ borderColor: '#E2E8F0' }}
            textAlign="left"
            borderRadius="4px"
            px="30px"
            py="10px"
            minHeight="140px"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              style={{
                position: 'relative'
              }}
            >
              <Box w="80%">
                <Text>{addressForm.address}</Text>
                <Text>{addressForm.postcode}</Text>
                <Text>{addressForm.town}</Text>
                <Text>{iso2ToCountryName(addressForm.country)}</Text>
              </Box>
              <MdEdit
                cursor="pointer"
                onClick={() => setShowModal(true)}
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '40px'
                }}
              />
            </Flex>
          </Box>
        </Box>
      ) : null}

      {manageMode !== true && (
        <Box my="24px" px={{ sm: '20px', md: 0 }}>
          <FormLabel mb="14px" color={darkGray}>
            Move {isMovingOut ? 'out' : 'in'} date
          </FormLabel>
          <TextField
            color={gray}
            type="date"
            borderRadius={4}
            min={isMovingOut ? sixMonthOldDate() : startDateFrom}
            errormessage={`Move ${isMovingOut ? 'out' : 'in'} date is required`}
            id="moveDate"
            name="moveDate"
            value={addressForm.moveDate}
            onChange={onMoveDateChange}
          />
        </Box>
      )}
    </Box>
  );
}

export default Address;
