import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { configure } from 'axios-hooks';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { client as axios } from './utils';
import { Auth, ForgotPassword, EmailVerification } from './screens/auth';
import Onboarding from './screens/onboarding';
import Dashboard from './screens/dashboard';
import MoveInformation from './components/pages/';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Checkout from './screens/customCheckout';

import './index.css';
import './rsms-me-inter.css';

configure({ axios, cache: false });

export function App () {
  const breakpoints = {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px'
  };
  const theme = extendTheme({
    components: {
      Steps
    },
    breakpoints,
    colors: {
      main: '#3377FF'
    }
  });

  return (
    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path='/login' element={<Auth viewType='LOGIN' />} />
            <Route path='/join' element={<Auth viewType='AFFILIATE' />} />
            <Route path='/:referralType/:userType/:username' element={<Auth viewType='REFERRAL' />} />
            <Route path='/reset-password' element={<ForgotPassword viewType='FORGOT' />} />
            <Route path='/password-reset/:uuid' element={<ForgotPassword viewType='RESET' />} />
            <Route path='/register' element={<Auth viewType='REGISTER' />} />
            <Route path='/yoti' element={<Auth viewType='YOTI' />} />
            <Route path='/yoti/welcome' element={<Auth viewType='YOTI_CALLBACK' />} />
            <Route path='/onboarding/email-verification' element={<EmailVerification />} />
            <Route path='/onboarding/:step' element={<Onboarding />} />
            <Route path='/onboarding' element={<Onboarding />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/profile' element={<Dashboard page='profile' />} />
            <Route path='/services' element={<Dashboard page='services' />} />
            <Route path='/spaces' element={<Dashboard page='comingsoon' />} />
            <Route path='/activity' element={<Dashboard page='comingsoon' />} />
            <Route path='/confirm-address-change/*' element={<MoveInformation />} />
            <Route path='/confirm-connect/*' element={<MoveInformation />} />
            <Route path='/confirm-disconnect/*' element={<MoveInformation />} />
            <Route path='/move-information/*' element={<MoveInformation />} />
            <Route path='/custom/checkout' element={<Checkout />} />
            <Route path='/' element={<Dashboard />} />
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};
