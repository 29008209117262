import { useState } from 'react';
import useAxios from 'axios-hooks';
import { BiTrash } from 'react-icons/bi';
import {
  Spinner,
  Box
} from '@chakra-ui/react';

import ConfirmDialog from '../../../../components/ui/ConfirmDialog';
import { API_ENDPOINTS } from '../../../../utils';
import { TEndPoint } from '../../../../types';
import { TProvider } from '../types';

import '../providers.css';

type TDeleteProviderButtonProps = {
  provider: TProvider;
  onDone: Function;
};

function DeleteProviderButton (props: TDeleteProviderButtonProps) {
  const { provider, onDone } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const deleteEndpoint: TEndPoint = provider.type === 'POTENTIAL' ? API_ENDPOINTS.DELETE_POTENTIAL_PROVIDER : API_ENDPOINTS.DELETE_AGREEMENT;
  const [{ loading }, deleteProvider] = useAxios({
    url: deleteEndpoint.url.replace(':id', `${provider.type === 'POTENTIAL' ? provider.id : provider.agreementId}`),
    method: deleteEndpoint.method
  }, { manual: true });

  const onDelete = async () => {
    await deleteProvider();
    setOpenDialog(false);
    onDone();
  };

  return loading ? <Spinner /> : (
    <Box
      borderRadius='100%'
      shadow='lg'
      p={{ base: '10px', lg: '12px' }}
      ml={{ base: '5px', lg: '15px' }}
      cursor='pointer'
      onClick={() => setOpenDialog(true)}
    >
      <BiTrash fontSize={22} />

      {openDialog && <ConfirmDialog showDialog={openDialog} onCancel={() => setOpenDialog(false)} onConfirm={onDelete} heading='Confirm?' msg='Are you sure you want to remove this?' />}
    </Box>
  );
}

export default DeleteProviderButton;
