import { FormLabel, Select } from '@chakra-ui/react';
import { gray } from '../../utils/themeSettings';
import { MdArrowDropDown } from 'react-icons/md';

function CustomSelect (props: any) {
  return (
        <>
            {props?.label && (
                <FormLabel mb="14px" color={gray}>
                    {props.label}
                </FormLabel>
            )}
            <Select
                icon={props.hideIcon ? '' : <MdArrowDropDown /> }
                h={props.h || props.height ? props.h || props.height : '62px'}
                {...props}
                color={props.color ? props.color : gray}
                // colorScheme="purple"
                borderRadius={props.borderRadius ? props.borderRadius : '4px'}
                // bg={props.isDisabled ? "#F7F7F7" : ""}
                placeholder={props.isDisabled ? '' : props.placeholder}
            >
                {props.children}
            </Select>
        </>
  );
}

export default CustomSelect;
