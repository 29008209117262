import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  Grid,
  Badge,
  Stack,
  Button
} from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';

// import debouce from 'lodash.debounce';
import DefaultLogo from '../../../../assets/providers-logos/default-logo.svg';
import Card from '../../../../components/ui/Card';
import DeleteProviderButton from './DeleteProviderButton';
import ConfirmProviderButton from './ConfirmProviderButton';
import ProviderChoiceSelect from './ProviderChoiceSelect';
import { UserState } from '../../types';
import { CLEARBIT, dark, purpleColor, gray, purpleColorHover, COMPLETED_PROVIDER_REQUEST_STATUS } from '../../../../utils';

import '../providers.css';

type TNonTransitionRowProps = {
  provider: any;
  onChange: Function;
  noBadge?: boolean;
  onCorrespondence: (data: any) => void;
};

function NonTransitionRow (props: TNonTransitionRowProps) {
  const { provider, onChange, noBadge, onCorrespondence } = props;

  const user = useSelector((state: UserState) => state.user.value);

  const [searchParams] = useSearchParams();

  const confirmationRequired = provider.action === 'Confirmation Required';

  const [providerChoice, setProviderChoice] = useState(provider.providerChoice || 'NONE');
  const [providerData] = useState<any>(provider);

  const startCorrespondence = (id: number) => {
    onCorrespondence({
      ...provider
    });
  };

  if (searchParams.get('state')) {
    // @ts-ignore
    const id = parseInt(searchParams.get('state'));
    searchParams.delete('state');
    onCorrespondence({ id });
  }

  const onConfirmed = (confirmedProvider: any) => {
    if (confirmedProvider) {
      const latestProviderData = provider || providerData;
      if (!provider.isPaid) {
        onCorrespondence({
          ...latestProviderData,
          providerName: providerData.providerName || confirmedProvider.provider.name,
          sendEmailType: providerData.sendEmailType || confirmedProvider.sendRequestType,
          sendEmailPermission: providerData.sendEmailPermission || confirmedProvider.hasSendScope,
          associatedEmail: providerData.associatedEmail || confirmedProvider.associatedEmail,
          id: confirmedProvider.id
        });
      }
      onChange('all');
    } else {
      onChange('none');
    }
  };

  const paymentRequired = provider.isPaid;
  const onHold = user.paymentRequired && !user.paymentCompleted && paymentRequired;
  const status = onHold ? 'On Hold' : COMPLETED_PROVIDER_REQUEST_STATUS.includes(provider.emailStatus) ? 'Completed' : 'In Progress';
  const statusColour = onHold ? 'red' : status === 'Completed' ? 'green' : 'purple';

  return (
    <>
      <Grid gridTemplateColumns={{ base: '1fr', md: '1.5fr 1fr' }} gap={2}>
        <Flex alignItems='center'>
          <Card
            src={provider.logo || (CLEARBIT + provider.name)}
            fallbackSrc={DefaultLogo}
            w='100px'
            py='0px'
            px='10px'
            pl='0px'
          />
          <Box ml={{ sm: 3, md: 5 }}>
            { noBadge !== true && <Badge variant='outline' colorScheme={paymentRequired ? 'red' : 'purple'}>{paymentRequired ? 'REQUIRES PAYMENT' : 'FREE REQUEST'}</Badge> }
            <Text display={{
              sm: 'block',
              md: 'block'
            }} fontWeight={600} color={dark}>
              {provider.providerName || provider.name}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent='space-between' alignItems='center' minWidth='max-content' gap={2}>
          {
            confirmationRequired ? <ProviderChoiceSelect transitionType='none' provider={provider} onChange={setProviderChoice} /> : (
              <Stack direction='column'>
                <Text fontWeight={600}
                  fontSize={{ sm: '12px', md: '13px' }}
                  color={gray}
                  mb='2px'>{provider.action}
                </Text>
                <Flex mt='-1'>
                  <Badge variant='outline' fontSize='2xs' colorScheme={statusColour}>{status}</Badge>
                </Flex>
              </Stack>
            )
          }
          <Flex>
            {(provider.showSendEmailButton === true && !confirmationRequired) || provider.reason ? (
              <Button
                leftIcon={<ChatIcon className={provider.reason ? 'blink' : ''} />}
                bg={purpleColor}
                size={{ base: 'xs', md: 'md' }}
                color='white'
                _hover={{
                  bg: purpleColorHover
                }}
                onClick={() => {
                  startCorrespondence(provider.id);
                }}
              >
                {['MAILTO_TRIGGERED', 'USER_GMAIL_SENT', 'SENT'].includes(provider.emailStatus) || !provider.showSendEmailButton ? 'Messages' : 'Send Request'}
              </Button>
            ) : null}
            {confirmationRequired && (
              <>
                { (providerChoice !== 'NONE') && <ConfirmProviderButton provider={provider} onDone={onConfirmed} action={providerChoice} /> }
                <DeleteProviderButton provider={provider} onDone={() => onChange('service-agreement')} />
              </>
            )}
          </Flex>
        </Flex>
      </Grid>
    </>
  );
}

export default NonTransitionRow;
