import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Center,
  useToast,
  Image,
  Spinner
} from '@chakra-ui/react';

import MonaddLogoBlack from '../../assets/Monadd_logo_black.svg';

import {
  API_ENDPOINTS,
  setLocalData,
  dark,
  lightGray
} from '../../utils';

const YotiCallback = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const [{ data, loading, error }, authenticateYoti] = useAxios(API_ENDPOINTS.YOTI_SIGNUP, { manual: true });

  useEffect(() => {
    const setYoti = (payload: any) => {
      setLocalData({
        yoti: {
          ...payload,
          phoneNumber: payload.phone,
          firstName: `${payload.fullName.split(' ').shift()}`,
          lastName: `${payload.fullName.split(' ').pop()}`,
          postcode: payload.address.postalCode,
          address: payload.address.addressLine1,
          city: payload.address.townCity || '',
          country: payload.address.countryIso2 || payload.address.countryIso || '',
          countryName: payload.address.country
        }
      });

      navigate('/register');
    };

    if (!token) {
      toast({
        title: 'Cannot find token, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });

      navigate('/yoti');
    } else if (token && !data && !loading) {
      if (token !== 'test') {
        authenticateYoti({
          params: { token }
        });
      } else {
        setTimeout(() => {
          setYoti(JSON.parse('{"fullName":"John Doe","address":{"addressLine1":"12 OLD GATE ROAD","address_line2":"FAVERSHAM","address_line3":"ME137SR","townCity":"FAVERSHAM","postalCode":"ME137 SR","country_iso":"GB", "countryIso2":"GB","country":"United Kingdom"},"phone":"+447918905721","email":"johndoe@monadd.io"}'));
        }, 2000);
      }
    } else if (token && error && !loading) {
      toast({
        title: 'Cannot connect with yoti, please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });

      navigate('/yoti');
    } else if (token && data && !loading) {
      setYoti(data);
    }
  }, [navigate, toast, token, error, data, loading, authenticateYoti]);

  return (
    <Box>

      <Box
        px={{
          sm: '25px',
          md: '171px',
          lg: '10px',
          xl: '70px',
          '2xl': '79px'

        }} justifyContent="center" alignItems="center" mt={{
          sm: '54px',
          md: '54px',
          lg: '97px'
        }}
      >
        <Flex justifyContent="center" mb="36px">
          {<Image src={MonaddLogoBlack} display={{
            sm: 'block',
            md: 'block',
            lg: 'none',
            xl: 'none'
          }} />}
        </Flex>
        <Heading
          fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
          fontWeight='700'
          color={dark}
          mb='16px'
          textAlign='center'
        >
          Connecting with Yoti!
        </Heading>
        <Text color={lightGray} textAlign='center' px={{
          sm: '15px',
          md: 0,
          lg: '0',
          xl: 0,
          '2xl': '60px'
        }} fontSize={{ sm: '16px', md: '16px', lg: '18px' }} fontWeight='400'>
          We are connecting with Yoti and retrieving your information.
        </Text>
        <Divider my='20px' />

        <Center><Spinner size='xl' /></Center>

      </Box>
    </Box>
  );
};

export default YotiCallback;
