import { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Progress,
  Text,
  useToast
} from '@chakra-ui/react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useAxios from 'axios-hooks';

import {
  dark,
  gray,
  purpleColor,
  purpleColorHover,
  API_ENDPOINTS,
  getLocalData
} from '../../utils';
import Navbar from '../onboarding/Navbar';
import SecureInfoTooltip from '../../components/ui/SecureInfoTooltip';

import './index.css';

const promise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function CardSection () {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const [{ data, error, loading }, createCard] = useAxios(
    {
      baseURL: process.env.REACT_APP_STRIPE_BE_URL,
      url: API_ENDPOINTS.ADD_CREDIT_CARD.url,
      method: API_ENDPOINTS.ADD_CREDIT_CARD.method
    },
    {
      manual: true
    }
  );

  const localData = getLocalData();

  const [{ data: card, loading: cardLoading }, refetch] = useAxios({
    baseURL: process.env.REACT_APP_STRIPE_BE_URL,
    url: API_ENDPOINTS.LIST_CREDIT_CARD.url,
    method: API_ENDPOINTS.LIST_CREDIT_CARD.method,
    data: {
      email: localData.email
    }
  });

  useEffect(() => {
    if (error) {
      toast({
        title: 'Something went wrong try again or contact support',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    }
    if (data) {
      refetch();

      toast({
        title: 'Your card has been added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const cardElement = elements?.getElement('card');
    const { token } = await stripe!.createToken(cardElement!);

    const localData = getLocalData();

    if (localData.email) {
      createCard({
        data: {
          cardToken: token?.id,
          email: localData.email
        }
      });
    }
  };

  return (
    <>
      <Navbar />
      <Flex
        justifyContent="center"
        alignItems="center"
        h="80vh"
        direction="column"
      >
        <Box
          p="20px"
          mb="50px"
          borderRadius="16px"
          shadow="lg"
          textAlign="center"
          w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }}
          onClick={(event) => event.stopPropagation()}
        >
          <Heading
            fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
            mt={{ sm: '24px', md: '28px' }}
            color={dark}
          >
            Your Card With Us
          </Heading>

          <Box>
            {cardLoading ? (
              <Progress size="xs" isIndeterminate mt="20px" />
            ) : (
              ''
            )}
          </Box>
          {
            card ? <Flex textAlign="left" fontSize={14} mt="30px">
              <Box>
                <Text mb="10px">Ending Digits: </Text>
                <Text mb="10px">Brand: </Text>
                <Text mb="10px">Country: </Text>
              </Box>

              <Box color={gray} ml="20px">
                <Text mb="10px">{card.last4}</Text>
                <Text mb="10px">{card.brand}</Text>
                <Text>{regionNames.of(card.country)}</Text>
              </Box>

              <Box ml="20px"></Box>
          </Flex> : null
          }
        </Box>
        <Box
          borderRadius="16px"
          shadow="lg"
          textAlign="center"
          w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }}
          onClick={(event) => event.stopPropagation()}
        >
          <Heading
            fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
            mt={{ sm: '24px', md: '28px' }}
            color={dark}
          >
            Add Your Card
          </Heading>
          <form id="payment-form" onSubmit={handleSubmit}>
            <Flex
              justifyContent="center"
              direction="column"
              my="30px"
              w="100%"
              p="20px"
            >
              <Box mb="20px">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </Box>

              <Button
                my="24px"
                w="100%"
                mt="40px"
                borderRadius="16px"
                bg={purpleColor}
                _hover={{ bg: purpleColorHover }}
                color="white"
                px="30px"
                py="30px"
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={loading}
              >
                Add Card
              </Button>
            </Flex>
          </form>
        </Box>
        <SecureInfoTooltip />
      </Flex>
    </>
  );
}

export default function Checkout () {
  return (
    <div className="App">
      <Elements
        stripe={promise}
        options={{
          appearance: {
            theme: 'flat',
            variables: {
              borderRadius: '15px'
            }
          }
        }}
      >
        <CardSection />
      </Elements>
    </div>
  );
}
