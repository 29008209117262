import { TStepProps } from '../types';
import { TStep } from './Steps';

function StepWrapper (props: TStepProps & {children: any, onboardingSteps: TStep[]}) {
  const { onboardingSteps, me, onStepChange, step } = props;

  const moveToStep: number = [...onboardingSteps].reduce((stepToMove: number, currentStep: TStep, index: number, arr: TStep[]): number => {
    const missingFields = me?.onboardingStatus?.missingFields;
    const requiredFields = me?.onboardingStatus?.requiredFields;

    if (currentStep.id < stepToMove && currentStep.field.filter((fieldName: string) => requiredFields.includes(fieldName) && missingFields.includes(fieldName)).length) {
      stepToMove = currentStep.id;

      arr.splice(1);
    }

    return stepToMove;
  }, step);

  if (moveToStep !== step) onStepChange(moveToStep);

  return (
    <>{props.children}</>
  );
}

export default StepWrapper;
