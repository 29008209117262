import { Flex, Image } from '@chakra-ui/react';

function Card (props: any) {
  return (
    <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        px={props.px ? props.px : '45px'}
        py={props.py ? props.py : '22px'}
        {...props}
        onClick={props.onClick}
    >
        <Image borderRadius='50%' shadow={'md'} src={props.src} fallbackSrc={props.fallbackSrc} minHeight='60px' maxHeight='80px' maxWidth='80px' fit='contain' />
    </Flex>
  );
}

export default Card;
