import { Flex, Image, Text, Box, useToast } from '@chakra-ui/react';
import {
  API_ENDPOINTS,
  dark,
  setLocalData
} from '../../utils';
// @ts-ignore linting bypass

import googleLogo from '../../assets/icons/google-logo.svg';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../store/user';

import { useGoogleLogin } from '@react-oauth/google';

const GmailAuthDummy = (props: any) => {
  const getUserAuthorization = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
    ux_mode: 'redirect',
    hint: props.email,
    select_account: false,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    state: 'readonly.requested'
  });

  useEffect(() => {
    getUserAuthorization();
  });

  return <></>;
};

const GoogleAuth = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [gmailError, setGmailError] = useState(false);
  const [{ data, loading }, fetchToken] = useAxios(
    API_ENDPOINTS.SOCIAL_LOGIN,
    { manual: true }
  );
  const [userGmail, setUserGmail] = useState('');
  const [
    { data: userDetails, loading: gmailJourneyLoading }, startGmailOnboarding
  ] = useAxios(
    API_ENDPOINTS.START_GMAIL, { manual: true }
  );
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (gmailError) {
      // avoid infinite loop on 400 errors in gmail login
      setSearchParams('');
      return;
    };
    const successResponse = async (code: any) => {
      await fetchToken({
        data: {
          provider: 'google-oauth2',
          code
        }
      }).catch((error) => {
        if (error?.response?.status === 500) {
          if (!gmailError) {
            setGmailError(true);
            toast({
              title: 'Error logging in with Gmail - have you already created a non-Gmail login for the same email?',
              status: 'error',
              duration: 10000,
              isClosable: true,
              position: 'top'
            });
            navigate('/login');
          };
        };
      });
    };
    if (searchParams.get('code') && !data && !loading) {
      successResponse(searchParams.get('code'));
    }
    if (data && !userDetails) {
      setLocalData({
        token: data.token,
        refreshToken: data.refresh
      });
      startGmailOnboarding({ data: {} }).catch((error) => {
        if (error?.response?.status === 400) {
          if (!gmailError) {
            setGmailError(true);
            toast({
              title: 'The Gmail account was not used for sign-in, but there is an account with the same Gmail already associated. Please login with email and password instead.',
              status: 'error',
              duration: 10000,
              isClosable: true,
              position: 'top'
            });
          };
        };
      });
    };
    if (userDetails) {
      setLocalData({
        token: data.token,
        refreshToken: data.refresh
      });
      dispatch(login(userDetails));
      if (userDetails?.email) {
        if (!searchParams.get('state') && !searchParams.get('scope')?.split(' ').includes('https://www.googleapis.com/auth/gmail.readonly')) {
          setUserGmail(userDetails.email);
        } else {
          navigate('/onboarding/name');
        }
      }
    }
  }, [
    data,
    loading,
    userDetails,
    gmailJourneyLoading,
    dispatch,
    navigate,
    searchParams,
    startGmailOnboarding,
    fetchToken,
    gmailError,
    setSearchParams,
    toast
  ]);

  const authenticateWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: process.env.REACT_APP_REDIRECT_URL
  });

  return (
    <Box>
      <Box
        as="button"
        width="100%"
        onClick={() => authenticateWithGoogle()}
        _hover={{
          background: 'white',
          color: 'teal.500'
        }}
      >
        <Flex borderRadius="4px" boxShadow="lg" px="40px" py="19px">
          <Image src={googleLogo} />
          <Text
            fontWeight="600"
            color={dark}
            display="flex"
            justifyContent="center"
            w="100%"
          >
            {props.name}
          </Text>
        </Flex>
      </Box>
      {userGmail ? <GmailAuthDummy email={userGmail} /> : <></>}
    </Box>
  );
};

export default GoogleAuth;
