import { TApiEndPoints } from '../types';

export const API_ENDPOINTS: TApiEndPoints = {
  ME: { url: '/api/me/', method: 'GET', useCache: false },

  // LOGIN AND SIGN UP
  SOCIAL_LOGIN: { url: '/auth/login/social/jwt-pair/', method: 'POST' },
  EMAIL_SIGNUP: { url: '/api/onboarding/start-manual/', method: 'POST' },
  EMAIL_SIGNIN: { url: '/auth/login/email/', method: 'POST' },
  YOTI_SIGNUP: { url: '/api/yoti', method: 'GET' },
  REFRESH_TOKEN: { url: '/auth/refresh/', method: 'POST' },
  VALIDATE_EMAIL: { url: '/api/onboarding/validate-email/', method: 'POST' },
  START_GMAIL: { url: '/api/onboarding/start-gmail/', method: 'POST' },
  INCREMENTAL_GMAIL_SCOPE: { url: '/api/me/gmail-incremental-auth-add-gmail-send/', method: 'POST' },
  START_AGAIN: { url: '/api/me/restart-journey/', method: 'POST', useCache: false },

  // PASSWPRD MANAGEMENT
  FORGOT_PASSWORD: { url: '/api/me/init-password-reset/', method: 'POST', useCache: false },
  RESET_PASSWORD: { url: '/api/me/reset-password/', method: 'POST' },

  // ONBOARDING
  PERSONAL_INFORMATION: { url: '/api/onboarding/personal-details/', method: 'POST' },
  GET_USER_NAME: { url: '/api/get-name-from-username', method: 'GET', useCache: false },
  ADD_EMAIL: { url: '/auth/add/email', method: 'POST' },
  ADD_ADDRESS: { url: '/api/onboarding/add-address/', method: 'POST' },
  ADD_PHONE_NUMBER: { url: '/api/onboarding/add-phone-number/', method: 'POST' },
  VALIDATE_PHONE_NUMBER: { url: '/api/onboarding/validate-phone-number/', method: 'POST' },

  // SERVICES
  SERVICE_PROVIDERS: { url: '/api/service-provider', method: 'GET', useCache: false },
  MY_SERVICE_PROVIDERS: { url: '/api/me/service-providers/', method: 'GET', useCache: false },

  SERVICE_AGREEMENTS: { url: '/api/service-agreements/', method: 'GET', useCache: false },
  SERVICE_AGREEMENTS_BY_CATEGORY: { url: '/api/service-agreements/get-by-category/', method: 'GET', useCache: false },
  DELETE_AGREEMENT: { url: '/api/service-agreements/:id/', method: 'DELETE' },
  ADD_SERVICE_AGREEMENT: { url: '/api/me/add-service-agreement/', method: 'POST' },

  POTENTIAL_PROVIDERS: { url: '/api/potential-providers/', method: 'GET', useCache: false },
  DELETE_POTENTIAL_PROVIDER: { url: '/api/potential-providers/:id/delete-provider/', method: 'DELETE' },
  ADD_POTENTIAL_PROVIDER: { url: '/api/potential-providers/manual-add/', method: 'POST' },

  UPDATE_PROVIDER_CHOICE: { url: '/api/provider-choice-update', method: 'PUT' },
  CONFRIM_PROVIDERS: { url: '/api/me/confirm-providers/', method: 'POST' },
  CHANGE_REQUESTS: { url: '/api/change-requests/', method: 'GET', useCache: false },
  CONNECT_REQUESTS: { url: '/api/connect-requests/', method: 'GET', useCache: false },
  DISCONNECT_REQUESTS: { url: '/api/disconnect-requests/', method: 'GET', useCache: false },

  GET_EMAIL_TEMPLATE: { url: '/api/get-send-request-template', method: 'GET', useCache: false },
  SEND_EMAIL_USING_GMAIL: { url: '/api/send-request-email/', method: 'GET', useCache: false },
  SEND_EMAIL_USING_MAILTO: { url: '/api/set-mailto-triggered', method: 'PUT', useCache: false },

  // SERVICE DISCOVERY
  PROCESS_EMAILS: { url: '/api/onboarding/email-processing/', method: 'POST' },
  EMAIL_PROCESSING: { url: '/api/me/email-processing', method: 'GET', useCache: false },

  // PAYMENTS
  ADD_CREDIT_CARD: { url: '/create-card', method: 'POST', useCache: false },
  LIST_CREDIT_CARD: { url: '/get-card', method: 'POST', useCache: false },

  CREATE_PAYMENT_SESSION: { url: '/api/payment-session-create/', method: 'GET', useCache: false },
  UPDATE_PAYMENT_SESSION: { url: '/api/payment-session-update/', method: 'GET', useCache: false },
  CONFIRM_PAYMENT: { url: '/api/payment-confirm/', method: 'PUT' }
};

export const MANUAL_JOURNEYS = ['manual_moving_onboarding_v1', 'manual_already_onboarding_v1', 'manual_manage_onboarding_v1'];
export const GMAIL_JOURNEYS = ['gmail_moving_onboarding_v1', 'gmail_already_onboarding_v1', 'gmail_manage_onboarding_v1'];

export const SPECIFIC_CUSTOMER_EMAIL = 'accounts@movinghub.co.uk';

export const PENDING_PROVIDER_REQUEST_STATUS = [
  'INITIALIZED',
  'FAILED_TO_SEND',
  'PROVIDER_FLAGGED_SUPPORT',
  'USER_FLAGGED_IDENTIFY',
  'SENT',
  'OVERTIME'
];

export const COMPLETED_PROVIDER_REQUEST_STATUS = [
  'USER_GMAIL_SENT',
  'MAILTO_TRIGGERED',
  'CONFIRMED',
  'NOT_APPLICABLE'
];

export const ACTION_REQUIRED_PROVIDER_REQUEST_STATUS = [
  'READY_TO_SEND',
  'REJECTED_BY_PROVIDER',
  'INFO_REQUIRED'
];

// processing required and missing fields
export const NAME = 'name';
export const OLD_LOCATION = 'old_location';
export const NEW_LOCATION = 'new_location';
export const PHONE_NUMBER = 'phone_number';
export const EMAIL = 'email';
export const ADD_SERVICES = 'add_services';
export const CONFIRM_PROVIDERS = 'confirm_providers';
export const ADD_ACCOUNTS = 'add_accounts';
export const PAYMENT = 'payment';
export const PROCESSING = 'processing_emails';

export const CLEARBIT = 'https://logo.clearbit.com/';
