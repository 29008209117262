import {
  Spinner,
  Flex,
  Image,
  Spacer,
  Text
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import homeIcon from '../../../../assets/dashboard/Frame120.svg';

type TServiceWidgetProps = {
  emailAddresses: number;
  total: number;
  finished: boolean;
};

function ServicesWidget (props: TServiceWidgetProps) {
  return (
    <Flex alignItems='center' width='100%' h={'60px'} pr='20px' pl='5px' backgroundColor='#FFDB80'>
      <Image width='60px' padding='10px' src={homeIcon} />
      <Text fontWeight={{ sm: '500' }} fontSize={{ sm: 'xs', md: 'md' }}>{`${props.total} Service${props.total > 1 || props.total === 0 ? 's' : ''} discovered from ${props.emailAddresses} email address(es).`}</Text>
      <Spacer />
      {props.finished ? <CheckIcon color='green' /> : <Spinner colorScheme='purple' />}
    </Flex>
  );
}

export default ServicesWidget;
