import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { dark } from '../../utils/themeSettings';
import googleLogo from '../../assets/icons/google-logo.svg';
import { useGoogleLogin } from '@react-oauth/google';

const LoginWithGoogle = (props: any) => {
  const authenticateWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: credentialResponse => {
      props.onGoogleLoginSuccess(credentialResponse);
    },
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
    ux_mode: 'popup',
    redirect_uri: '/',
    state: 'addaccounts.readonly.requested'
  });

  return (
    <Box
      as="button"
      width="100%"
      onClick={() => authenticateWithGoogle()}
      _hover={{
        background: 'white',
        color: 'teal.500'
      }}
    >
      <Flex borderRadius={4} boxShadow="lg" px="40px" py="19px">
        <Image src={googleLogo} />
        <Text
          fontWeight="600"
          color={dark}
          display="flex"
          justifyContent="center"
          w="100%"
        >
          {props.gmailButtonText}
        </Text>
      </Flex>
    </Box>
  );
};

export default LoginWithGoogle;
