import { useEffect } from 'react';
import {
  Box,
  Flex
} from '@chakra-ui/react';
import MonaddSideBanner from '../../components/ui/MonaddSideBanner';
import { useNavigate } from 'react-router-dom';

import { getLocalData } from '../../utils';
import AuthForm from './AuthForm';
import Yoti from './Yoti';
import YotiCallback from './YotiCallback';

type TAuthProps = {
  viewType: 'LOGIN' | 'REGISTER' | 'YOTI' | 'YOTI_CALLBACK' | 'AFFILIATE' | 'REFERRAL';
};

function Auth ({ viewType }: TAuthProps) {
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const localData = getLocalData();

    if (localData.token) navigate('/onboarding/name');
  });

  const renderForm = viewType === 'YOTI' ? <Yoti /> : viewType === 'YOTI_CALLBACK' ? <YotiCallback /> : <AuthForm isRegister={viewType === 'REGISTER' || viewType === 'AFFILIATE' || viewType === 'REFERRAL'} viewType={viewType} />;

  return (
    <Flex
      gap={6}
      flexDirection={{
        sm: 'column-reverse',
        md: 'column-reverse',
        lg: 'row'
      }}
      mx={{
        sm: 0,
        md: 0,
        lg: '165px',
        xl: '165px'
      }}
      my={{
        sm: 0,
        md: 0,
        lg: '100px',
        xl: '100px'
      }}
    >
      <Box flex={1}>
        <MonaddSideBanner />
      </Box>
      <Box flex={1}>
        {renderForm}
      </Box>
    </Flex>
  );
}

export default Auth;
