import { useEffect, useState } from 'react';
import {
  Stack
} from '@chakra-ui/react';
import '../providers.css';

import ProviderRow from './ProviderRow';

type TProviderRowGroupProps = {
  providers: any;
  onChange: Function;
  onCorrespondence: (data: any) => void;
};

function ProviderRowGroup (props: TProviderRowGroupProps) {
  const { providers, onChange, onCorrespondence } = props;

  const [groups, setGroups] = useState<any>({
    freeRequests: [],
    paidRequests: [],
    transitionRequests: []
  });

  useEffect(() => {
    const groupedData: any = {
      freeRequests: [],
      paidRequests: []
    };

    providers.forEach((provider: any) => {
      if (provider.to && provider.from) {
        if (provider.to.isPaid || provider.from.isPaid) {
          groupedData.paidRequests.push(provider);
        } else {
          groupedData.freeRequests.push(provider);
        }
      } else {
        const paymentRequired = provider.isPaid;

        if (paymentRequired) {
          groupedData.paidRequests.push(provider);
        } else {
          groupedData.freeRequests.push(provider);
        }
      }
    });

    setGroups(groupedData);
  }, [providers]);

  return (
    <>
      <Stack py={3}>
        {(providers || []).map((provider: any, index: number) => <ProviderRow key={`provider-${index}`} providers={provider} onChange={onChange} last={index === groups.paidRequests.length - 1} noBadge onCorrespondence={onCorrespondence} />)}
      </Stack>
    </>
  );
}

export default ProviderRowGroup;
