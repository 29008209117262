import {
  Heading,
  Text
} from '@chakra-ui/react';

import { dark } from '../../../../utils/themeSettings';

type TVerifyEmailProps = {
  email: string
};

function VerifyEmail ({ email }: TVerifyEmailProps) {
  return (
    <>
      <Heading fontSize="26px" color={dark}>
        Final Check!
      </Heading>
      <Text color={dark} px="20px" my="15px">
        We have sent a confirmation email to {email}.
        Click the link in the email to validate your address
      </Text>
    </>
  );
};

export default VerifyEmail;
