export const purpleColor = '#513FCA';
export const purpleColorHover = '#6C5DD3';
export const gray = '#8692A6';
export const dark = '#373D43';
export const lightGray = '#B0B7BE';
export const darkGray = '#5d6166';
export const offWhite = '#F5F5F6';
export const green = '#08AD36';
export const light = '#F9FAFA';
export const red = '#E53E3E';
export const blue = '#499DF8';
export const orange = '#FF754C';
export const lightPurple = '#DAD6F4';
export const yellow = '#FFCC00';
