import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  FlexProps,
  Text
} from '@chakra-ui/react';

import '../dashboard.css';

interface NavItemProps extends FlexProps {
  icon: any;
  title: string;
  path: string;
  isClicked?: boolean;
  url?: string;
}

const NavBarItem = (props: NavItemProps) => {
  const {
    icon,
    title,
    path,
    isClicked,
    ...rest
  } = props;

  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  const nav = <Flex
    className={path.includes(currentPath || 'dashboard') ? 'active-link' : ''}
    align="center"
    py="4"
    px="5"
    borderRadius="lg"
    role="group"
    cursor="pointer"
    mb="1"
    _hover={{
      background: '#F3F3F3',
      color: 'black'
    }}
    {...rest}
  >
    <Box mr="16px">{icon}</Box>
    <Text fontWeight='700' fontSize='16px' color={!path.includes(currentPath) ? 'grey' : 'black'}>{title}</Text>
  </Flex>;

  return (
    props.url ? <a href={props.url}>{nav}</a> : <Link to={path}>{nav}</Link>
  );
};

export default NavBarItem;
