import { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import {
  API_ENDPOINTS
} from '../../../../utils';
import ServicesWidget from './ServicesWidget';
import DashboardWidget from './DashboardWidget';

type TServiceDiscoveryProps = {
  view: 'Dashboard' | 'Services';
  onUpdate: Function;
};

function ServiceDiscovery ({ onUpdate, view }: TServiceDiscoveryProps) {
  const [{ data, loading }, refetchStatus] = useAxios(API_ENDPOINTS.EMAIL_PROCESSING);
  const [discoveryStatus, setDiscoveryStatus] = useState<any>({
    total: 0,
    finished: true
  });

  useEffect(() => {
    if (data && data.emailProcessingStatus.length && discoveryStatus.total !== data.discoveredProviders.length) {
      const emails = data.emailProcessingStatus.map((email: any) => email[Object.keys(email)[0]]);

      const pending = emails.filter((email: any) => email.status === 'processing');

      if (discoveryStatus.total !== data.discoveredProviders.length) onUpdate();

      setDiscoveryStatus({
        total: data.discoveredProviders.length,
        finished: pending.length === 0
      });
    } else if (data && data.discoveredProviders && discoveryStatus.total !== data.discoveredProviders.length) {
      setDiscoveryStatus({
        total: data.discoveredProviders.length,
        finished: true
      });
    }

    if (!loading && !discoveryStatus.finished) {
      setTimeout(refetchStatus, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, discoveryStatus]);

  if (discoveryStatus.finished && view === 'Services') return null;

  return (
    view === 'Services' ? <ServicesWidget total={discoveryStatus.total} finished={discoveryStatus.finished} emailAddresses={(data?.emailProcessingStatus || []).length} /> : <DashboardWidget discovered={data?.discoveredProviders || []} total={discoveryStatus.total} finished={discoveryStatus.finished} emailAddresses={(data?.emailProcessingStatus || []).length} />
  );
}

export default ServiceDiscovery;
