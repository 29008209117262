import {
  Button,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { useState } from 'react';
import { purpleColor } from '../../utils/themeSettings';

function PasswordField (props: any) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size='md'>
      <Input
        type={show ? 'text' : 'password'}
        {...props}
        borderRadius={4}
        px='30px'
        py='30px'
        name={props.name}
      />
      <InputRightElement width='4.5rem' mt='10px'>
        <Button
          h='1.75rem'
          variant='unstyled'
          size='sm'
          onClick={handleClick}
          bg='transparent'
          color={purpleColor}
          fontWeight='normal'
        >
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}

export default PasswordField;
