import { useState } from 'react';
import useAxios from 'axios-hooks';
import { BiCheck } from 'react-icons/bi';
import {
  Spinner,
  Box,
  useToast
} from '@chakra-ui/react';

import ConfirmProviderDialog from './ConfirmProviderDialog';
import { API_ENDPOINTS, setLocalData, getLocalData } from '../../../../utils';
import { TProvider } from '../types';

import '../providers.css';

type TConfirmProviderButtonProps = {
  provider: TProvider;
  onDone: Function;
  action: string;
};

function ConfirmProviderButton (props: TConfirmProviderButtonProps) {
  const { provider, action, onDone } = props;

  const toast = useToast();

  const [openDialog, setOpenDialog] = useState(false);

  const [{ loading }, confirmProvider] = useAxios(API_ENDPOINTS.CONFRIM_PROVIDERS, { manual: true });

  const onConfirm = async (confirmConsent: boolean) => {
    if (confirmConsent) setLocalData({ confirmAllConsent: true });

    const data: any = {
      potentialProviderChange: [],
      potentialProviderDisconnect: [],
      serviceAgreementChange: [],
      serviceAgreementConnect: [],
      serviceAgreementDisconnect: [],
      switchEnergy: false
    };

    const key = action === 'ADDRESS_CHANGE' ? (
      provider.type === 'POTENTIAL' ? 'potentialProviderChange' : 'serviceAgreementChange'
    ) : action === 'CONNECT' ? (
      provider.type === 'POTENTIAL' ? null : 'serviceAgreementConnect'
    ) : (provider.type === 'POTENTIAL' ? 'potentialProviderDisconnect' : 'serviceAgreementDisconnect');

    if (key) {
      data[key].push(provider.agreementId || provider.id);

      try {
        const resp = await confirmProvider({ data });

        toast({
          title: `${provider.name} is confirmed.`,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        });

        onDone(resp.data[0]);
      } catch (err: any) {
        if (err?.response.status === 402) {
          toast({
            title: `${provider.name} cannot be confirmed without payment.`,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top'
          });

          onDone();
        }
      }

      setOpenDialog(false);
    }
  };

  const handleConfirm = () => {
    const config = getLocalData();

    if (config.confirmAllConsent) {
      onConfirm(config.confirmAllConsent);
    } else {
      setOpenDialog(true);
    }
  };

  return loading ? <Spinner /> : (
    <Box
      borderRadius='100%'
      shadow='lg'
      p={{ base: '10px', lg: '12px' }}
      ml={{ base: '5px', lg: '15px' }}
      cursor='pointer'
      onClick={() => handleConfirm()}
    >
      <BiCheck fontSize={22} />

      {openDialog && <ConfirmProviderDialog showDialog={openDialog} onCancel={() => setOpenDialog(false)} isLoading={loading} onConfirm={onConfirm} />}
    </Box>
  );
}

export default ConfirmProviderButton;
