import React, { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button, Text, Flex, Spinner, Spacer,
  Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
  Alert, AlertIcon, AlertDescription
} from '@chakra-ui/react';

import StartPayment from './StartPayment';
import Paid from './Paid';

import { offWhite, API_ENDPOINTS } from '../../../utils';
import { UserState, TMe } from '../types';

type TPayNowProps = {
  totalProviderRequests: number;
  onDone: Function;
  onCancel: Function;
  startPayment?: boolean;
};

function PayNow (props: TPayNowProps) {
  const me: TMe = useSelector((state: UserState) => state.user.value);

  const [searchParams] = useSearchParams();
  const paymentIntentParam = searchParams.get('payment_intent');
  const [paymentId, setPaymentId] = useState(paymentIntentParam);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(props.startPayment || !!paymentIntentParam);
  const enablePayNow = me.paymentRequired && !me.paymentCompleted;

  const [{ data, loading }, confirmPayment] = useAxios(API_ENDPOINTS.CONFIRM_PAYMENT, { manual: true });

  useEffect(() => {
    if (me.onboardingStatus?.missingFields?.includes('payment')) {
      if (paymentId && !loading && !data) {
        confirmPayment({
          data: { paymentId }
        });
        setPaymentId(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, paymentId, data, me]);

  useEffect(() => {
    if (props.startPayment === true || props.startPayment === false) setShowPaymentModal(props.startPayment);
  }, [props.startPayment]);

  const modalSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1280) return 'xl';
    else if (screenWidth >= 992) return 'lg';
    else if (screenWidth >= 768) return 'md';
    else if (screenWidth >= 480) return 'sm';
    else return 'full';
  };

  if (!enablePayNow) return null;

  return (
    <Alert bg={me.freeRequests <= 0 ? 'purple.200' : 'purple.100' } status={me.freeRequests <= 0 ? 'info' : 'warning'} borderBottomRadius={4}>
      <AlertIcon />
      <Flex alignItems='center' width='100%' minHeight='40px'>
        <AlertDescription>
          {
            enablePayNow && me.freeRequests <= 0 && props.totalProviderRequests > 3 ? <Text fontWeight={{ sm: '500' }} fontSize={{ sm: 'xs', md: 'md' }}>Complete your payment to continue with your request(s).</Text> : props.totalProviderRequests === 3 && enablePayNow ? <Text fontWeight={{ sm: '500' }} fontSize={{ sm: 'xs', md: 'md' }}>You have used all of your free requests.</Text> : <Text fontWeight={{ sm: '500' }} fontSize={{ sm: 'xs', md: 'md' }}>You have {me.freeRequests} free requests left to get started.</Text>
          }
        </AlertDescription>
        <Spacer />
        {
          (enablePayNow && props.totalProviderRequests > 3) && (
            <Button
              bgColor={offWhite}
              color={'#513fca'}
              borderRadius={4}
              _hover={{
                backgroundColor: '#3e2db0',
                color: 'white',
                cursor: 'pointer'
              }}
              onClick={() => {
                setShowPaymentModal(true);
              }}
            >
              Pay now
            </Button>
          )
        }
      </Flex>
      {showPaymentModal && (
        <Modal size={modalSize()} isOpen={showPaymentModal} onClose={() => { setShowPaymentModal(false); props.onCancel(); }} closeOnOverlayClick={false} isCentered>
          <ModalOverlay
            backdropFilter='auto' backdropBlur='2px' />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {loading ? <Spinner size='xl' /> : (me.paymentRequired && !me.paymentCompleted) ? <StartPayment me={me} /> : <Paid onDone={() => {
                setShowPaymentModal(false);
                props.onDone();
              }} />}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Alert>
  );
}

export default PayNow;
