import React from 'react';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Grid, Image, Stack, Text } from '@chakra-ui/react';

import giveMeFive from '../../assets/dashboard/giveMeFive.svg';
import blackHand from '../../assets/dashboard/BlackHand.svg';
import homeIcon from '../../assets/dashboard/Frame120.svg';
import personInPurple from '../../assets/dashboard/group8.svg';
import personInBlue from '../../assets/dashboard/group7.svg';

const DashboardCard = (props: any) => {
  const image = () => {
    if (props.image === 'giveMeFive') return giveMeFive;
    if (props.image === 'blackHand') return blackHand;
    if (props.image === 'homeIcon') return homeIcon;
    if (props.image === 'personInBlue') return personInBlue;
    if (props.image === 'personInPurple') return personInPurple;
  };

  const disabledBackground = {
    background: 'repeating-linear-gradient(45deg, #F3F3F3, #F3F3F3 10px, grey 10px, grey 20px)',
    backgroundSize: 'cover'
  };

  const theme = () => {
    if (props.cardType === 'greyCard') {
      return {
        bgColor: '#F3F3F3',
        textGap: '12px',
        textColor: 'black',
        btnColor: '#6D59E9',
        btnTextColor: 'white',
        buttonHoverColor: '#5242bc',
        gridColumns: { base: '80%', md: '75%', lg: '40%' }
      };
    }
    if (props.cardType === 'yellowCard') {
      return {
        bgColor: '#FFDB80',
        textColor: 'black',
        textGap: '8px',
        btnColor: 'white',
        btnTextColor: 'black',
        buttonHoverColor: '#e8e8e8',
        gridColumns: { base: '100%', md: '90%', lg: '80%' }
      };
    }
    if (props.cardType === 'blueCard') {
      return {
        bgColor: '#7F66FF',
        textColor: 'white',
        textGap: '8px',
        btnColor: 'white',
        btnTextColor: '#7F66FF',
        buttonHoverColor: '#e8e8e8',
        gridColumns: { base: '100%', md: '90%', lg: '80%' }
      };
    }
  };

  return (
    <Box
      as={Grid}
      gridTemplateColumns={theme()?.gridColumns}
      p='6'
      borderRadius='4px'
      pos='relative'
      gap={4}
      backgroundColor={theme()?.bgColor}
      style={props.disabled ? disabledBackground : {}}
      h={props.cardType === 'greyCard' ? '293px' : '178px'}
      opacity={props.disabled ? '0.5' : undefined}
    >
      <Stack
        gap='20px'
        zIndex={10}
        textColor={theme()?.textColor}
      >
        <Stack gap={theme()?.textGap} >
          <Text fontSize='18px' fontWeight='700'>{props.primaryText}</Text>
          <Text fontSize='14px' fontWeight='400'>{props.secondaryText}</Text>
        </Stack>
        {props.cardType === 'greyCard' && (
          <Stack
            color='#6D59E9'
            fontSize='14px'
            direction='row'
            alignItems='center'
          >
            <QuestionOutlineIcon />
            <Text>Need help?</Text>
          </Stack>
        )}
        <Box pos='absolute' bottom='30px'>
          {props.content ? props.content : <Button
            fontWeight='700'
            fontSize='14px'
            px='24px'
            py='10px'
            h='40px'
            borderRadius={4}
            bgColor={theme()?.btnColor}
            color={theme()?.btnTextColor}
            disabled={props.disabled}
            _hover={{
              backgroundColor: theme()?.buttonHoverColor
            }}
            onClick={props.action}
          >
            {props.btnText}
          </Button>
          }
        </Box>
      </Stack>
      <Stack
        pos='absolute'
        right='0'
        bottom='0'
        opacity={{ base: '0.5', lg: '1' }}
        {...props.imageProperties}
      >
        <Box>
          <Image src={image()} />
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardCard;
