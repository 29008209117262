import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'axios-hooks';
import {
  AbsoluteCenter,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
  Spinner,
  Spacer,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import { API_ENDPOINTS, purpleColor, purpleColorHover, setLocalData, getLocalData, removeStorage } from '../../../../utils';
import { UserState } from '../../types';

import './correspondence.css';

type TCorrespondenceProps = {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  onChange: () => void;
  reloadMe: Function;
};

function Correspondence (props: TCorrespondenceProps) {
  const { data, isOpen, onClose, onChange } = props;

  const user = useSelector((state: UserState) => state.user.value);
  const toast = useToast();

  const gmailCallbackData = getLocalData().correspondenceCallBackData;

  if (gmailCallbackData) {
    removeStorage('correspondenceCallBackData');
  }
 
  // const [emailTemplate, setEmailTemplate] = useState<string>('');
  const [agreed, setAgreed] = useState(data?.emailSendType === 'MAILTO');
  const [{ loading, data: emailData, error: emailError }] = useAxios(
    {
      ...API_ENDPOINTS.GET_EMAIL_TEMPLATE,
      url: `${API_ENDPOINTS.GET_EMAIL_TEMPLATE.url}/${data?.id}`
    },
    { manual: false }
  );

  const [{ data: providerData, loading: providerLoading }] = useAxios({ ...API_ENDPOINTS.SERVICE_PROVIDERS, params: { id: (data?.providerId || data?.provider) } });

  const [{ loading: gmailLoading, error: gmailError }, sendEmailThroughGmail] = useAxios(
    API_ENDPOINTS.SEND_EMAIL_USING_GMAIL,
    { manual: true }
  );

  const [{ loading: mailToLoading, error: mailToError }, sendEmailUsingMailTo] = useAxios(
    API_ENDPOINTS.SEND_EMAIL_USING_MAILTO,
    { manual: true }
  );

  const sendEmailGmail = async () => {
    await sendEmailThroughGmail({
      url: `${API_ENDPOINTS.SEND_EMAIL_USING_GMAIL.url}${data?.id}`
    });
    toast({
      title: 'Email Sent. Please check back if your provider needs more information.',
      status: 'success',
      isClosable: true,
      duration: 3000,
      position: 'top'
    });
    onChange();
    onClose();
    // props?.onClose(false);
  };

  const triggerMailto = async (e: any) => {
    e.preventDefault();
    await sendEmailUsingMailTo({
      data: {
        id: data.id
      }
    });
    toast({
      title: 'Email Sent. Please check back if your provider needs more information.',
      status: 'success',
      isClosable: true,
      duration: 3000,
      position: 'top'
    });
    // @ts-ignore;
    const newTab = window.open(emailData.mailto, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;

    onChange();
    onClose();
    // props.onClose(false);
  };

  const sendEmailPermissionGranted = () => {
    if (user.primaryEmail.gmailSendScope) return true;

    const emailWithSendScope = user.secondaryEmails.find((value) => value.gmailSendScope === true);

    if (emailWithSendScope) return true;

    return false;
  };

  const sendRequest = (event: any) => {
    const { sendEmailType } = data;

    if (sendEmailType === 'GMAIL') {
      sendEmailGmail();
    } else {
      triggerMailto(event);
    }
  };

  const getHandlePopupResult = () => {
    return (result: any) => {
      if (result) {
        setAgreed(true);
      }
    };
  };

  const oauth2SignIn = async (event: any) => {
    setAgreed(true);

    if (sendEmailPermissionGranted()) return;

    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);
    form.autofocus = true;

    const params = {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_DASHBOARD_URL,
      scope: 'https://www.googleapis.com/auth/gmail.send',
      include_granted_scopes: 'true',
      response_type: 'code',
      access_type: 'offline'
      /// login_hint: props.associatedEmail
    };
    for (const p in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      // @ts-ignore

      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }
    // @ts-ignore
    window.handlePopupResult = getHandlePopupResult();

    function popup (f: any) {
      document.body.appendChild(f);
      // @ts-ignore
      form.submit(f);
    }

    setLocalData({
      correspondenceCallBackData: data
    });

    popup(form);
  };

  const closeDrawer = () => {
    // setEmailTemplate('');
    onClose();
  };

  useEffect(() => {
    if (emailError && !loading) {
      toast({
        title: 'Failed to load email correspondence. Please try again later.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      });
      closeDrawer();
    }

    if (gmailError && !gmailLoading) {
      toast({
        title: 'Failed to send email via gmail. Please try again later.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      });
      closeDrawer();
    }

    if (mailToError && !mailToLoading) {
      toast({
        title: 'Failed to mark email correspondence as sent. Please try again later.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      });
      closeDrawer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailError, loading, gmailError, mailToError, mailToLoading, gmailLoading]);

  const LOADER = (loading || providerLoading) ? <AbsoluteCenter p='4' axis='both'>
    <Spinner color='purple' size='xl' />
  </AbsoluteCenter> : null;

  return (
    <Drawer onClose={closeDrawer} isOpen={isOpen} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Correspondence
          <Text fontSize='md'>{providerData && providerData.length ? providerData[0]?.name : ''}</Text>
        </DrawerHeader>
        <DrawerBody>
          <VStack w='100%'>
            {
              data.showSendEmailButton && (
                <>
                  <Flex width='100%' alignContent='center' alignItems='center'>
                    <CheckIcon color={purpleColor} />
                    <Text ml='10px'>
                      For this company to fulfil this request they require for
                      the email to be sent from you
                    </Text>
                  </Flex>
                  <Flex width='100%' alignContent='center' alignItems='center'>
                    <CheckIcon color={purpleColor} />
                    <Text ml='10px'>
                      Your custom email will open in your predefined email
                      client.
                    </Text>
                  </Flex>
                </>
              )
            }
            <Divider />
            {LOADER}
            {!loading && (<>
              {
                // (data.showSendEmailButton || ['MAILTO_TRIGGERED', 'USER_GMAIL_SENT', 'SENT'].includes(data.emailStatus)) && (
                  <Flex width='100%'>
                    <Box
                      bg='gray.100'
                      color='black'
                      borderRadius='4'
                      my='1'
                      p='3'
                      mr='4'
                      width='100%'
                      dangerouslySetInnerHTML={{ __html: emailData?.html }}
                    />
                    <Avatar
                      name={`${user?.firstName} ${user?.lastName}`}
                      width='50px'
                      height='50px'
                      bg='purple.100'
                    />
                  </Flex>
                // )
              }
              {data.reason &&
                <Flex width='100%'>
                  <Avatar
                    name={'M D'}
                    width='50px'
                    height='50px'
                    bg='purple.300'
                  />
                  <Box
                    bg='purple.100'
                    color='black'
                    borderRadius='4'
                    my='1'
                    p='3'
                    ml='4'
                    width='100%'
                    dangerouslySetInnerHTML={{ __html: data.reason }}
                  />
                  <Spacer />
                </Flex>
              }
            </>)}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <VStack width='100%'>
            <Divider />
            {(data.sendEmailType === 'GMAIL' && !sendEmailPermissionGranted()) ? (<Flex width='100%'>
              <Checkbox
                checked={agreed}
                disabled={loading}
                size={'lg'}
                color={'red'}
                onChange={oauth2SignIn}
                height='25px'
                width='25px'
              />

              <Text ml='10px'>
                Allow Monadd to send this request directly from your email account
              </Text>
            </Flex>
            ) : null}
            <Flex width='100%' alignItems='center' gap='2'>
              <Button variant='outline' colorScheme='red' size={{ base: 'xs', md: 'md' }} mr={3} onClick={closeDrawer}>
                Close
              </Button>
              <Spacer />
              {
                (data.showSendEmailButton || data.showSendEmailButton === undefined) && (
                  <Button
                    bg={purpleColor}
                    borderRadius={4}
                    size={{ base: 'xs', md: 'md' }}
                    color='white'
                    p={{ base: '4', md: '2' }}
                    _hover={{
                      bg: purpleColorHover
                    }}
                    isLoading={gmailLoading || mailToLoading}
                    isDisabled={loading || gmailLoading || mailToLoading || (agreed ? true : data.sendEmailType === 'GMAIL' && !sendEmailPermissionGranted())}
                    onClick={sendRequest}
                  >
                    {['MAILTO_TRIGGERED', 'USER_GMAIL_SENT'].includes(data.emailStatus) ? 'Send Again' : 'Send Request'}
                  </Button>
                )
              }
            </Flex>
          </VStack>

        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default Correspondence;
