import { Image, Flex, Heading, Text, Button } from '@chakra-ui/react';
import { dark, darkGray, purpleColor, purpleColorHover } from '../../../utils/themeSettings';
import checkIcon from '../../../assets/icons/big-check.svg';

function Paid (props: any) {
  return (
    <Flex flexDirection='column' alignItems='center' textAlign='center' p='20px' h={{ sm: '100vh', md: 'auto' }}>
      <Heading fontSize={{ sm: '28px', md: '28px', lg: '36px' }} mt={{ sm: '24px', md: '28px', lg: 0 }} color={dark}>
        Thank You
      </Heading>
      <Text color={darkGray} px='30px' my='20px'>
        Payment is complete, you can continue when you're ready.
      </Text>
      <Flex justifyContent='center' my='20px'>
        <Image src={checkIcon} boxSize='110px' />
      </Flex>
      <Button
        justifySelf='flex-end'
        width='80%'
        borderRadius={4}
        my='30px'
        py='30px'
        bg={purpleColor}
        _hover={{ bg: purpleColorHover }}
        color='white'
        onClick={() => props.onDone()}
      >
        Continue
      </Button>
    </Flex>
  );
}

export default Paid;
