import { useRef } from 'react';

import {
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialog
} from '@chakra-ui/react';

type TConfirmDialogProps = {
  showDialog: boolean;
  heading: string;
  msg: string;
  onConfirm: Function;
  onCancel: Function;
  isLoading?: boolean;
};

function ConfirmDialog (props: TConfirmDialogProps) {
  const { showDialog, heading, msg, onConfirm, onCancel, isLoading } = props;

  const cancelRef = useRef(null);
  const modalSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1280) return 'xl';
    else if (screenWidth >= 992) return 'lg';
    else if (screenWidth >= 768) return 'md';
    else if (screenWidth >= 480) return 'sm';
    else return 'xs';
  };

  return (
    <AlertDialog
      size={modalSize()}
      motionPreset='slideInBottom'
      onClose={() => onCancel()}
      isOpen={showDialog}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent borderRadius={4}>
        <AlertDialogHeader>{heading}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{msg}</AlertDialogBody>
        <AlertDialogFooter>
          <Button borderRadius={4} isDisabled={isLoading} onClick={() => onCancel()}>No</Button>
          <Button borderRadius={4} colorScheme='red' ml={3} onClick={() => onConfirm()} isLoading={isLoading} isDisabled={isLoading}>Yes</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ConfirmDialog;
