import { useRef, useState } from 'react';
import useAxios from 'axios-hooks';
import {
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  AlertDialog,
  Box
} from '@chakra-ui/react';
import { AsyncCreatableSelect, ChakraStylesConfig } from 'chakra-react-select';

import { API_ENDPOINTS, dark } from '../../../utils';

type TAddProviderDialogProps = {
  showDialog: boolean;
  onConfirm: Function;
  onCancel: Function;
};

const chakraStyles: ChakraStylesConfig = {
  control: (provided, state) => ({
    ...provided,
    h: '60px',
    borderRadius: '4px',
    borderColor: '#e2e8f0',
    textAlign: 'left'
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    border: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: dark,
    bg: 'white',
    border: 'none'
  })
};

function AddProviderDialog (props: TAddProviderDialogProps) {
  const { showDialog, onConfirm, onCancel } = props;

  const cancelRef = useRef(null);
  const [addedProviders, setAddedProviders] = useState<any>([]);

  const [{ loading: searching }, searchProviders] = useAxios(API_ENDPOINTS.SERVICE_PROVIDERS, { manual: true });
  // eslint-disable-next-line no-empty-pattern
  const [{ loading: addProviderLoading }, addServiceProvider] = useAxios(API_ENDPOINTS.ADD_SERVICE_AGREEMENT, { manual: true });
  // eslint-disable-next-line no-empty-pattern
  const [{ }, addManualProvider] = useAxios(API_ENDPOINTS.ADD_POTENTIAL_PROVIDER, { manual: true });

  const handleSearch = async (query: any) => {
    if (query !== '') {
      const searchData = await searchProviders({ params: { query } });

      return searchData.data;
    }

    return [];
  };

  const handleOnInputChange = (inputValue: any) => {
    setAddedProviders(inputValue);
  };

  const handleOnCofirm = async () => {
    for (let index = 0; index < addedProviders.length; index++) {
      if (addedProviders[index].id) {
        await addServiceProvider({ data: { provider: addedProviders[index].id } });
      } else {
        await addManualProvider({ data: { providerNames: [addedProviders[index].value] } });
      }
    }

    setAddedProviders([]);

    onConfirm();
  };

  const handleCancel = () => {
    setAddedProviders([]);

    onCancel();
  };

  const modalSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1280) return 'xl';
    else if (screenWidth >= 992) return 'lg';
    else if (screenWidth >= 768) return 'md';
    else if (screenWidth >= 480) return 'sm';
    else return 'xs';
  };

  return (
    <AlertDialog
      size={modalSize()}
      motionPreset='slideInBottom'
      onClose={handleCancel}
      isOpen={showDialog}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent borderRadius={4}>
        <AlertDialogHeader>Add Provider(s)</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Box w={{ base: '100%' }} >
            <AsyncCreatableSelect
              isMulti
              chakraStyles={chakraStyles}
              placeholder='Find service provider...'
              loadOptions={handleSearch}
              onChange={handleOnInputChange}
              getOptionValue={(option: any) => option.id || option.value}
              getOptionLabel={(option: any) => option.name || option.label }
              value={addedProviders}
            />
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button borderRadius={4} onClick={handleCancel} disabled={addProviderLoading}>Cancel</Button>
          <Button borderRadius={4} colorScheme='purple' ml={3} onClick={() => handleOnCofirm()} isDisabled={searching || addProviderLoading} isLoading={addProviderLoading}>Confirm</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default AddProviderDialog;
