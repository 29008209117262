import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';

import logo from '../../assets/logo.svg';

import checkIcon from '../../assets/icons/signup-checks.svg';

import ratingsIcon from '../../assets/icons/google-ratings.svg';

import {
  purpleColor
} from '../../utils/themeSettings';

type TAuthProps = {
  isRegister?: boolean;
};

const MonaddSideBanner = (props: TAuthProps) => {
  return (
        <Box
        bg={purpleColor}
        w="100%"
        px={{ sm: '28px', md: '130px', lg: '80px', xl: '80px' }}
        py={{ sm: '40px', md: '40px', lg: '70px', xl: '50px' }}
        borderRadius={{ sm: '0px', md: '0px', lg: '4px', xl: '4px' }}
        color="white"
      >
        <Flex justifyContent={{ sm: 'center', md: 'center', lg: 'start', xl: 'start' }} display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}>
        <Image src={logo} mb={{ sm: '32px', md: '24px', lg: '90px' }} />
        </Flex>

        <Heading fontWeight={700} fontSize={{ sm: '28px', md: '28px', lg: '28px', xl: '28px', '2xl': '28px' }} textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }} >Fast, secure, affordable</Heading>
        <Text textAlign={{ sm: 'center', md: 'center', lg: 'left', xl: 'left' }} w={{ sm: '100%', md: '100%', lg: '350px', xl: '360px', '2xl': '360px' }} px={{ sm: '16px', md: '100px', lg: '0px', xl: '0px' }} py="10px">
          Securely update, cancel and add new services to your address on the
          go.
        </Text>
        <Box w={{ sm: '100%', md: '100%', lg: '380px', xl: '380px' }} px={{ sm: 0, md: '80px', lg: 0, xl: 0 }}>
        <Flex alignItems="center" mt="25px">
          <Image src={checkIcon} />
          <Text pl={{ sm: '20px', md: '20px', lg: '20px', xl: '20px' }} pr={{ sm: 0, md: '10px', lg: '10px', xl: '10px' }}>
            Discover which accounts are associated with your household
          </Text>

        </Flex>
        <Flex alignItems="center" mt="25px">
          <Image src={checkIcon} />
          <Text px="20px">Choose to update accounts</Text>
        </Flex>
        <Flex alignItems="center" mt="25px">
          <Image src={checkIcon} />
          <Text px="20px">Monitor changes as they happen</Text>
        </Flex>
        </Box>
        <Flex justifyContent={{ sm: 'center', md: 'center', lg: 'start', xl: 'start' }}>
        <Box
          mt="82px"
          mb="140px"
          bg="white"
          w="200px"
          px="10px"
          py="6px"
          borderRadius="16px"
        >
          <Image src={ratingsIcon} />
        </Box>
        </Flex>
      </Box>
  );
};

export default MonaddSideBanner;
