import { Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';

import { dark, red } from '../../utils/themeSettings';

import { FiSearch } from 'react-icons/fi';

function TextField (props: any) {
  return (
    <>
      <InputGroup>
        {/* <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          fontSize="1.2em"
          children="$"
        /> */}
        <Input
          {...props}
          borderRadius={props.borderRadius ? props.borderRadius : '16px'}
          px={props.px ? props.px : '30px'}
          py={props.py ? props.py : '30px'}
          bg={props.isDisabled ? '#F7F7F7' : ''}
          placeholder={props.isDisabled ? '' : props.placeholder}
          name={props.name}
          value={props.value ? props.value : ''}
        />
        {/* {props.withbtn ? (
          <InputRightElement
            mt="11px"
            mr="2px"
            w="170px"
            children={
              <>
                <IoIosCheckmarkCircle
                  style={{ fontSize: '32px', color: green }}
                />
                <CustomButton py="29px" ml="10px">
                  Find address
                </CustomButton>
              </>
            }
          />
        ) : null} */}
        {props.searchAble ? (
          <InputRightElement
            mt='12px'
            mr='28px'
            // w="170px"
            children={
              <FiSearch
                style={{ fontSize: '20px', color: dark, cursor: 'pointer' }}
              />
            }
          />
        ) : null}
        {props.icon ? (
          <InputRightElement
            mt='12px'
            mr='28px'
            // w="170px"
            children={props.icon}
          />
        ) : null}
      </InputGroup>
      {props.isInvalid && (
        <Text color={red} textAlign='left' p='3px'>
          {props.errormessage}
        </Text>
      )}
    </>
  );
}

export default TextField;
