import React, { useState, useEffect } from 'react';
import {
  Flex,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Text
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import { iso2CountryCallingCode, iso2CountryFlag, COUNTRY_OPTIONS } from '../../../../utils';

export default function PhoneNumberInput ({
  size,
  value,
  onChange,
  placeholder,
  defaultCountry,
  ...rest
}: any) {
  const [number, setNumber] = useState(value || '');
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [countryCode, setCountryCode] = useState<string | null>(iso2CountryCallingCode(defaultCountry));

  const onCountryChange = (e: any) => {
    const value = e.target.value;
    const code = iso2CountryCallingCode(value);
    const parsedNumber = new AsYouType().input(`${code}${value}`);

    setCountryCode(code);
    setSelectedCountry(value);
    setNumber('');
    onChange(parsedNumber);
  };

  const onPhoneNumberChange = (e: any) => {
    const value = e.target.value;
    const parsedNumber = new AsYouType().input(`${countryCode}${value}`);

    setNumber(value);
    onChange(parsedNumber);
  };

  useEffect(() => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);

      if (parsedNumber) {
        setSelectedCountry(parsedNumber.country || '');
        setNumber(parsedNumber.nationalNumber);
      }
    }
  }, [value]);

  return (
    <InputGroup size={size} {...rest}>
      <InputLeftElement width="6.5rem" >
        <Select
          top="0"
          left="0"
          zIndex={1}
          bottom={0}
          opacity={0}
          py={'22px'}
          height="100%"
          position="absolute"
          value={selectedCountry}
          onChange={onCountryChange}
        >
          {COUNTRY_OPTIONS.filter((option: any) => option.callingCode).map((option: any) => (
            <option key={option.value} value={option.value}>{option.label2} {option.callingCode}</option>
          ))}
        </Select>
        <Flex pl={2} width="100%" alignItems="center" paddingTop={7}>
          {
            selectedCountry ? <Text textAlign='right'>{iso2CountryFlag(selectedCountry)} {iso2CountryCallingCode(selectedCountry)}</Text> : <PhoneIcon />
          }
        </Flex>
      </InputLeftElement>
      <Input
        pl="6.5rem"
        type="tel"
        value={number}
        borderRadius={4}
        py={'30px'}
        placeholder={placeholder}
        onChange={onPhoneNumberChange}
      />
    </InputGroup>
  );
}

PhoneNumberInput.defaultProps = {
  options: [],
  size: 'md'
};
