import { useState } from 'react';
import useAxios from 'axios-hooks';
import {
  Box,
  Divider,
  Flex,
  Image,
  Show,
  Text,
  Heading,
  Button,
  ButtonGroup,
  useToast
} from '@chakra-ui/react';
import 'react-loqate/dist/react-loqate.cjs.development.css';

import arrow from '../../../../assets/icons/Line.svg';
import Address, { TAddress } from './Address';

import { TStepProps } from '../../types';
import { API_ENDPOINTS, purpleColor, purpleColorHover, offWhite, lightGray, dark } from '../../../../utils';

import '../../profile.css';

type TDateStatus = {
  moveFrom: TAddress | null;
  moveTo: TAddress | null;
};

function Addresses (props: TStepProps) {
  const { onStepChange, me } = props;

  const toast = useToast();

  const getAddress = (moveType: 'in' | 'out' | 'manage'): TAddress | null => {
    const key = moveType === 'in' ? 'moveInDate' : 'moveOutDate';

    const address = moveType === 'manage' ? me.addresses[0] : (moveType === 'out' && me.affiliate === 'yoti' && me.addresses.length === 1 ? me.addresses[0] : me.addresses.find((address: any) => address[key]));

    if (address) {
      return {
        address: address?.addressLines.length ? address?.addressLines[0] : '',
        moveDate: address[key] || null,
        town: address?.addressLines.length === 2 ? address?.addressLines[1] : '',
        postcode: address?.postcode || '',
        country: address?.country || 'GB'
      };
    }

    return null;
  };

  const [isMoving, setIsMoving] = useState<boolean | null>(me.addresses.length ? me.activeChangeRequest.serviceType !== 'manage' : null);
  const [selectedAddresses, setSelectedAddresses] = useState<TDateStatus>({
    moveFrom: getAddress(me.activeChangeRequest.serviceType === 'manage' ? 'manage' : 'out'),
    moveTo: getAddress('in')
  });

  const [{ data, loading }, saveAddress] = useAxios(API_ENDPOINTS.ADD_ADDRESS, { manual: true });

  const nextStep = async () => {
    let errorMessage = '';

    if (!selectedAddresses.moveFrom && isMoving === false) {
      errorMessage = 'Provide a valid address to manage.';
    }

    if (isMoving === true && (!selectedAddresses.moveFrom || !selectedAddresses.moveFrom.moveDate || !selectedAddresses.moveTo || !selectedAddresses.moveTo.moveDate)) {
      errorMessage = 'Provide valid moving from and moving to addresses and dates.';
    }

    if (errorMessage !== '') {
      toast({
        title: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
    } else {
      if (selectedAddresses.moveFrom) {
        await saveAddress({
          data: {
            postcode: selectedAddresses.moveFrom.postcode,
            country: selectedAddresses.moveFrom.country,
            moveDate: selectedAddresses.moveFrom.moveDate,
            isCurrentAddress: !isMoving,
            serviceType: isMoving ? 'move' : 'manage',
            addressLine1: selectedAddresses.moveFrom.address,
            addressLine2: selectedAddresses.moveFrom.town,
            addressLines: [selectedAddresses.moveFrom.address, selectedAddresses.moveFrom.town]
          }
        });
      }

      if (selectedAddresses.moveTo && isMoving) {
        await saveAddress({
          data: {
            postcode: selectedAddresses.moveTo.postcode,
            country: selectedAddresses.moveTo.country,
            moveDate: selectedAddresses.moveTo.moveDate,
            isCurrentAddress: true,
            serviceType: 'move',
            addressLine1: selectedAddresses.moveTo.address,
            addressLine2: selectedAddresses.moveTo.town,
            addressLines: [selectedAddresses.moveTo.address, selectedAddresses.moveTo.town]
          }
        });
      }

      onStepChange(data);
    }
  };

  return (
    <>
      <Flex flexDirection='column' alignItems='center' onClick={event => event.stopPropagation()}>
        {isMoving === null && <>
          <Heading
            fontSize={{ sm: '28px', md: '28px', lg: '36px' }}
            mt={{ sm: '24px', md: '28px', lg: 0 }}
            color={dark}
          >
            Your Plan?
          </Heading>
          <Text
            id="description"
            color={lightGray}
            px={{ base: '30px', md: '30px', lg: '30px' }}
            w={{ base: '320px', sm: '400px', md: '400px', lg: '450px' }}
            my='15px'
            align='center'
          >
            Are you Moving to a new Address or planning to Manage your subscriptions at your existing Address?
          </Text>
          <Box px="25px">
            <Divider />
          </Box>
        </>
        }
        <ButtonGroup
          spacing='6'
          isAttached
          my={'16px'}
          mx={{ sm: '20px', md: '5px' }}
          border={`solid ${purpleColor}`}
          borderRadius={4}
          height={'61px'}
          width={'350px'}
          display={'flex'}
          alignItems={'center'}
          fontWeight={500}
          fontSize={'md'}
        >
          <Button
            color={isMoving ? offWhite : purpleColor}
            bg={isMoving ? purpleColor : offWhite}
            height={'100%'}
            width={'50%'}
            borderLeftRadius={0}
            borderRight={`solid ${purpleColor}`}
            _hover={{
              background: purpleColorHover,
              color: 'white'
            }}
            onClick={() => setIsMoving(true)}
          >
            Moving home
          </Button>
          <Button
            color={isMoving === false ? offWhite : purpleColor}
            bg={isMoving === false ? purpleColor : offWhite}
            height={'100%'}
            width={'50%'}
            borderRightRadius={0}
            _hover={{
              background: purpleColorHover,
              color: 'white'
            }}
            onClick={() => setIsMoving(false)}
          >
            Manage my services
          </Button>
        </ButtonGroup>
        {isMoving !== null && (
          <>
            <Flex flexDirection={{ base: 'column', md: 'column', lg: 'row' }} mt='60px' justifyContent='between'>
              <Box >
                <Address
                  manageMode={!isMoving}
                  values={selectedAddresses.moveFrom}
                  onChange={(address: TAddress) => {
                    const outDate = address?.moveDate;
                    const inDate = selectedAddresses.moveTo?.moveDate;

                    if (selectedAddresses.moveTo?.moveDate && outDate && inDate && new Date(outDate) > new Date(inDate)) selectedAddresses.moveTo.moveDate = null;

                    setSelectedAddresses({ ...selectedAddresses, moveFrom: address });
                  }}
                  isMovingOut
                />
              </Box>
              {
                isMoving && (
                  <>
                    <Show above='lg'>
                      <Image src={arrow} m={20} />
                    </Show>
                    <Box mt={{ base: '20px', md: '20px', lg: 0 }}>
                      <Address
                        values={selectedAddresses.moveTo}
                        onChange={(address: TAddress) => setSelectedAddresses({ ...selectedAddresses, moveTo: address })}
                        isMovingOut={false}
                        startDateFrom={selectedAddresses.moveFrom?.moveDate}
                      />
                    </Box>
                  </>
                )}
            </Flex>
            <Button
              my='30px'
              w={{ base: '92%', md: '428px', lg: '428px' }}
              mt='40px'
              borderRadius={4}
              bg={purpleColor}
              _hover={{ bg: purpleColorHover }}
              color='white'
              px='30px'
              py='30px'
              isLoading={loading}
              isDisabled={loading}
              onClick={() => nextStep()}
            >
              Save & Continue
            </Button>
          </>
        )}
      </Flex>
    </>
  );
}

export default Addresses;
