import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Center,
  Text,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import logo from '../../assets/navbar-logo.svg';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../ui/CustomButton';
import { dark, yellow, blue, API_ENDPOINTS } from '../../utils/';
import { WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';

export default function MoveInformation (props: any) {
  const navigate = useNavigate();
  const params = window.location.pathname.split('/');
  const func = params[1];
  const uuid = params[2];
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const countryNames = new Intl.DisplayNames(
    ['en'], { type: 'region' }
  );
  const asMonaddEndpoints = [
    'confirm-address-change',
    'confirm-connect',
    'confirm-disconnect'
  ];
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const { isOpen: isSupportOpen, onOpen: onSupportOpen, onClose: onSupportClose } = useDisclosure();

  const [supportName, setSupportName] = useState('');
  const [supportContactDetails, setContactDetails] = useState('');

  let firstName = '';
  let serviceType = 'move';
  let lastName = '';
  let fullName = '';
  let email = '';
  let oldAddress = {};
  let newAddress = {};
  let endpoint = '';
  let changeType = ''; // one of move, cancel, connect
  let changeTitle = '';
  let oldAddressLines = [];
  let newAddressLines = [];
  let moveInDate;
  let moveOutDate;
  let oldCountry;
  let newCountry;
  let providerName;
  let consentReferenceId = '';
  let consentAgreement = '';
  let consentIPAddress = '';
  let consentISOTime = '';
  let consentTime = '';

  endpoint = API_ENDPOINTS.CHANGE_REQUESTS.url + uuid + '/provider-request/';

  const [{ data: moveDetails }, getMoveDetails] = useAxios(
    {
      url: endpoint,
      method: 'GET'
    },
    {
      manual: true,
      useCache: false
    }
  );

  const [, updateProviderRequest] = useAxios(
    {
      url: endpoint,
      method: 'POST'
    },
    {
      manual: true,
      useCache: false
    }
  );

  const setConfirmedMove = () => {
    updateProviderRequest({ data: { confirmed: true } });
    onConfirmOpen();
  };

  const closeConfirmModalAndRefresh = () => {
    onConfirmClose();
    window.location.reload();
  };

  const submitSupport = () => {
    updateProviderRequest({
      data: {
        confirmed: false,
        name: supportName,
        contactInfo: supportContactDetails
      }
    }).then(() => {
      onSupportClose();
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!moveDetails) {
      getMoveDetails();
    }
  }, [moveDetails, getMoveDetails]);

  if (moveDetails) {
    serviceType = moveDetails.providerRequest.userChangeRequest.serviceType;
    providerName = moveDetails.providerRequest.provider.name;
    firstName = moveDetails.providerRequest.user.firstName;
    lastName = moveDetails.providerRequest.user.lastName;
    fullName = firstName + ' ' + lastName;
    email = moveDetails.providerRequest.user.email;
    oldAddress = moveDetails.idInfo.oldAddress;
    newAddress = moveDetails.idInfo.newAddress;

    consentReferenceId = moveDetails.providerRequest.userConsent.uuid;
    consentAgreement = moveDetails.providerRequest.userConsent.consentText;
    consentIPAddress = moveDetails.providerRequest.userConsent.ip;
    consentISOTime = moveDetails.providerRequest.userConsent.grantedOn;
    consentTime = new Date(consentISOTime).toLocaleString();

    if (newAddress) {
      newAddressLines = moveDetails.idInfo.newAddress.addressLines;
      moveInDate = new Date(moveDetails.idInfo.newAddress.moveInDate).toLocaleDateString('en-GB', dateOptions);
      newCountry = countryNames.of(moveDetails.idInfo.newAddress.country.toUpperCase());
    };
    if (oldAddress) {
      oldAddressLines = moveDetails.idInfo.oldAddress.addressLines;
      moveOutDate = new Date(
        moveDetails.idInfo.oldAddress.moveOutDate).toLocaleDateString('en-GB', dateOptions);
      oldCountry = countryNames.of(moveDetails.idInfo.oldAddress.country.toUpperCase());
    };
    if (newAddress && oldAddress) {
      if (oldCountry !== newCountry) {
        oldAddressLines.push(oldCountry);
        newAddressLines.push(newCountry);
      };
    };
    if (serviceType === 'manage') {
      oldAddress = false;
      newAddressLines.push(newCountry);
    }
    if (func === 'confirm-connect') {
      changeType = 'connect';
      changeTitle = 'Service Connect';
    }
    if (func === 'confirm-disconnect') {
      changeType = 'cancel';
      changeTitle = 'Service Cancellation';
    }
    if (func === 'confirm-address-change') {
      changeType = 'move';
      changeTitle = 'Address Change Notification';
      if (serviceType === 'manage') {
        changeTitle = 'Personal details to update';
      }
    }
    if (func === 'move-information') {
      changeTitle = 'Details for ' + fullName;
    }
  };

  if (!moveDetails) {
    return (
      <Box pb='20' textColor={dark}>
        <Flex px="40px" py="26px" justifyContent="space-between">
          <Image cursor="pointer" src={logo} onClick={() => navigate('/')} />
        </Flex>
        <Center>
          <Box textAlign="left">
            <Box pb='5'>
              <Text fontSize='3xl' >
                We are working on this request!
              </Text>
            </Box>
          </Box>
        </Center>
      </Box>
    );
  };

  return (
    <Box pb='20' textColor={dark}>
      <Flex px="40px" py="26px" justifyContent="space-between">
        <Image cursor="pointer" src={logo} onClick={() => navigate('/')} />
      </Flex>
      <Center>
        <Box textAlign="left">
          {asMonaddEndpoints.includes(func) ? <Box pb='5'>
            <Text fontSize='3xl' >
              Hi {providerName} support team,
            </Text>
            {serviceType === 'manage' ? <Text fontSize='xl' pt='3'>
              We are heping <b>{fullName} ({email})</b> manage providers,
            </Text> : <Text fontSize='xl' pt='3'>
                We are heping <b>{fullName} ({email})</b> with a move,
              </Text>
            }
            {changeType === 'cancel' ? <Text fontSize='xl'>
              {firstName} asked us to <b>cancel their account with {providerName}</b>.
            </Text> : <>
                {changeType === 'connect' ? <Text fontSize='xl'>
                  {firstName} asked us to <b>create a new account with {providerName}</b>.
                </Text> : <> {serviceType === 'manage' ? <Text fontSize='xl'>
                    {firstName} asked us to <b>send updated personal details</b> to <b>{providerName}</b>.
                  </Text> : <Text fontSize='xl'>
                      {firstName} asked us to <b>notify {providerName}</b> of the address change.
                    </Text>
                  }
                  </>
                }
              </>
            }
            <Text fontSize='xl'>
              Below are the details that {firstName} wants to update.
            </Text>
          </Box> : null
          }

          <Divider />

          <Text fontSize='xl' pt='5'>
            <b>{changeTitle}</b>
          </Text>
          <Text fontSize='xl'>
            <b>Name:</b> {fullName}<br/>
            <b>Email:</b> {email}
          </Text>
          <TableContainer>
            <Table variant='simple'>
              <Tbody>
                <Tr><Td></Td><Td></Td></Tr>
                <Tr>
                  {oldAddress ? <Td>
                    <Text fontSize='lg' p='2'>
                      <b>Old Address:</b><br/>
                    </Text>
                    <Text fontSize='lg' p='2'>
                      {oldAddressLines.map((line : string) => <span key={line}>{line}<br/></span>)}
                    </Text>
                    <Text fontSize='lg' p='2'>
                      <b>Move Out Date:</b> {moveOutDate}
                    </Text>
                  </Td> : <Td></Td>
                  }
                  {newAddress ? <Td>
                    {serviceType === 'manage' ? <Text fontSize='lg' p='2'>
                      <b> Address:</b><br/>
                    </Text> : <Text fontSize='lg' p='2'>
                        <b>New Address:</b><br/>
                      </Text>
                    }
                    <Text fontSize='lg' p='2'>
                      {newAddressLines.map((line : string) => <span key={line}>{line}<br/></span>)}
                    </Text>
                    {serviceType === 'manage' ? <>
                    </> : <Text fontSize='lg' p='2'>
                        <b>Move In Date:</b> {moveInDate}
                      </Text>
                    }
                  </Td> : <Td></Td>
                  }
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          {asMonaddEndpoints.includes(func) ? <Box pt='5'>
            {changeType === 'cancel' ? <>
              <Text fontSize='xl'>
                Click on the button below when the account has been canceled.
              </Text>
              <Center pt='5'>
                <Stack spacing={4} direction='row' align='center'>
                  <CustomButton size='lg' width='300px' onClick={onSupportOpen}>
                    Support
                  </CustomButton>
                  <CustomButton size='lg' width='300px' onClick={setConfirmedMove}>
                    Account is now cancelled
                  </CustomButton>
                </Stack>
              </Center>
            </> : <>
                {changeType === 'connect' ? <>
                  <Text fontSize='xl'>
                    Click on the button below when the new account has been created.
                  </Text>
                  <Center pt='5'>
                    <Stack spacing={4} direction='row' align='center'>
                      <CustomButton size='lg' width='300px' onClick={onSupportOpen}>
                        Support
                      </CustomButton>
                      <CustomButton size='lg' width='300px' onClick={setConfirmedMove}>
                        Account is now created
                      </CustomButton>
                    </Stack>
                  </Center>
                </> : <>
                    <Text fontSize='xl'>
                      Click on the button below to acknowledge that you updated the user's details.
                    </Text>
                    <Center pt='5'>
                      <Stack spacing={4} direction='row' align='center'>
                        <CustomButton size='lg' width='300px' onClick={onSupportOpen}>
                          Support
                        </CustomButton>
                        <CustomButton size='lg' width='300px' onClick={setConfirmedMove}>
                          Details are now updated
                        </CustomButton>
                      </Stack>
                    </Center>
                  </>
                }
              </>
            }

            <Text fontSize='xl' pt='5' w='650px'>
              <WarningIcon color={blue}/> If you notice a mismatch or cannot find the user record, please contact our support team as soon as possible and a team member will get back to you within 24 hours.
            </Text>
            <Text fontSize='xl' pt='5' pb='5' color={dark} w='650px'>
              <WarningTwoIcon color={yellow}/> Copying, using or distributing this information for purposes other than creating the user's account is strictly prohibited.
            </Text>

            <Divider />

            <Text fontSize='md' pt='5' color={dark} w='650px'>
              Please see below the consent details {fullName} provided:
            </Text>
            <Text fontSize='md' pt='5' color={dark} w='650px'>
              <b>Reference Id:</b> {consentReferenceId}<br/>
              <b>Consent agreement:</b> {consentAgreement}<br/>
              <b>Verified email address:</b> {email}<br/>
              {consentIPAddress ? <><b>IP address:</b> {consentIPAddress}<br/></> : null}
              <b>Time:</b> {consentTime} (ISO format {consentISOTime})<br/>
              <b>Registered by:</b> Monadd Ltd. Company number 11471322 / ICO number ZA516678
            </Text>
          </Box> : null
          }
        </Box>

        <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Thank you!</ModalHeader>
            <ModalBody>
              Your response has been registered, thank you!
            </ModalBody>
            <ModalFooter>
              <CustomButton size='md' width="full" mt={4} onClick={closeConfirmModalAndRefresh}>
                Close
              </CustomButton>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isSupportOpen} onClose={onSupportClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>How can we best contact you?</ModalHeader>
            <ModalBody>
              <Box my={4} textAlign="left">
                <form>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="name"
                      placeholder="First and last name"
                      onChange={event => setSupportName(event.currentTarget.value)}
                    />
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Contact details</FormLabel>
                    <Input
                      type="contactInfo"
                      placeholder="Your preferred contact(s)"
                      onChange={event => setContactDetails(event.currentTarget.value)}
                    />
                  </FormControl>
                  <CustomButton size='md' width="full" mt={4} onClick={submitSupport}>
                    Submit
                  </CustomButton>
                  <CustomButton size='md' width="full" mt={4} onClick={onSupportClose}>
                    Close
                  </CustomButton>
                </form>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

      </Center>
    </Box>
  );
}
