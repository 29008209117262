import { Flex, Box } from '@chakra-ui/react';
// import LeftSideDashboard from './LeftSideDashboard';
import Services from '../Services';
import LeftFrameDashboard from './LeftFrameDashboard';
import Header from './Header';
import ComingSoon from '../ComingSoon';
import Profile from '../Profile';

type TMainProps = {
  reloadMe: Function;
  page?: string;
};

function Main (props: TMainProps) {
  return (
    <Flex direction='column' alignItems='center'>
      <Header page={props.page} />
      {/* <LeftSideDashboard {...props} /> */}
      <Box width='100%' maxW='1187px' py={4} px={{ base: 5, md: 8, lg: 3 }}>
        {
        props.page === 'services' ? <Services reloadMe={props.reloadMe} /> : props.page === 'profile' ? <Profile /> : props.page === 'comingsoon' ? <ComingSoon /> : <LeftFrameDashboard/> }
      </Box>
    </Flex>
  );
};

export default Main;
