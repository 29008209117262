import Axios from 'axios';
import { getLocalData, setLocalData, clearLocalStorage } from './helpers';
import { API_ENDPOINTS } from './appCostants';

export const client = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const refreshAuthToken = async (): Promise<void> => {
  const token = getLocalData().refreshToken;

  // user logged out
  if (!token) {
    clearLocalStorage();
    window.location.replace('/login' + document.location.search);
    return;
  }

  const { data } = await client.post(`${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REFRESH_TOKEN.url}`, { refresh: token });

  setLocalData({ token: data.access });
};

client.interceptors.request.use((config: any) => {
  const localData = getLocalData();

  if (config.method === 'get') config.params = { ...(config.params || {}), _timestamp: new Date().getTime() };

  if (localData.token) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${localData.token}`
    };
  };

  return config;
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (config) config._retries = 5;

    if (error?.response?.status === 401 && !config._retry && ![API_ENDPOINTS.EMAIL_SIGNIN.url].includes(config.url)) {
      config._retry = true;

      // DONOT CHANGE THESE UNLESS THE VALUES ARE CHANGED ON BACKEND.
      if (['You do not have permission to perform this action.', 'Token is invalid or expired', 'Token is blacklisted'].includes(error.response.data?.detail)) {
        clearLocalStorage();

        window.location.replace('/login' + document.location.search);

        return Promise.reject(error);
      }

      await refreshAuthToken();

      return client(config);
    }

    return Promise.reject(error);
  }
);
