import { useState } from 'react';
import {
  Box,
  FormLabel,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
  Button
} from '@chakra-ui/react';
import parsePhoneNumber from 'libphonenumber-js';
import PhoneNumberInput from './PhoneNumberInput';

import { dark, lightGray } from '../../../../utils/themeSettings';

type TPhoneNumberProps = {
  phoneNumber: string;
  onSend: Function;
  loading: boolean;
  phoneNumberError: string;
  defaultCountry: string;
};

function PhoneNumber ({ onSend, loading, phoneNumberError, phoneNumber: preFilledPhoneNumber, defaultCountry }: TPhoneNumberProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>(preFilledPhoneNumber);
  const [phoneError, setPhoneError] = useState<string>(phoneNumberError);

  const validatePhoneNumber = async (e: any) => {
    e.preventDefault();
    const parsedNumber = parsePhoneNumber(phoneNumber);

    if (parsedNumber && parsedNumber.isValid()) {
      setPhoneNumber(parsedNumber.number);
      onSend(parsedNumber.number);
    } else {
      setPhoneError('Please provide a valid phone number.');
    }
  };

  return (
    <>
      <Heading fontSize="26px" color={dark}>
        Verify Your Profile!
      </Heading>
      <Text color={lightGray} px="20px" my="15px">
        For the purpose of industry regulation, your details are required.
      </Text>

      <form onSubmit={validatePhoneNumber}>
        <Box mt="44px" mb="16px">

          <FormControl isInvalid={!!phoneError || !!phoneNumberError} mt="30px">

            <FormLabel>Mobile number</FormLabel>
            <PhoneNumberInput
              value={preFilledPhoneNumber}
              defaultCountry={defaultCountry}
              placeholder="Enter phone number"
              onChange={(value: any) => {
                setPhoneNumber(value);
                setPhoneError('');
              }}
            />
            {(!!phoneError || !!phoneNumberError) && (<FormErrorMessage>{phoneError || phoneNumberError}</FormErrorMessage>)}

          </FormControl>

        </Box>

        <Button
          my="24px"
          w='100%'
          mt='40px'
          borderRadius={4}
          colorScheme="purple"
          px='30px'
          py='30px'
          isDisabled={loading}
          isLoading={loading}
          type="submit"
        >
          Send
        </Button>
      </form>
    </>
  );
};

export default PhoneNumber;
